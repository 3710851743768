import React, { useState, useEffect } from 'react'
import { State as allStates, City as allCities }  from 'country-state-city';

import DatePicker from 'react-date-picker';
import info from '../images/info.svg'
import Footer from './Footer'
import SidePanel from './SidePanel'
import video_player from '../images/video-player.svg'
import styles from '../components_css/Client_info.module.css'
import cx from 'classnames'
import { useHistory } from 'react-router'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import * as allNaics from '../constant/NAICS';
import * as allNaic from '../constant/NAIC';
import * as allCountries from '../constant/COUNTRIES';
import * as allCurrency from '../constant/CURRENCY';
import {notify} from '../utils/globalFunction'
import { Typeahead } from 'react-bootstrap-typeahead';
// import * as allStates from '../constant/STATES';
// import * as allCities from '../constant/CITIES';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Autosave } from 'react-autosave';

function ClientInfo({mainData, BaseURL,submitCall,userLogOut,gotoWorksheet,gotoRunRate }) {
    
    const [Editmode,setEditmode] = useState(false);
    const [Company, setCompany] = useState(mainData?.client_information?.company || '')
    const [Mobile, setMobile] = useState(mainData?.client_information?.main_phone || '')
    const [Email, setEmail] = useState(mainData?.client_information?.email || '')
    const [InvalidEmail, setInvalidEmail] = useState(false)
    const [InvalidMobile, setInvalidMobile] = useState(false)
    const [Textarea, setTextarea] = useState(mainData?.client_information?.internal_notes||'')

    const [AssessmentDate, setAssessmentDate] = useState( new Date())
    const [ProjectName, setProjectName] = useState(mainData?.client_information?.project_name || '')
    const [Naics, setNaics] = useState(mainData?.client_information?.naics || 'Agriculture, Forestry, Fishing and Hunting')
    const [YearStarted, setYearStarted] = useState(mainData?.client_information?.year_started || '')
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [Fname, setFname] = useState(mainData?.client_information?.first_name || '')
    const [Lname, setLname] = useState(mainData?.client_information?.last_name || '')
    const [Country, setCountry] = useState(mainData?.client_information?.country || '') 
    const [Currency, setCurrency] = useState(mainData?.client_information?.currency || '') 
    const [Address, setAddress] = useState(mainData?.client_information?.address || '')
    const [City, setCity] = useState(mainData?.client_information?.city || '')
    const [State, setState] = useState(mainData?.client_information?.state || 'AL')
    const [Zip, setZip] = useState(mainData?.client_information?.zip || '')
    const [OtherMobile, setOtherMobile] = useState(mainData?.client_information?.other_phone || '')
    const [LessThan12, setLessThan12] = useState(mainData?.client_information?.business_less_then_12_month === true? 1:2 || '')
    const [WomenOwner, setWomenOwner] = useState(mainData?.client_information?.is_business_owned_by_woman_or_minority || '')
    const [value, onChange] = useState(new Date())
    const [Allvalid, setAllvalid] = useState(true)
    
    let token = localStorage.getItem('userAccessToken')

    const [singleSelections, setSingleSelections] = useState([]);
    
    const [firstname, setName] = useState("");
  const [lastname, setLastname] = useState("");
    
    // const [data, setData] = useState((options?.initialValues || {}));
    
    useEffect(() => {
        setEditmode(mainData.id?true:false)
        setCompany(mainData?.client_information?.company || '')
        setMobile(mainData?.client_information?.main_phone || '')
        setEmail(mainData?.client_information?.email || '')
        setTextarea(mainData?.client_information?.internal_notes || '')
        setAssessmentDate(mainData?.client_information?.assessment_date || '')
        setProjectName(mainData?.client_information?.project_name || '')
        setNaics(mainData?.client_information?.naics || '')
        setSingleSelections([mainData?.client_information?.naics] || '')
        setYearStarted(mainData?.client_information?.year_started || '')
 
        setFname(mainData?.client_information?.first_name || '')
        setLname(mainData?.client_information?.last_name || '')
        setCurrency(mainData?.client_information?.currency || '')
        setCountry(mainData?.client_information?.country || '')
        //setStateAndCountry(Country)
        // setCitiesFn(Country,State)
    
        setAddress(mainData?.client_information?.address || '')
        setCity(mainData?.client_information?.city || '')
        setState(mainData?.client_information?.state || '')
        setZip(mainData?.client_information?.zip || '')
        setOtherMobile(mainData?.client_information?.other_phone || '')
        setLessThan12(mainData?.client_information?.business_less_then_12_month === true?1:2)
        setWomenOwner(mainData?.client_information?.is_business_owned_by_woman_or_minority)

        
    }, [mainData?.client_information]);


    const client_info_user_data = {
        "client_information": {
            "assessment_date": `${AssessmentDate}`,
            "project_name": `${ProjectName}`,
            "naics": `${Naics}`,
            "first_name": `${Fname}`,
            "last_name": `${Lname}`,
            "city": `${City}`,
            "year_started": `${YearStarted}`,
            "address": `${Address}`,
            "state": `${State}`,
            "email": `${Email}`,
            "main_phone": `${(Mobile === '') ? '' : `${Mobile}`}`,
            "other_phone": `${(OtherMobile === '') ? '' : `${OtherMobile}`}`,
            "zip": `${Zip}`,
            "country": `${Country}`,
            "business_less_then_12_month": LessThan12===1,
            "internal_notes": `${Textarea}`,
            "company": `${Company}`,
            "is_business_owned_by_woman_or_minority": WomenOwner,
            "currency": `${Currency}`
        },
        worksheet:{},
        analyzing_report:{},
        "progress_bar": 25
    }

    const history = useHistory()

    const year = () => {
        let currentYear = new Date().getFullYear();
        const years = [];
        let startYear = 1970;
        for(let i=startYear; i<= currentYear+1; i++){
            years.push(startYear++);
        }
        return years.reverse();
     }
    useEffect(() => {

        setStateAndCountry(Country)
        setCitiesFn(Country,State)
    }, [Country])

    const setStateAndCountry = (arg) => {
        const statesData = allStates.getStatesOfCountry(arg)
        setStates(statesData)
        console.log(statesData);
        if(statesData.length>0){
            setCitiesFn(statesData[0].countryCode,statesData[0].isoCode.toString())
        }
    }

    const setCitiesFn = async(arg1,arg2) => {
        const citiesData = await allCities.getCitiesOfState(arg1, arg2)
        setCities(citiesData)
    }


    useEffect(() => {
        if (Mobile.length == 0) {
            setInvalidMobile(false)
        } 
        else if ( (Mobile.length > 2) && (Mobile.length <= 15) ){
            console.log(Mobile.length,"Mobile.length")
            if ( /^\+\d+$/.test(Mobile) ) {        
                setInvalidMobile(false)
            } else {
                setInvalidMobile(true)
            }
        }
        else {
            console.log(Mobile.length,"Mobile.length")
        
            setInvalidMobile(true)
        }

    }, [Mobile])

    useEffect(() => {
        if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(Email)) && !(Email === '')) {
            setInvalidEmail(true)
        }
        else {
            setInvalidEmail(false)
        }

    }, [Email])

    useEffect(() => {
        if ((InvalidEmail)) {
            setAllvalid(false)
        }
        else {
            setAllvalid(true)
        }

    }, [InvalidEmail])

    const handleSubmit = (e) => {
        e.preventDefault();
           
        if (Allvalid) {
           if(Editmode)
           {
            
                axios.put(`${BaseURL}/api/v1/assessments/update/${mainData.id}/`, client_info_user_data, { headers: { "Authorization": `JWT ${token}` } })
                .then(response => {
                    // console.log(response.data)
                    const res = response.data
        

                    const data = {
                        client_information: res.data.client_information,
                        id:res.data.id
                    }

                    submitCall(data)
                  
                })
                .catch(err =>  {
                    console.log(err)
                   
        
                })
            
           }
           else{
            axios.post(`${BaseURL}/api/v1/assessments/create/`, client_info_user_data, { headers: { "Authorization": `JWT ${token}` } })
                .then(response => {
                    console.log(response.data)
                    const res = response.data

                    const data = {
                        client_information: res.data.client_information,
                        id:res.data.id
                    }

                    submitCall(data)
                    // console.log(response); history.push("/analyzing_report")
                })
                .catch(err =>  {
                    // if(err.response.status===401){
                    //     userLogOut()
                    // }
                    // console.log(err.response.status);
                    notify(err,'error')
                })
           }
        }
        else {
            console.log('something is wrong')
        }
    }

    const handleSave = (e) => {
        // e.preventDefault();
           
        if (Allvalid) {
           if(Editmode)
           {
            
                axios.put(`${BaseURL}/api/v1/assessments/update/${mainData.id}/`, client_info_user_data, { headers: { "Authorization": `JWT ${token}` } })
                .then(response => {
                    // console.log(response.data)
                    const res = response.data
        

                    const data = {
                        client_information: res.data.client_information,
                        id:res.data.id
                    }

                   // submitCall(data)
                  
                })
                .catch(err =>  {
                    console.log(err)
                   
        
                })
            
           }
        } else {
                console.log('something is wrong')
        }
    }
   
    const { register, errors } = useForm();


    return (
        <>
            <div className={styles.client_info_main}>
            <div className={styles.page_content}>
                <div className={styles.client_info_form_container}>
                    <div className={styles.client_info_form_header}>
                        <h4>Client Information</h4>
                        <a href="https://pat-api.profitfirsttools.com/static/video/client-information-screen.mp4" target="_blank"><img className="video-icon" alt='' src={video_player} /> <i>Watch Tutorial</i></a>
                    </div>
                    <form className={styles.client_info_form} onSubmit={handleSubmit} autoComplete='nope'>
                        <div className={styles.client_info_row}>

                            <div className="custom_date_picker">
                                <label>Assessment Date *</label>
                                {/* <span className={`error_msg ${InvalidUsername && `display_error`}`}>Username can be alphanumeric only</span> */}
                                <DatePicker    
                                    className="custom-picker"     
                                    onChange={onChange}
                                    value={value}  
                                    selected={AssessmentDate}
                                />
                                {/* <input className={styles.client_info_inputs} type="date" name="date" value={AssessmentDate} onChange={(e) => setAssessmentDate(e.target.value)} required /> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidUsername && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Project Name *</label>
                                <input className={styles.client_info_inputs} type="text" placeholder="Enter here" name="project-name" value={ProjectName} onChange={(e) => setProjectName(e.target.value)} required  />
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>Company *</label>
                               
                                 {/* <span className={`error_msg ${setCompany && `display_error`}`}>Enter a company name</span> */}
                                <input required autoComplete='nope' className={styles.client_info_inputs} type="text" placeholder="Enter here" name="company"  value={Company} onChange={(e) => setCompany(e.target.value)}
                               
                                />
                                  { errors && errors.company && <span>{errors.company.message}</span>}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidMobile && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>NAICS *</label>
                                 {/* <span className={`error_msg ${setNaics && `display_error`}`}>Enter a  Naics</span> */}
                                {/* <input className={`client_info_inputs ${InvalidEmail && `error`}`} type="text" placeholder="Enter here" name="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required /> */}
                                {/* <select className={styles.client_info_select} onChange={(e) => setNaics(e.target.value)} required>
                                    {allNaics.NAICS.map((item, key)=><option key={key} value={item}>{item}</option>)}
                                    
                                </select> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    className="custom-typehead"
                                    onChange={(value)=>{setNaics(value[0]); setSingleSelections(value)}}
                                    options={allNaic.NAIC}
                                    placeholder="Enter here"
                                    value={Naics}
                                    selected={singleSelections}
                                    inputProps={{ required: true }}
                                />
                           
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Year Started</label>
                                <select className={styles.client_info_select} onChange={(e) => setYearStarted(e.target.value)} value={YearStarted}>
                                <option value="">Select Year</option>
                                {year().map((item,key)=>  <option key={key} value={item}>{item}</option>)}
                                </select>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                {/* <input className={styles.client_info_inputs} type="number" placeholder="Enter here" name="year_started" value={YearStarted} onChange={(e) => setYearStarted(e.target.value)} /> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Currency *</label>
                                <select value={Currency} required className={styles.client_info_select} onChange={(e) => { setCurrency(e.target.value); setStateAndCountry(e.target.value)}}>
                                    <option value="">Select Currency</option>
                                    {allCurrency.CURRENCY.map((item, key)=><option key={item.id} value={item.id}>{item.symbol} {item.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>First Name *</label>
                                {/* <span className={`error_msg ${setFname && `display_error`}`}>Enter your first name</span> */}
                                <input required autoComplete='nope' className={styles.client_info_inputs} type="text" placeholder="Enter here" name="Fname" value={Fname} onChange={(e)=>{ setFname(e.target.value)}}
                                    />
                
                                 { errors && errors.first_name && <span>firstname is required</span>}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidMobile && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Last Name *</label>
                                {/* <span className={`error_msg ${setLname && `display_error`}`}>Enter your last name</span> */}
                                <input required autoComplete='nope' className={styles.client_info_inputs} type="text" placeholder="Enter here" name="Lname" value={Lname} onChange={(e)=>{ setLname(e.target.value)}}
                                  />
                                  { errors && errors.last_name && <span>{errors.last_name.message}</span>}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Country *</label>
                                <select value={Country} autoComplete='nope' required className={styles.client_info_select} onChange={(e) => { setCountry(e.target.value); setStateAndCountry(e.target.value)}}>
                                    <option value="">Select Country</option>
                                    {allCountries.COUNTRIES.map((item, key)=><option key={key} value={item.iso2}>{item.name}</option>)}
                                </select>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Select a valid Country</span> */}
                                {/* <input className={styles.client_info_inputs} type="text" placeholder="Enter here" name="country" value={Country} onChange={(e) => setCountry(e.target.value)} /> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>Address</label>
                                <input className={styles.client_info_inputs} autoComplete='nope' type="text" placeholder="Enter here" name="address" value={Address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>State</label>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                
                                <select autoComplete='nope' value={State} className={styles.client_info_select}  onChange={(e) => {setState(e.target.value); setCitiesFn(Country,e.target.value)}}>
                                    <option value="">Select State</option>
                                    {states?states.map((item, key)=><option key={key} value={item.isoCode}>{item.name}</option>):''}
                                </select>
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>City</label>
                                {/* <span className={`error_msg ${InvalidMobile && `display_error`}`}>Enter 10 digit number</span> */}
                                <select autoComplete='nope' value={City} className={styles.client_info_select} onChange={(e) => setCity(e.target.value)} >
                                    <option value="">Select City</option>
                                    {cities?cities.map((item, key)=><option key={key} value={item.name}>{item.name}</option>):''}
                                </select>
                                {/* <img alt='' src={info} className={`error_icon ${InvalidMobile && `display_error`}`}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Zip</label>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                <input className={styles.client_info_inputs} type="text" placeholder="Enter here" name="zip" value={Zip} onChange={(e) => setZip(e.target.value)} />
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>Email</label>
                                <span className={cx(styles.client_info_error_msg, { [styles.client_info_display_error]: InvalidEmail })}>Enter a valid email</span>
                                <input  className={cx(styles.client_info_inputs, { [styles.client_info_error]: InvalidEmail })} type="text" placeholder="Enter here" name="email" value={Email} onChange={(e) => setEmail(e.target.value)}  />
                                <img alt='' src={info} className={cx(styles.client_info_error_icon, { [styles.client_info_display_error]: InvalidEmail })}></img>
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Main phone</label>
                                <span className={cx(styles.client_info_error_msg)}>Enter a 10 digit number</span>
                                <input className={cx(styles.client_info_inputs, { [styles.client_info_error]: InvalidMobile })} type="tel" placeholder="+11234567890" name="phone" value={Mobile} onChange={(e) => setMobile(e.target.value)} />
                                {/* <PhoneInput country={Country.toLowerCase()} containerClass={styles.client_info_inputs} containerStyle={{ position: 'relative' }} inputStyle={{ backgroundColor: "inherit", border: 'none', position: 'relative', left: '0px' }} 
                                buttonStyle={{ border: 'none', backgroundColor: 'inherit' }} placeholder='Mobile' value={Mobile} onChange={setMobile} inputProps={{name:'main_phone'}}
                                // inputProps={{
                                    
                                //     required: true,
                                   
                                //   }}
                                className={cx(styles.client_info_inputs, { [styles.client_info_error]: InvalidMobile })}
                                /> */}
                                {/* <PhoneInput  international  defaultCountry='US' value={Mobile} onChange={setMobile} className={cx(styles.client_info_inputs, { [styles.client_info_error]: InvalidMobile })}/> */}
                                {/* <img alt='' src={info} className={cx(styles.client_info_error_icon)}></img> */}
                            </div>
                            <div className={styles.client_info_input_container}>
                                <label>Other phone</label>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                <input className={styles.client_info_inputs} type="text" placeholder="+11234567890" name="other-phone" value={OtherMobile} onChange={(e) => setOtherMobile(e.target.value)} />
                                {/* <PhoneInput country={Country.toLowerCase()} containerClass={styles.client_info_inputs} containerStyle={{ position: 'relative' }} inputStyle={{ backgroundColor: "inherit", border: 'none', position: 'relative', left: '0px' }} 
                                buttonStyle={{ border: 'none', backgroundColor: 'inherit' }} placeholder='Mobile' value={OtherMobile} onChange={setOtherMobile} inputProps={{name:'other_phone'}} /> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                                {/* <PhoneInput  international  defaultCountry='US' value={OtherMobile} onChange={setOtherMobile} className={cx(styles.client_info_inputs, { [styles.client_info_error]: InvalidMobile })}/> */}
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container} >
                                <label>Are you analyzing a company that has been in business for less than 12 months? *</label>
                                <div >
                                    <div><input onChange={(e) => setLessThan12(parseInt(e.target.value))} className={styles.client_info_radio} type="radio" name="company_name" value="1" required checked={LessThan12 === 1}/><span>Yes</span></div>
                                    <div><input onChange={(e) => setLessThan12(parseInt(e.target.value))} style={{ marginLeft: "40px" }} className={styles.client_info_radio} type="radio" name="company_name" value="2" checked={LessThan12 === 2} /><span>No</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container} >
                                <label>Community *</label>
                                <div>
                                    <select autoComplete='nope' required value={WomenOwner} className={styles.client_info_select} onChange={(e) => setWomenOwner(parseInt(e.target.value))} >
                                        <option value="">Please select</option>
                                        <option value="1">Minority</option>
                                        <option value="2">Women</option>
                                        <option value="3">Veteran</option>
                                        <option value="4">LGBTQ</option>
                                        <option value="5">Not Applicable/Choose Not To Answer</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={styles.client_info_row}>
                            <div className={styles.client_info_input_container}>
                                <label>Internal Notes</label>
                                <textarea className={styles.client_info_textarea} placeholder='Enter here' value={Textarea} onChange={(e) => setTextarea(e.target.value)} />
                            </div>
                        </div>
                        <div className={styles.client_info_footer}>
                            <div className={styles.progress_bar}><div className={styles.progress} style={{ width: "12.5%" }}></div></div>
                            {Editmode &&  (
                                <div className='d-flex'>
                                    <button className={styles.primary_blue_btn}  onClick={gotoRunRate}>Skip to Final Assessment</button>
                                    &nbsp;
                                    <button className={styles.primary_blue_btn}  onClick={gotoWorksheet}>Skip to Worksheet</button>
                                </div>
                            )}
                            <button className={styles.client_info_submit_btn} type='submit'>Save and continue</button>
                        </div>
                        <Autosave data={client_info_user_data} onSave={handleSave} />
                    </form>
                </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ClientInfo
