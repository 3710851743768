import React,{useEffect,useState} from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Layer_1 from '../images/layer1.svg'
import avatar from '../images/avatar.png'
import menu from '../images/menu.svg'
import settings from '../images/settings.svg'
import exit from '../images/exit.svg'
import user from '../images/pig.jpg'

import { Link, useHistory,NavLink } from 'react-router-dom'

import axios from 'axios'
import {updateTheme, updateCompany, updateLogo} from '../redux/actions/settings'

const drawerWidth = 240;    

const SidePanel = ({ActiveTab,BaseURL,userLogOut })=> {
    const theme = useSelector(state => state.theme);
    const company = useSelector(state => state.company);
    const logo = useSelector(state => state.logo);
    const history = useHistory()
    // const BaseURL = "https://pat-api.profitfirsttools.com"
   // const BaseURL = "http://ec2-18-222-232-202.us-east-2.compute.amazonaws.com"
    // const BaseURL = "http://127.0.0.1:8001"
    const [profile,setProfile]= useState({})
 
    let token = localStorage.getItem('userAccessToken')
    const handleLogout = () => {
        history.push('/')
        localStorage.clear()
        userLogOut()
    }

    
    const dispatch = useDispatch();

    useEffect(() => {
     
        axios.get(`${BaseURL}/api/v1/user/profile/`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
           setProfile(res.data)
           console.log(res.data,'te');
            dispatch(updateTheme(res.data.data.template))
            dispatch(updateCompany(res?.data?.data?.company))

        })
        .catch(err => console.log(err))

        axios.get(`${BaseURL}/api/v1/user/retrieve-settings/`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                console.log(res?.data?.data?.logo,'logo')
                console.log(res,'sss')
                // setLogo(res?.data?.data?.logo)
                dispatch(updateLogo(BaseURL+res?.data?.data?.logo))
        })

    },[token]);
    
    const backHome = () => { history.push('/') }

    return (
     
    <div className={`side_panel ${theme}`}>
        <div className='side_panel_bar'>
           <div className="logo">
               <img alt='' src={Layer_1} onClick={backHome} className='logo_sidepanel'></img>
           </div>
            <div className='upper_circle_side_panel'></div>
            <div className="user-profile">
                <img alt='' src={`${logo? logo:user}`} className='user_avatar'></img>
                <h4 className='user_name'>{company}</h4>
            </div>
            <div className='side_panel_btn_container'>
                <NavLink  exact={true} activeClassName='is-active'  className='side_panel_btn' to='/'>
                    <span className={`indicator active_tab`}></span>
                    <img alt='' src={menu} className='side_panel_btn_icon'></img>
                    <h5 className='side_panel_btn_text'>Dashboard</h5>
                </NavLink>
                <NavLink  exact={true} activeClassName='is-active'  className='side_panel_btn' to='/settings'>
                    <span className={`indicator active_tab`}></span>
                    <img alt='' src={settings} className='side_panel_btn_icon'></img>
                    <h5 className='side_panel_btn_text'>Your Settings</h5>
                </NavLink>
            </div>
            <NavLink  onClick={handleLogout} className={`logout_btn ${theme}`} to='/'>
                    <span className={`indicator active_tab`}></span>
                    <img alt='' src={exit} className='side_panel_btn_icon'></img>
                    <h5 className='side_panel_btn_text'>Logout</h5>
                </NavLink>
            <div className='lower_circle_side_panel'></div>
        </div>
    </div>

    )
}

export default SidePanel
