import React, { useState,useCallback, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Layer_1 from '../images/layer1.svg'
import video_player from '../images/video-player.svg'
import padlock from '../images/padlock.svg'
import user from '../images/user.svg'
import axios from 'axios';
import {notify} from '../utils/globalFunction'
import {updateTheme} from '../redux/actions/settings'

function Login({ BaseURL,loginSuccess, userLogOut }) {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token')
    const [Username, setUsername] = useState((localStorage.getItem('userName') === null) ? '' : localStorage.getItem('userName'))
    const [Password, setPassword] = useState('')
    const user_data = {
        "username": `${Username.toLowerCase()}`,
        "password": `${Password}`
    }
    const dispatch = useDispatch()    
    
    const [login, setLogin] = useState(false);

    const hitLogin = async () => {
        const tokenData= {
            token
        }
        setLogin(true)
        await axios.post(`${BaseURL}/api/v1/user/auto-login/`, tokenData)
        .then(response => {
            if(response.data.status){
                localStorage.setItem('userAuth', `${response.data.status}`);
                localStorage.setItem('userAccessToken', `${response.data.AccessToken}`);
                localStorage.setItem('userRefreshToken', `${response.data.RefreshToken}`);
                localStorage.setItem('userName', `${Username}`)
                // dispatch(updateTheme('blue-theme'))
                loginSuccess()                
            } else {
                notify(response?.data?.message || response?.data?.user,'error')
                setLogin(false)
                userLogOut()
            }
        })
        .catch(err => {
            setLogin(false)
        })
    }

    const handleSubmit = async (e) => {
         
        e.preventDefault()
        await axios.post(`${BaseURL}/api/v1/user/jwt/login/`, user_data)
            .then(response => {
                console.log(response)
                if(response.data.status){
                    localStorage.setItem('userAuth', `${response.data.status}`);
                    localStorage.setItem('userAccessToken', `${response.data.AccessToken}`);
                    localStorage.setItem('userRefreshToken', `${response.data.RefreshToken}`);
                    localStorage.setItem('userName', `${Username}`)
                    // dispatch(updateTheme('blue-theme'))
                    loginSuccess()                
                } else {
                    notify(response?.data?.message || response?.data?.user,'error')
                }
            })
            .catch(err => notify(err,'info'))
    }

    useEffect(() =>{
        if(token){
            hitLogin()
        }
    },[token])

    return (
        <div className='login'>
           
            <div className='left'>
                <img alt='' src={Layer_1} className='login_logo'></img>
                <div className='upper_circle'>
                </div>
                <div className="welcome-text">
                <h2>
                    Welcome to Your
                </h2>
                <h2>
                    Profit Assessment Tool.
                </h2>
                </div>
                <div className='lower_circle'>
                </div>
            </div>
            <div id='login_right'>
            {login? <div>
                <div className="loader_1"></div>
                <h3>Please wait while we are logging you in</h3>
            </div>: ''}
            {!login && token? 
            <h1>Issue with the login?<br />Please contact Administrator</h1> : ''}

             {!login && !token && BaseURL=='https://pat-api.profitfirsttools.com'?
             <h1>Please go to Profit First University for <a href="https://profitfirstuniversity.com/login/">login</a></h1> : '' } 
             
             {/* login form */}
             {BaseURL!='https://pat-api.profitfirsttools.com'?
             <div>
              <div className="login-link">
             <p>Don't have an account yet? <Link to='/register'>Register</Link></p>
             </div>
            <div className="login-box">
                <div id='login_heading'>
                    <h4>Sign in</h4>
                    <img alt='' src={video_player}></img>
                </div>
                <div className='login_container'>
                    <form onSubmit={handleSubmit}>
                        <label>Username<sup className="text-danger">*</sup></label>
                        <input className={`inputs`} type="text" placeholder="Username" name="Username" value={Username} onChange={(e) => setUsername(e.target.value)} required/>
                        <img alt='' src={user} className='input_icons'></img>
                        <label>Password<sup className="text-danger">*</sup></label>
                        <input className={`inputs`} type="password" placeholder="Password" name="password" value={Password} onChange={(e) => setPassword(e.target.value)} required/>
                        <img alt='' src={padlock} className='input_icons pass'></img>
                        <div id='footer'>
                            <label id='radio_container'>
                                <input type='radio' id='radio_btn'></input>
                                <span>Keep me signed in</span>
                            </label>
                            <a href="forgotpassword">Forgot Password? </a>
                        </div>
                        <button id='login_submit_btn' type="submit">SIGN IN</button>
                        
                    </form>
                </div>
                </div> 
                </div> : ''}
                {/* end login form */}
            </div>
        </div>
       
    )
}

export default Login
