import React,{useEffect} from 'react'
import Modal from 'react-modal';
import Layer_1 from '../images/Layer_1_.png'
import { MenuItem, Menu, Button   } from '@material-ui/core';
import {  PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer'
import { getFix, getDelta, currency, getPF, getPercent1, getPercent2, getPercent3, getQuater,getFixU,getPFU  } from '../utils/globalFunction'
import axios from 'axios'

const styles = StyleSheet.create({
    em:{
    fontStyle: 'bold'
}, 
page: { flexDirection: "column", padding: 25 },
table: {
  display: 'block',
  width: '100%',
  fontSize:14,
  borderCollapse:"collapse",
},
hedingFont:{
  fontSize:14,
  // marginBottom:20
},
standardFont:{
  fontSize:11,
  // marginBottom:15
},
comp:{
  marginTop:20,
  marginBottom:20
},
row: {
  display: "flex",
  flexDirection: "row",
  flexFlow:"wrap",
  gap:"0",
  justifyContent: "space-around",
  
  // alignContent: "stretch",
  // alignItems: "stretch",
},
// cellbBody:{
//   border:"",
//   borderWidth:"1px",
//   borderStyle:"solid",
//   borderColor:"#1D457B",
//   backgroundColor: "#FEFEFE"
// },
cell: {
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: "auto",
  alignSelf: "flex-start",
  alignItems:"flex-start",
  cellPadding:"0",
  cellSpacing:"0",
  gap:"0",
  // margin:-2,
  borderCollapse:"collapse",
  // backgroundColor: "#fff",
  border:"",
  borderWidth:"1px",
  borderStyle:"solid",
  borderColor:"#eee",
  height:'100%',
  paddingTop: 8,
  paddingBottom: 8,
  paddingRight: 8,
  paddingLeft: 8,
  boxSizing:"border-box",
  wordBreak: "break-all",
  fontSize:10,
},
// header: {
//   backgroundColor: "#1D457B",
// },
headerText: {
  fontSize: 10,
  borderWidth:"0",
  fontWeight: 600,
  color: "#ffffff",
  backgroundColor: "#1D457B",
},
tableText: {
  color: '#999999',
},
leftColumn: {
  alignItems:'center',
  alignContent:'center',
  flexDirection: 'column',
  paddingTop: 20,
  paddingBottom: 10,
  paddingRight: 15,
  paddingLeft: 15,
  backgroundColor: "#1D457B",
  borderRadius:"6px",
  '@media max-width: 400': {
    width: '100%',
    paddingRight: 0,
  },
  '@media orientation: landscape': {
    width: 200,
  },
},
image:{
  height:'40px',
  maxHeight:'40px',
  maxWidth:'50%',
  marginTop:4,
  marginBottom:10
}

});


function Export2Word(element,  data, BaseURL, logo, editableExpenses, extraExpenses, user, filename = ''){
  
  



  var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'></head><body>";
  
  var innert_html = MyDoc_Document( data, BaseURL, logo, editableExpenses, extraExpenses, user)
  var css = (
     '<style>' +
     '@page WordSection1{size: 11.0in 8.5in;mso-page-orientation: landscape;}' +
     'div.WordSection1 {page: WordSection1; font-family: Avenir, Helvetica, Arial, sans-serif;} table{ font-family: Avenir, Helvetica, Arial, sans-serif; } ' +
     '</style>'
   );
  
  var postHtml = "</body></html>";
  var html = preHtml+css+innert_html+postHtml;

  var blob = new Blob(['\ufeff', html], {
    type: 'application/msword'
  });

  // var blob = new Blob(['\ufeff', html], {
  //     type: 'application/msword'
  // });
  
  // Specify link url
  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
  
  // Specify file name
  filename = filename?filename+'.doc':'document.doc';
  
  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);
  
  if(navigator.msSaveOrOpenBlob ){
      navigator.msSaveOrOpenBlob(blob, filename);
  }else{
      // Create a link to the file
      downloadLink.href = url;
      
      // Setting the file name
      downloadLink.download = filename;
      
      //triggering the function
      downloadLink.click();
  }
  
  document.body.removeChild(downloadLink);
}


// data={data} 
// BaseURL={BaseURL} 
// logo={logo} 
// editableExpenses={editableExpenses} 
// extraExpenses={extraExpenses} 
// user={user}

const MyDoc_Document = (data, BaseURL, logo, editableExpenses, extraExpenses, user ) => {
  console.log(BaseURL,logo, Layer_1);
  const image = logo ? `${BaseURL}${logo}`: 'https://pat-api.profitfirsttools.com/media/user_301/2022-09-12_19_37_54-pig.8fdebc33.jpg_559396.png' ;

  //console.log("image >>", image);

  return (`<div id="docx"><div class="WordSection1">
            <div>
            <img src="${image}" width="100px" /> 
            </div>
            <div style="margin-top: 20px; margin-bottom: 20px;">
            Company Name: ${data.project_name || user.company}
            </div>
  <table border='1' style='border-collapse:collapse'>
                
                    <tr style="background-color: #1D457B;  color: #ffffff; padding: 10px">
                    <th>#</th>
                    <th>Actual ${data.dCurrency}</th>
                    <th>Actual%</th>
                    <th>PF%</th>
                    <th>PF ${data.dCurrency}</th>
                    <th>Delta</th>
                    <th>Fix</th>
                    </tr>
               
                    <tr style="padding: 10px">
                    <th scope="row">Top Line Revenue</th>
                        <td>${currency(data.topLineRevenue,data.cCode)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr style="padding: 10px">
                    <th scope="row">Material & Subcontractors</th>
                        <td>${currency(data.materialSubcontractors,data.cCode)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>  
                    </tr>
                    <tr style="padding: 10px">
                        <th scope="row">Real Revenue </th>
                        <td >${currency(data.realRevenue,data.cCode)}</td>                 
                        <td>100%</td>
                        <td>100%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr style="padding: 10px">
                        <th scope="row">Profit </th>
                        <td >${currency(data.profit,data.cCode)}</td>
                        <td>${Math.round((data.profit/data.realRevenue)*100)}%</td>
                        <td>${getPercent1(data.realRevenue)}%</td>
                        <td>${getPF(data.realRevenue,getPercent1(data.realRevenue),data.cCode)}</td>
                        <td>${getFix(data.profit,data.realRevenue,getPercent1(data.realRevenue),data.cCode)}</td>
                        <td>${getDelta(data.profit,data.realRevenue,getPercent1(data.realRevenue))}</td>
                    </tr>
                    <tr style="padding: 10px">
                        <th scope="row">Owner Comp</th>
                        <td >${currency(data.ownerComp,data.cCode)}</td>
                        <td>${Math.round((data.ownerComp/data.realRevenue)*100)}%</td>
                        <td>${getPercent2(data.realRevenue)}%</td>
                        <td>${getPF(data.realRevenue,getPercent2(data.realRevenue),data.cCode)}</td>
                        <td>${getFix(data.ownerComp,data.realRevenue,getPercent2(data.realRevenue),data.cCode)}</td>
                        <td>${getDelta(data.ownerComp,data.realRevenue,getPercent2(data.realRevenue))}</td>
                    </tr>
                    <tr style="padding: 10px">
                        <th scope="row">Tax </th>
                        <td >${currency(data.tax,data.cCode)}</td>
                        <td>${Math.round((data.tax/data.realRevenue)*100)}%</td>
                        <td>15%</td>
                        <td>${getPF(data.realRevenue,15,data.cCode)}</td>
                        <td>${getFix(data.tax,data.realRevenue,15,data.cCode)}</td>
                        <td>${getDelta(data.tax,data.realRevenue,15)}</td>
                    </tr>
                    <tr style="padding: 10px">
                        <th scope="row">Operating Expenses</th>
                        <td >${currency(data.operatingExpenses,data.cCode)}</td>
                        <td>${Math.round((data.operatingExpenses/data.realRevenue)*100)}%</td>
                        <td>${getPercent3(data.realRevenue)}%</td>
                        <td>${getPF(data.realRevenue,getPercent3(data.realRevenue),data.cCode)}</td>
                        <td>${getFix(data.operatingExpenses,data.realRevenue,getPercent3(data.realRevenue),data.cCode)}</td>
                        <td>${getDelta(data.operatingExpenses,data.realRevenue,getPercent3(data.realRevenue))}</td>
                    </tr>
                
                </table>
                
                <br/>
                <br style="page-break-before: always" />
                
                <table border='1' style='border-collapse:collapse;  '>
               
                    <tr style="background-color: #1D457B; color: #ffffff; padding: 10px">
                    <th scope="col">#</th>
                    <th scope="col">Actual ${data.dCurrency}</th>
                    <th scope="col">Actual%</th>
                    <th scope="col">Q1</th>
                    <th scope="col">Q2</th>
                    <th scope="col">Q3</th>
                    <th scope="col">Q4</th>
                    <th scope="col">Q5</th>
                    <th scope="col">Q6</th>
                    <th scope="col">PF ${data.dCurrency}</th>
                    </tr>
                
                    <tr className="custom_exp edit" style="padding: 10px">
                        <th scope="row">Profit </th>
                        <td >${currency(data.profit,data.cCode)}</td>
                        <td>${Math.round((data.profit/data.realRevenue)*100)}% </td>
                        <td><span>${parseFloat(editableExpenses[0].aq1).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[0].aq2).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[0].aq3).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[0].aq4).toFixed()}%</span></td>
                        <td><span>${parseFloat(editableExpenses[0].aq5).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[0].aq6).toFixed()}%</span> </td>
                        <td>${getPercent1(data.realRevenue)}%</td>
                    </tr>
                    <tr  className="custom_exp edit" style="padding: 10px">
                        <th scope="row">Owner Comp</th>
                        <td >${currency(data.ownerComp,data.cCode)}</td>
                        <td>${Math.round((data.ownerComp/data.realRevenue)*100)}%</td>
                        <td><span>${parseFloat(editableExpenses[1].aq1).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[1].aq2).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[1].aq3).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[1].aq4).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[1].aq5).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[1].aq6).toFixed()}%</span> </td>
                        <td>${getPercent2(data.realRevenue)}%</td>
                    </tr>
                    <tr className="custom_exp edit" style="padding: 10px">
                        <th scope="row">Tax </th>
                        <td >${currency(data.tax,data.cCode)}</td>
                        <td>${Math.round((data.tax/data.realRevenue)*100)}%</td>
                        <td><span>${parseFloat(editableExpenses[2].aq1).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[2].aq2).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[2].aq3).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[2].aq4).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[2].aq5).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[2].aq6).toFixed()}%</span> </td>
                        <td>15%</td>
                    </tr>
                    <tr className="custom_exp edit" style="padding: 10px">
                        <th scope="row">Operating Expenses</th>
                        <td >${currency(data.operatingExpenses,data.cCode)}</td>
                        <td>${Math.round((data.operatingExpenses/data.realRevenue)*100)}%</td>
                        <td><span>${parseFloat(editableExpenses[3].aq1).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[3].aq2).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[3].aq3).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[3].aq4).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[3].aq5).toFixed()}%</span> </td>
                        <td><span>${parseFloat(editableExpenses[3].aq6).toFixed()}%</span> </td>
                        <td>${getPercent3(data.realRevenue)}%</td>
                    </tr>
                    ${extraExpenses.map((item,i)=>
                      `<tr key=${i} className="custom_exp" style="padding: 10px">
                          <th scope="row" >${extraExpenses[i].name} </th>
                          <td>${currency(extraExpenses[i].value, data.cCode)} </td>
                          <td>${extraExpenses[i]?.actual}%</td>
                          <td>${extraExpenses[i]?.q1}%</td>
                          <td>${extraExpenses[i]?.q2}%</td>
                          <td>${extraExpenses[i]?.q3}%</td>
                          <td>${extraExpenses[i]?.q4}%</td>
                          <td>${extraExpenses[i]?.q5}%</td>
                          <td>${extraExpenses[i]?.q6}%</td>
                          <td></td>
                      </tr>`
                    )}
               
              </table>
              </div>
              </div></div>
               `)};




  
const MyDoc = (props) => (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.leftColumn}>
          {
            props.logo?
              <Image
              src={`${props.BaseURL}${props.logo}`}
              style={styles.image}
            />
            :<Image
            src={Layer_1}
            style={styles.image}
          />
          }
          
        </View>
        <View style={styles.comp}>
          <Text>Company Name: {props.data.project_name || props.user.company}</Text>
        </View>
        <View style={styles.table}>
            <View style={[styles.row, styles.header]}>
                <Text style={[styles.headerText, styles.cell]}>#</Text>
                <Text style={[styles.headerText, styles.cell]}>Actual {props.data.dCurrency}</Text>
                <Text style={[styles.headerText, styles.cell]}>Actual%</Text>
                <Text style={[styles.headerText, styles.cell]}>PF%</Text>
                <Text style={[styles.headerText, styles.cell]}>PF {props.data.dCurrency}</Text>
                <Text style={[styles.headerText, styles.cell]}>Delta</Text>
                <Text style={[styles.headerText, styles.cell]}>Fix</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Top Line Revenue</Text>
                <Text style={[styles.cell]}>{currency(props.data.topLineRevenue,props.data.cCode)}</Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Material & {'\n'} Subcontractor</Text>
                <Text style={[styles.cell]}>{currency(props.data.materialSubcontractors,props.data.cCode)}</Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Real Revenue</Text>
                <Text style={[styles.cell]}>{currency(props.data.realRevenue,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>100%</Text>
                <Text style={[styles.cell]}>100%</Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
                <Text style={[styles.cell]}></Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Profit</Text>
                <Text style={[styles.cell]}>{currency(props.data.profit,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.profit/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{getPercent1(props.data.realRevenue)}%</Text>
                <Text style={[styles.cell]}>{getPF(props.data.realRevenue,getPercent1(props.data.realRevenue),props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getFix(props.data.profit,props.data.realRevenue,10,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getDelta(props.data.profit,props.data.realRevenue,10)}</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Owner comp</Text>
                <Text style={[styles.cell]}>{currency(props.data.ownerComp,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.ownerComp/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{getPercent2(props.data.realRevenue)}%</Text>
                <Text style={[styles.cell]}>{getPF(props.data.realRevenue,getPercent2(props.data.realRevenue,props.data.cCode))}</Text>
                <Text style={[styles.cell]}>{getFix(props.data.ownerComp,props.data.realRevenue,getPercent2(props.data.realRevenue),props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getDelta(props.data.ownerComp,props.data.realRevenue,getPercent2(props.data.realRevenue))}</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Tax</Text>
                <Text style={[styles.cell]}>{currency(props.data.tax,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.tax/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>15%</Text>
                <Text style={[styles.cell]}>{getPF(props.data.realRevenue,15,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getFix(props.data.tax,props.data.realRevenue,15,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getDelta(props.data.tax,props.data.realRevenue,15)}</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Operating Expenses</Text>
                <Text style={[styles.cell]}>{currency(props.data.operatingExpenses,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.operatingExpenses/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{getPercent3(props.data.realRevenue)}%</Text>
                <Text style={[styles.cell]}>{getPF(props.data.realRevenue,getPercent3(props.data.realRevenue),props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getFix(props.data.operatingExpenses,props.data.realRevenue,getPercent3(props.data.realRevenue),props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{getDelta(props.data.operatingExpenses,props.data.realRevenue,getPercent3(props.data.realRevenue))}</Text>
            </View>
        </View>

        <View style={styles.table} wrap={false}>
            <View style={[styles.row, styles.header]}>
                <Text style={[styles.headerText, styles.cell]}>#</Text>
                <Text style={[styles.headerText, styles.cell]}>Actual {props.data.dCurrency}</Text>
                <Text style={[styles.headerText, styles.cell]}>Actual%</Text>
                <Text style={[styles.headerText, styles.cell]}>Q1</Text>
                <Text style={[styles.headerText, styles.cell]}>Q2</Text>
                <Text style={[styles.headerText, styles.cell]}>Q3</Text>
                <Text style={[styles.headerText, styles.cell]}>Q4 </Text>
                <Text style={[styles.headerText, styles.cell]}>Q5 </Text>
                <Text style={[styles.headerText, styles.cell]}>Q6 </Text>
                <Text style={[styles.headerText, styles.cell]}>PF {props.data.dCurrency} </Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Profit</Text>
                <Text style={[styles.cell]}>{currency(props.data.profit,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.profit/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq1)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq2)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq3)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq4)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq5)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[0].aq6)}%</Text> 
                <Text style={[styles.cell]}>{getPercent1(props.data.realRevenue)}%</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Owner Comp</Text>
                <Text style={[styles.cell]}>{currency(props.data.ownerComp,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.ownerComp/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq1)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq2)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq3)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq4)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq5)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[1].aq6)}%</Text>
                <Text style={[styles.cell]}>{getPercent2(props.data.realRevenue)}%</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Tax</Text>
                <Text break style={[styles.cell]}>{currency(props.data.tax,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.tax/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq1)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq2)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq3)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq4)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq5)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[2].aq6)}%</Text>
                <Text style={[styles.cell]}>15%</Text>
            </View>
            <View style={[styles.row]}>
                <Text style={[styles.cell]}>Operating Expenses</Text>
                <Text style={[styles.cell]}>{currency(props.data.operatingExpenses,props.data.cCode)}</Text>
                <Text style={[styles.cell]}>{Math.round((props.data.operatingExpenses/props.data.realRevenue)*100)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq1)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq2)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq3)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq4)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq5)}%</Text>
                <Text style={[styles.cell]}>{Math.round(props.editableExpenses[3].aq6)}%</Text>
                <Text style={[styles.cell]}>{getPercent3(props.data.realRevenue)}%</Text>
            </View>
            {props.extraExpenses.map((item,i)=>
                <View key={i} style={[styles.row]}>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i].name}</Text>
                    <Text  style={[styles.cell]}>{currency(props.extraExpenses[i].value,props.data.cCode)}</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.actual}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q1}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q2}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q3}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q4}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q5}%</Text>
                    <Text  style={[styles.cell]}>{props.extraExpenses[i]?.q6}%</Text>
                    <Text  style={[styles.cell]}></Text>
                </View>
              )}

        </View>
      </Page>
    </Document>
  );

  
  const MyReport = (props) => (
    <Document>
      <Page wrap  style={styles.page}>
        <View style={styles.hedingFont}>
          <Text style={{marginBottom: "20"}}>Details For Recommended Profit First Rollout</Text>
        </View>
        <View style={styles.standardFont} >
          <Text style={{fontWeight: "400"}}><Text style={{fontWeight: 1200}}>Total Revenue - </Text>It is common for business owners to focus aggressively on top line growth. In 
            our experience, entrepreneurs want to drive top line growth to increase the bottom line 
            (profitability), instead of just focusing on the bottom line itself. This often causes a trap of 
            constantly increasing revenue and increasing costs in parallel. That said, we believe it is usually 
            reasonable to expect continued top line growth while working to improve profitability</Text>
        </View>
        <View style={styles.standardFont}>
          <Text>Our recommendation: Drive profitability improvements as the priority over top line revenue 
            growth. Revenue growth will be a natural benefactor of improved profitability and focus.</Text>
        </View>
        
        <View style={styles.standardFont}>
          <Text>Mats & Subs (Materials & Subcontractors) - Reducing the costs of materials and subcontractors, 
            without compromising the quality and/or performance, is a powerful way to increase the amount 
            of Real Revenue. Since it won’t require more OpEx (Operating Expenses) that money typically 
            goes directly to Profit and Owner’s Compensation.</Text>
        </View>
      
        <View style={styles.standardFont}>
          <Text>Our recommendation:
              {parseInt(props.data.materialSubcontractors)===0 ? 'No recommendation necessary.':
              `Thoroughly analyze the Materials and Subcontractors 
              costs, and seek ways to decrease these costs. Typically, an 80/20 (Pareto) principle applies 
              to this category and can be used to find costs to cut that won’t affect the performance or 
            quality of your offering.`}</Text>
        </View>

        <View style={styles.standardFont}>
          <Text>Real Revenue - Your Real Revenue is the revenue your business generates when Mats & Subs are 
              excluded. This number is used to represent the ‘true’ income of a company, and the cash flow it 
              manages ‘above’ Real Revenue.</Text>
        </View>
        
        <View style={styles.standardFont}>
          <Text>Our recommendation: No recommendation necessary.</Text>
        </View>

        <View style={styles.standardFont}>
          <Text>Profit – For the purposes of Profit First, “Profit” refers to the cash you intentionally retain in your 
            business or distribute to owners/shareholders as Profit rather than the “profit” shown on the 
            Profit and Loss Statement. The reason for this is you can show a “profit on paper,” and yet not 
            have the money in the bank to match the bottom line of your P&L (or Income Statement). This 
            “discrepancy” is due to acquisition of assets, reduction of debt, and owner’s draws and 
            distributions, all of which show up in different accounts on the Profit Assessment and don’t show 
            up at all on the P&L.</Text>
        </View>
        <View style={styles.standardFont}>
          <Text>A point of clarification: Profit, Tax, and Owner’s Compensation are all for the benefit of the 
            business owner. At your Real Revenue level, the sum of these three accounts would be 70% if 
            target allocation percentages were attained. Historically, the sum of these three accounts have 
            been 43% for your business.</Text>
        </View>
        <View style={styles.standardFont}>
          <Text>Owner’s Compensation – Profit First recommends 50% Owner’s Comp for fiscally healthy 
          companies experiencing Real Revenue similar to your business’s. However, regardless of Real 
          Revenue, we have to be mindful of making sure the owner’s financial needs are being met.
          Historically, your Owner’s Comp has been 16% of your Real Revenue.</Text>
        </View>
        <View style={styles.standardFont}>
          <Text>Tax – The Tax account is used to pay both the tax on the business’s profits and the income tax of 
            the business owners. Without a Tax account, the owner’s income typically further has taxes 
            deducted from it. The Tax account allocates money to pay that tax liability.</Text>
          <Text>You should only use the Tax account for the tax liability generated by the profit in your business 
            and for the owner’s payroll tax liability. If you think you might benefit from allocating for other 
            taxes (sales tax, employee payroll tax, property tax, etc.), I recommend setting up separate 
            accounts for those taxes.</Text>
        </View>
        <View  style={styles.standardFont}>
          <Text>You should only use the Tax account for the tax liability generated by the profit in your business 
            and for the owner’s payroll tax liability. If you think you might benefit from allocating for other 
            taxes (sales tax, employee payroll tax, property tax, etc.), I recommend setting up separate 
            accounts for those taxes.</Text>
          <Text>Even as revenues increase, do not increase your Operating Expenses until it’s 
            absolutely necessary to do so. Doing this will result in your OpEx percentage naturally 
            improving without any effort on your part.</Text>
        </View>

      </Page>
    </Document>
  )
  
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      minWidth              : '800px'
    },
    closeButtton:{
      backgroundColor:'transparent',
      border:0,
      position:'absolute',
      right:10,
      top:10
    }
  };

export default function AssessmentModal({BaseURL,modalIsOpen,closeModal,data,user,saveOtherExp,saveEditExp,logo, assessType}){
    const [generalExp, setGeneralExp] = React.useState(data.operatingExpenses);

    const [extraExpenses, setExtraExpenses] = React.useState(data?.exp);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showQuater, setShowQuater] = React.useState(false);
    const [editable, setEditable] = React.useState(false);

    const [anchorE2, setAnchorE2] = React.useState(null);

    const [anchorE3, setAnchorE3] = React.useState(null);
    let token = localStorage.getItem('userAccessToken')

    useEffect(()=>{
      const allSum = extraExpenses.reduce((total, obj) => {
        if((typeof obj.value==='string' || typeof obj.value==='number') && !!obj.value ){
          return parseInt(obj.value) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const current = data.operatingExpenses 
      setGeneralExp(current-parseInt(allSum))

      const allAc = extraExpenses.reduce((total, obj) => {
        if((typeof obj.actual==='string' || typeof obj.actual==='number') && !!obj.actual ){
          return parseInt(obj.actual) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentAc = Math.round((data.operatingExpenses/data.realRevenue)*100)

      setActual(currentAc-parseInt(allAc))

      const allQ1 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q1==='string' || typeof obj.q1==='number') && !!obj.q1 ){
          return parseInt(obj.q1) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ1 = getQuater(Math.round((data.operatingExpenses/data.realRevenue)*100),Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)) 
      setQ1(currentQ1-parseInt(allQ1))


      const allQ2 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q2==='string' || typeof obj.q2==='number') && !!obj.q2 ){
          return parseInt(obj.q2) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ2 = getQuater2(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue))
      setQ2(currentQ2-parseInt(allQ2))

      const allQ3 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q3==='string' || typeof obj.q3==='number') && !!obj.q3 ){
          return parseInt(obj.q3) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ3 = getQuater3(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)) 
      setQ3(currentQ3-parseInt(allQ3))

      const allQ4 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q4==='string' || typeof obj.q4==='number') && !!obj.q4 ){
          return parseInt(obj.q4) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ4 = getQuater4(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100) ,getPercent3(data.realRevenue))
      setQ4(currentQ4-parseInt(allQ4))

      const allQ5 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q5==='string' || typeof obj.q5==='number') && !!obj.q5 ){
          return parseInt(obj.q5) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ5 = getQuater5(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue))
      setQ5(currentQ5-parseInt(allQ5))

      const allQ6 = extraExpenses.reduce((total, obj) => {
        if((typeof obj.q6==='string' || typeof obj.q6==='number') && !!obj.q6 ){
          return parseInt(obj.q6) + parseInt(total)
        } else{
          return parseInt(0) + parseInt(total)
        }
      },0)
      const currentQ6 = getQuater6(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)) 
      setQ6(currentQ6-parseInt(allQ6))


    },[extraExpenses])


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    const handleClick_AnchorE2 = (event) => {
      setAnchorE2(event.currentTarget);
    };
  
    const handleClose_AnchorE2 = () => {
      setAnchorE2(null);
    };

    const handleClick_AnchorE3 = (event) => {
      setAnchorE3(event.currentTarget);
    };
  
    const handleClose_AnchorE3 = () => {
      setAnchorE3(null);
    };


    const addNewExp = (arg) => {
      const exp = [...extraExpenses]
      const newObje = {name:"",value:""};
      exp.push(newObje)
      setExtraExpenses(exp)
    }
    const removeNewExp = (arg) => {
      const exp = [...extraExpenses]

      exp.splice((arg), 1);
      console.log(arg,exp)
      setExtraExpenses(exp)
    }
    const getRrPercentage = (val) => {
      console.log(parseInt(val),parseInt(data.realRevenue))
      return Math.round((parseInt(val)/parseInt(data.realRevenue))*100);
    }

    const handleChange  = (e) => {
      const className = e.target.className.split(' ')[0].trim()
      if(["name","value","actual","q1","q2","q3","q4","q5","q6"].includes(className)){
        let exp=[...extraExpenses]
        if(className==='value'){
          exp[e.target.dataset.id]['actual']=getRrPercentage(e.target.value)
        }
        exp[e.target.dataset.id][className]=e.target.value
        saveOtherExp(exp)
        setExtraExpenses(exp)
      } else {
        console.log({[e.target.name]:e.target.value},'sss')
        // setExtraExpenses({[e.target.name]:e.target.value})
      }

      if(["aq1","aq2","aq3","aq4","aq5","aq6"].includes(className)){
        let exp=[...editableExpenses]
        console.log()
        exp[e.target.dataset.id][className]=e.target.value
        // // saveOtherExp(exp)
        saveEditExp(exp)
        setEditableExpenses(exp)
      }

    }

    const openFile = (link) =>{
        axios({url: `${link}`, 
            method: 'GET',
            headers: { "Authorization": `JWT ${token}` }, 
            responseType: 'blob',})
                .then((response) => {
                // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;

                    // 1) Get the value of content-disposition header
                    const contentDisposition = response.headers['content-disposition'];
                    console.log('response headers: ', response.headers);

                    console.log('contentDisposition: ', contentDisposition);

                    // 2) set the fileName variable to the default value
                    let fileName = 'DownloadedFile';

                    // 3) if the header is set, extract the filename
                    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) { 
                          fileName = matches[1].replace(/['"]/g, '');
                        }
                    }
                  
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    console.log("file downloaded");
                }).catch(error => {
                console.log("*****  "+error)
            });
    }

    const getQuater2= (val, val2,percent) =>{
      return getQuater(getQuater(Math.round((val/data.realRevenue)*100),Math.round((val/data.realRevenue)*100),percent), val2,percent)
    }
    const getQuater3= (val, val2 ,percent) =>{
      return getQuater(getQuater2(val,val2,percent), val2,percent)
    }
    const getQuater4 = (val,val2,percent) =>{
      return getQuater(getQuater3(val,val2,percent),val2,percent)
    }
    const getQuater5 = (val,val2,percent) =>{
      return getQuater(getQuater4(val,val2,percent), val2,percent)
    }
    const getQuater6 = (val,val2,percent) =>{
      return getQuater(getQuater5(val,val2,percent), val2,percent)
    }
    const [actual, setActual] = React.useState(Math.round((generalExp/data.realRevenue)*100));
    const [q1, setQ1] = React.useState(getQuater(Math.round((generalExp/data.realRevenue)*100),Math.round((generalExp/data.realRevenue)*100),getPercent3(data.realRevenue)));
    const [q2, setQ2] = React.useState(getQuater2(generalExp,Math.round((generalExp/data.realRevenue)*100),getPercent3(data.realRevenue)));
    const [q3, setQ3] = React.useState(getQuater3(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)));
    const [q4, setQ4] = React.useState(getQuater4(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)));
    const [q5, setQ5] = React.useState(getQuater5(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)));
    const [q6, setQ6] = React.useState(getQuater6(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)));

    const allQuater=[
      {
        aq1:getQuater(Math.round((data.profit/data.realRevenue)*100),Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue)),
        aq2:getQuater2(data.profit,Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue),),
        aq3:getQuater3(data.profit,Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue)),
        aq4:getQuater4(data.profit,Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue)),
        aq5:getQuater5(data.profit,Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue)),
        aq6:getQuater6(data.profit,Math.round((data.profit/data.realRevenue)*100),getPercent1(data.realRevenue)),
      },
      {
        aq1:getQuater(Math.round((data.ownerComp/data.realRevenue)*100),Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
        aq2:getQuater2(data.ownerComp,Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
        aq3:getQuater3(data.ownerComp,Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
        aq4:getQuater4(data.ownerComp,Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
        aq5:getQuater5(data.ownerComp,Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
        aq6:getQuater6(data.ownerComp,Math.round((data.ownerComp/data.realRevenue)*100),getPercent2(data.realRevenue)),
      },
      {
        aq1:getQuater(Math.round((data.tax/data.realRevenue)*100),Math.round((data.tax/data.realRevenue)*100),15),
        aq2:getQuater2(data.tax,Math.round((data.tax/data.realRevenue)*100),15),
        aq3:getQuater3(data.tax,Math.round((data.tax/data.realRevenue)*100),15),
        aq4:getQuater4(data.tax,Math.round((data.tax/data.realRevenue)*100),15),
        aq5:getQuater5(data.tax,Math.round((data.tax/data.realRevenue)*100),15),
        aq6:getQuater6(data.tax,Math.round((data.tax/data.realRevenue)*100),15),
      },
      {
        aq1: getQuater(Math.round((data.operatingExpenses/data.realRevenue)*100),Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
        aq2: getQuater2(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
        aq3: getQuater3(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
        aq4: getQuater4(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
        aq5: getQuater5(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
        aq6: getQuater6(data.operatingExpenses,Math.round((data.operatingExpenses/data.realRevenue)*100),getPercent3(data.realRevenue)),
      }
    ]


    const [editableExpenses, setEditableExpenses] = React.useState(data.edited_quater || allQuater);
    const getCompProject = () =>{
      return data.project_name || user.company;
    }
     

    return (
        <>
        <Modal
          isOpen={modalIsOpen}
        //   onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
            <button style={customStyles.closeButtton} onClick={closeModal}>X</button>
            
            <div className="analysis-table-report">
              <div className="logo">
                <img alt='' src={Layer_1} className='logo_report'></img>
            </div>
            </div>
            {!showQuater?
            <div className="table-responsive">
                <table className="table analysis-table custom_price_table" id="quickTable">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Actual {data.dCurrency}</th>
                    <th scope="col">Actual%</th>
                    <th scope="col">PF%</th>
                    <th scope="col">PF {data.dCurrency}</th>
                    <th scope="col">Delta</th>
                    <th scope="col">Fix</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">Top Line Revenue</th>
                        <td>{currency(data.topLineRevenue,data.cCode)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                    <th scope="row">Material & Subcontractors</th>
                        <td>{currency(data.materialSubcontractors,data.cCode)}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>  
                    </tr>
                    <tr>
                        <th scope="row">Real Revenue </th>
                        <td >{currency(data.realRevenue,data.cCode)}</td>                 
                        <td>100%</td>
                        <td>100%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Profit </th>
                        <td >{currency(data.profit,data.cCode)}</td>
                        <td>{Math.round((data.profit/data.realRevenue)*100)}%</td>
                        <td>{getPercent1(data.realRevenue)}%</td>
                        <td>{getPF(data.realRevenue,getPercent1(data.realRevenue),data.cCode)}</td>
                        <td>{getFix(data.profit,data.realRevenue,getPercent1(data.realRevenue),data.cCode)}</td>
                        <td>{getDelta(data.profit,data.realRevenue,getPercent1(data.realRevenue))}</td>
                    </tr>
                    <tr>
                        <th scope="row">Owner Comp</th>
                        <td >{currency(data.ownerComp,data.cCode)}</td>
                        <td>{Math.round((data.ownerComp/data.realRevenue)*100)}%</td>
                        <td>{getPercent2(data.realRevenue)}%</td>
                        <td>{getPF(data.realRevenue,getPercent2(data.realRevenue),data.cCode)}</td>
                        <td>{getFix(data.ownerComp,data.realRevenue,getPercent2(data.realRevenue),data.cCode)}</td>
                        <td>{getDelta(data.ownerComp,data.realRevenue,getPercent2(data.realRevenue))}</td>
                    </tr>
                    <tr>
                        <th scope="row">Tax </th>
                        <td >{currency(data.tax,data.cCode)}</td>
                        <td>{Math.round((data.tax/data.realRevenue)*100)}%</td>
                        <td>15%</td>
                        <td>{getPF(data.realRevenue,15,data.cCode)}</td>
                        <td>{getFix(data.tax,data.realRevenue,15,data.cCode)}</td>
                        <td>{getDelta(data.tax,data.realRevenue,15)}</td>
                    </tr>
                    <tr>
                        <th scope="row">Operating Expenses</th>
                        <td >{currency(data.operatingExpenses,data.cCode)}</td>
                        <td>{Math.round((data.operatingExpenses/data.realRevenue)*100)}%</td>
                        <td>{getPercent3(data.realRevenue)}%</td>
                        <td>{getPF(data.realRevenue,getPercent3(data.realRevenue),data.cCode)}</td>
                        <td>{getFix(data.operatingExpenses,data.realRevenue,getPercent3(data.realRevenue),data.cCode)}</td>
                        <td>{getDelta(data.operatingExpenses,data.realRevenue,getPercent3(data.realRevenue))}</td>
                    </tr>
                    <tr>
                        <th scope="row">Total Percentage</th>
                        <td></td>
                        <td>{Math.round(parseFloat(parseFloat((data.profit/data.realRevenue)*100) + parseFloat((data.ownerComp/data.realRevenue)*100) + parseFloat((data.tax/data.realRevenue)*100) + parseFloat((data.operatingExpenses/data.realRevenue)*100)))}%</td>
                        <td>100%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {/* {extraExpenses.map((item,i)=>
                      <tr key={i} className="custom_exp">
                          <th scope="row" ><input type="text" placeholder="Expense Name" data-id={i} name={`exp-${i}`} value={extraExpenses[i].name} className="name"/></th>
                          <td colSpan="5"><input type="number" placeholder="Expense Value" data-id={i} name={`val-${i}`} value={extraExpenses[i].value} className="value"/></td>
                          <td className="p_m_button"><button type="button" onClick={()=>removeNewExp(i)}>-</button></td>
                      </tr>
                    )} */}
                </tbody>
                </table>
                <button type="buttton" onClick={()=>setShowQuater(true)} className="rtl-btn">Roll Out Analysis</button>
            </div>
            : null}
            {showQuater?
            <div className="table-responsive">
            <form onChange={handleChange}>
              <table className={`table analysis-table custom_price_table ${editable? 'editable': null}`} id="quickTable">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Actual {data.dCurrency}</th>
                    <th scope="col">Actual%</th>
                    <th scope="col">Q1</th>
                    <th scope="col">Q2</th>
                    <th scope="col">Q3</th>
                    <th scope="col">Q4</th>
                    <th scope="col">Q5</th>
                    <th scope="col">Q6</th>
                    <th scope="col">PF {data.dCurrency}</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="custom_exp edit">
                        <th scope="row">Profit </th>
                        <td >{currency(data.profit,data.cCode)}</td>
                        <td>{Math.round((data.profit/data.realRevenue)*100)}% </td>
                        <td><span>{parseFloat(editableExpenses[0].aq1).toFixed()}%</span> <input type="number" placeholder="Q1" data-id="0" name={`aq1-0`} value={parseFloat(editableExpenses[0].aq1).toFixed()} className="aq1"/></td>
                        <td><span>{parseFloat(editableExpenses[0].aq2).toFixed()}%</span> <input type="number" placeholder="Q2" name={`aq1-1`}  data-id="0" value={parseFloat(editableExpenses[0].aq2).toFixed()} className="aq2"/></td>
                        <td><span>{parseFloat(editableExpenses[0].aq3).toFixed()}%</span> <input type="number" placeholder="Q3" name={`aq1-2`}  data-id="0" value={parseFloat(editableExpenses[0].aq3).toFixed()} className="aq3"/></td>
                        <td><span>{parseFloat(editableExpenses[0].aq4).toFixed()}%</span> <input type="number" placeholder="Q4" name={`aq1-3`}  data-id="0" value={parseFloat(editableExpenses[0].aq4).toFixed()} className="aq4"/></td>
                        <td><span>{parseFloat(editableExpenses[0].aq5).toFixed()}%</span> <input type="number" placeholder="Q5" name={`aq1-4`}  data-id="0" value={parseFloat(editableExpenses[0].aq5).toFixed()} className="aq5"/></td>
                        <td><span>{parseFloat(editableExpenses[0].aq6).toFixed()}%</span> <input type="number" placeholder="Q6" name={`aq1-5`}  data-id="0" value={parseFloat(editableExpenses[0].aq6).toFixed()} className="aq6"/></td>
                        <td>{getPercent1(data.realRevenue)}%</td>
                    </tr>
                    <tr  className="custom_exp edit">
                        <th scope="row">Owner Comp</th>
                        <td >{currency(data.ownerComp,data.cCode)}</td>
                        <td>{Math.round((data.ownerComp/data.realRevenue)*100)}%</td>
                        <td><span>{parseFloat(editableExpenses[1].aq1).toFixed()}%</span> <input type="number" placeholder="Q1" name={`aq2-0`} data-id="1" value={parseFloat(editableExpenses[1].aq1).toFixed()} className="aq1"/></td>
                        <td><span>{parseFloat(editableExpenses[1].aq2).toFixed()}%</span> <input type="number" placeholder="Q2" name={`aq2-1`}  data-id="1" value={parseFloat(editableExpenses[1].aq2).toFixed()} className="aq2"/></td>
                        <td><span>{parseFloat(editableExpenses[1].aq3).toFixed()}%</span> <input type="number" placeholder="Q3" name={`aq2-2`}  data-id="1" value={parseFloat(editableExpenses[1].aq3).toFixed()} className="aq3"/></td>
                        <td><span>{parseFloat(editableExpenses[1].aq4).toFixed()}%</span> <input type="number" placeholder="Q4" name={`aq2-3`}  data-id="1" value={parseFloat(editableExpenses[1].aq4).toFixed()} className="aq4"/></td>
                        <td><span>{parseFloat(editableExpenses[1].aq5).toFixed()}%</span> <input type="number" placeholder="Q5" name={`aq2-4`}  data-id="1" value={parseFloat(editableExpenses[1].aq5).toFixed()} className="aq5"/></td>
                        <td><span>{parseFloat(editableExpenses[1].aq6).toFixed()}%</span> <input type="number" placeholder="Q6" name={`aq2-5`}  data-id="1" value={parseFloat(editableExpenses[1].aq6).toFixed()} className="aq6"/></td>
                        <td>{getPercent2(data.realRevenue)}%</td>
                    </tr>
                    <tr className="custom_exp edit">
                        <th scope="row">Tax </th>
                        <td >{currency(data.tax,data.cCode)}</td>
                        <td>{Math.round((data.tax/data.realRevenue)*100)}%</td>
                        <td><span>{parseFloat(editableExpenses[2].aq1).toFixed()}%</span> <input type="number" placeholder="Q1" name={`aq3-0`} data-id="2" value={parseFloat(editableExpenses[2].aq1).toFixed()} className="aq1"/></td>
                        <td><span>{parseFloat(editableExpenses[2].aq2).toFixed()}%</span> <input type="number" placeholder="Q2"  name={`aq3-1`} data-id="2" value={parseFloat(editableExpenses[2].aq2).toFixed()} className="aq2"/></td>
                        <td><span>{parseFloat(editableExpenses[2].aq3).toFixed()}%</span> <input type="number" placeholder="Q3"  name={`aq3-2`} data-id="2" value={parseFloat(editableExpenses[2].aq3).toFixed()} className="aq3"/></td>
                        <td><span>{parseFloat(editableExpenses[2].aq4).toFixed()}%</span> <input type="number" placeholder="Q4"  name={`aq3-3`} data-id="2" value={parseFloat(editableExpenses[2].aq4).toFixed()} className="aq4"/></td>
                        <td><span>{parseFloat(editableExpenses[2].aq5).toFixed()}%</span> <input type="number" placeholder="Q5"  name={`aq3-4`} data-id="2" value={parseFloat(editableExpenses[2].aq5).toFixed()} className="aq5"/></td>
                        <td><span>{parseFloat(editableExpenses[2].aq6).toFixed()}%</span> <input type="number" placeholder="Q6"  name={`aq3-5`} data-id="2" value={editableExpenses[2].aq6} className="aq6"/></td>
                        <td>15%</td>
                    </tr>
                    <tr className="custom_exp edit">
                        <th scope="row">Operating Expenses</th>
                        <td >{currency(data.operatingExpenses,data.cCode)}</td>
                        <td>{Math.round((data.operatingExpenses/data.realRevenue)*100)}%</td>
                        <td><span>{parseFloat(editableExpenses[3].aq1).toFixed()}%</span> <input type="number"  data-id="3" name={`aq4-0`} placeholder="Q1" value={parseFloat(editableExpenses[3].aq1).toFixed()} className="aq1"/></td>
                        <td><span>{parseFloat(editableExpenses[3].aq2).toFixed()}%</span> <input type="number" data-id="3" placeholder="Q2" name={`aq4-0`} value={parseFloat(editableExpenses[3].aq2).toFixed()} className="aq2"/></td>
                        <td><span>{parseFloat(editableExpenses[3].aq3).toFixed()}%</span> <input type="number" data-id="3" placeholder="Q3" name={`aq4-0`} value={parseFloat(editableExpenses[3].aq3).toFixed()} className="aq3"/></td>
                        <td><span>{parseFloat(editableExpenses[3].aq4).toFixed()}%</span> <input type="number" data-id="3" placeholder="Q4" name={`aq4-0`} value={parseFloat(editableExpenses[3].aq4).toFixed()} className="aq4"/></td>
                        <td><span>{parseFloat(editableExpenses[3].aq5).toFixed()}%</span> <input type="number" data-id="3" placeholder="Q5" name={`aq4-0`} value={parseFloat(editableExpenses[3].aq5).toFixed()} className="aq5"/></td>
                        <td><span>{parseFloat(editableExpenses[3].aq6).toFixed()}%</span> <input type="number" data-id="3" placeholder="Q6" name={`aq4-0`} value={parseFloat(editableExpenses[3].aq6).toFixed()} className="aq6"/></td>
                        <td>{getPercent3(data.realRevenue)}%</td>
                    </tr>
                    <tr className="custom_exp edit">
                        <th scope="row">Total Percentage</th>
                        <td ></td>
                        <td></td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq1) + parseFloat(editableExpenses[1].aq1) + parseFloat(editableExpenses[2].aq1) + parseFloat(editableExpenses[3].aq1)).toFixed()}%</td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq2) + parseFloat(editableExpenses[1].aq2) + parseFloat(editableExpenses[2].aq2) + parseFloat(editableExpenses[3].aq2)).toFixed()}%</td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq3) + parseFloat(editableExpenses[1].aq3) + parseFloat(editableExpenses[2].aq3) + parseFloat(editableExpenses[3].aq3)).toFixed()}%</td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq4) + parseFloat(editableExpenses[1].aq4) + parseFloat(editableExpenses[2].aq4) + parseFloat(editableExpenses[3].aq4)).toFixed()}%</td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq5) + parseFloat(editableExpenses[1].aq5) + parseFloat(editableExpenses[2].aq5) + parseFloat(editableExpenses[3].aq5)).toFixed()}%</td>
                        <td>{parseFloat(parseFloat(editableExpenses[0].aq6) + parseFloat(editableExpenses[1].aq6) + parseFloat(editableExpenses[2].aq6) + parseFloat(editableExpenses[3].aq6)).toFixed()}%</td>
                        <td></td>
                    </tr>
                    {assessType=='f' && data.id?
                    <tr>
                        <th scope="row">General Expenses</th>
                        <td >{currency(generalExp,data.cCode)}</td>
                        <td>{actual}%</td>                      
                        <td>{parseFloat(q1).toFixed()}%</td>
                        <td>{parseFloat(q2).toFixed()}%</td>
                        <td>{parseFloat(q3).toFixed()}%</td>
                        <td>{parseFloat(q4).toFixed()}%</td>
                        <td>{parseFloat(q5).toFixed()}%</td>
                        <td>{parseFloat(q6).toFixed()}%</td>
                        <td  className="p_m_button"><button type="button" onClick={()=>addNewExp(0)}>+</button></td>
                    </tr>
                    : null }
                    {extraExpenses.map((item,i)=>
                      <tr key={i} className="custom_exp">
                          <th scope="row" ><input type="text" placeholder="Expense Name" data-id={i} name={`exp-${i}`} value={extraExpenses[i].name} className="name"/></th>
                          <td><input type="number" placeholder="Expense Value" data-id={i} name={`val-${i}`} value={extraExpenses[i].value} className="value"/></td>
                          <td><input disabled type="number" placeholder="Actual" data-id={i} name={`ac-${i}`} value={extraExpenses[i]?.actual} className="actual"/>%</td>
                          <td><input type="number" placeholder="Q1" data-id={i} name={`q1-${i}`} value={extraExpenses[i]?.q1} className="q1"/></td>
                          <td><input type="number" placeholder="Q2" data-id={i} name={`q2-${i}`} value={extraExpenses[i]?.q2} className="q2"/></td>
                          <td><input type="number" placeholder="Q3" data-id={i} name={`q3-${i}`} value={extraExpenses[i]?.q3} className="q3"/></td>
                          <td><input type="number" placeholder="Q4" data-id={i} name={`q4-${i}`} value={extraExpenses[i]?.q4} className="q4"/></td>
                          <td><input type="number" placeholder="Q5" data-id={i} name={`q5-${i}`} value={extraExpenses[i]?.q5} className="q5"/></td>
                          <td><input type="number" placeholder="Q6" data-id={i} name={`q6-${i}`} value={extraExpenses[i]?.q6} className="q6"/></td>
                          <td className="p_m_button"><button type="button" onClick={()=>removeNewExp(i)}>-</button></td>
                      </tr>
                    )}
                </tbody>
              </table>
            </form>
            <button type="buttton" onClick={()=>setShowQuater(false)} className="rtl-btn">View run rate</button>
            </div>
            :null}
            <div className="table_btn">
              
              {data.id ? 
                <>
                <Button aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    className="mt-20"
                    color="primary"
                    onClick={handleClick_AnchorE3}>
                  Download Assessments
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorE3}
                  keepMounted
                  open={Boolean(anchorE3)}
                  onClose={handleClose_AnchorE3}
                >
                  <MenuItem onClick={handleClose_AnchorE3}>
                    <PDFDownloadLink 
                      document={
                        <MyDoc 
                          data={data} 
                          BaseURL={BaseURL} 
                          logo={logo} 
                          editableExpenses={editableExpenses} 
                          extraExpenses={extraExpenses} 
                          user={user}/>
                        } 
                      fileName={"assessment-"+getCompProject()+".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <>Download Assessment in PDF</>)}
                    </PDFDownloadLink>
                  </MenuItem>
                  <MenuItem onClick={handleClose_AnchorE3}>
                    <span onClick={()=>Export2Word('exportContent',  data, BaseURL, logo, editableExpenses, extraExpenses, user, 'assessment-'+getCompProject() )}> Download Assessment in Doc</span>
                  </MenuItem>
                </Menu>
                </>


               : null 
              }



              

              {assessType=='f' && data.id? 
              
              <>
              <Button aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  className="mt-20 ml-10"
                  color="primary"
                  onClick={handleClick_AnchorE2}>
                Download Reports
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorE2}
                keepMounted
                open={Boolean(anchorE2)}
                onClose={handleClose_AnchorE2}
              >
                <MenuItem onClick={handleClose_AnchorE2}><a role="button" href="#"  onClick={()=>openFile(`${BaseURL}/api/v1/assessments/report5/${data.id}`)} >Download Report in PDF</a></MenuItem>
                <MenuItem onClick={handleClose_AnchorE2}><a role="button" href="#"  onClick={()=>openFile(`${BaseURL}/api/v1/assessments/word-report-5/${data.id}`)} >Download Report in Doc</a></MenuItem>
              </Menu>
              </>
              
              :null}
              {/* {
                data.id?
                <a role="button" className="report-download" target="_blank" href={`${BaseURL}/api/v1/assessments/convert-to-pdf/${data.id}`} download>Download Evaluation Report</a>
                :null
              } */}
              {
               assessType=='f' &&  !editable && showQuater && data.id?
                <button onClick={()=>setEditable(true)} className="report-download">Edit Quarters</button>
                :null
              }
              {assessType=='f' && data.id?
              <>
              <Button aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  className="mt-20 ml-10"
                  color="primary"
                  onClick={handleClick}>
                Presentations
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}><a role="button" href="#"  onClick={()=>openFile(`${BaseURL}/api/v1/assessments/convert-to-pdf/${data.id}`)} >Download Evaluation Report in PDF</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" href="#" onClick={()=>openFile(`${BaseURL}/api/v1/assessments/convert-to-word/${data.id}`)} >Download Evaluation Report in Doc</a></MenuItem>
                {/* <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/report2/${data.id}`} download>Download Profit Report in PDF</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/word-report-2/${data.id}`} download>Download Profit Report in Doc</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/report3/${data.id}`} download>Profit First Report in PDF</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/word-report-3/${data.id}`} download>Profit First Report in Doc</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/report4/${data.id}`} download>Money Management Report in PDF</a></MenuItem>
                <MenuItem onClick={handleClose}><a role="button" target="_blank" href={`${BaseURL}/api/v1/assessments/word-report-4/${data.id}`} download>Money Management Report in Doc</a></MenuItem> */}
              </Menu>
              </>:
              null}
              {/* <a className="report-download" href={BaseURL+ '/api/v1/assessments/convert-to-pdf/'+ user.id}>Download pdf report</a> */}
              </div>
        </Modal>
        </>
    )

}