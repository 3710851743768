import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import Modal from 'react-modal';
import SidePanel from './SidePanel'
import AssessmentModal from './AssessmentModal'
import styles from '../components_css/Quick_assessment.module.css'
import modalStyles from '../components_css/Analyzing_report.module.css'
import cx from 'classnames'
import video_player from '../images/video-player.svg'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import {formatDate, formatDate2} from '../utils/globalFunction'
import {formatEndDate} from '../utils/globalFunction'
import {notify, momentConvertFormat} from '../utils/globalFunction'
import * as allCurrency from '../constant/CURRENCY';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      minWidth              : '700px',
    },
    closeButtton:{
      backgroundColor:'transparent',
      border:0,
      position:'absolute',
      right:10,
      top:10
    }
  };


function Worksheet6({ BaseURL,topLine,profit,owner,tax,opEx,real,mat,analyzeCall,backCall,adjustmentCall,mainData,updateCurrentStatus }) {
    const [currency, setCurrency] = useState(allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.symbol || '$')
    const [cCode, setCCode] = useState(allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.code || 'USD')


    const history = useHistory();

    const [Q20, setQ20] = useState((typeof topLine==='undefined' || topLine==='' || topLine==='NaN')? 0 : topLine)
    const [Q25, setQ25] = useState((typeof mat==='undefined' || mat==='' || mat==='NaN')? 0 : mat)
    const [Q26, setQ26] = useState((typeof real==='undefined' || real==='' || real==='NaN')? 0 : real)
    const [Q27, setQ27] = useState((typeof profit==='undefined' || profit==='' || profit==='NaN')? 0 : (parseFloat(profit)+parseFloat(mainData?.worksheet?.profit_adjustment)).toFixed())
    const [Q34, setQ34] = useState((typeof owner==='undefined' || owner==='' || owner==='NaN')? 0 : owner)
    const [Q48, setQ48] = useState((typeof tax==='undefined' || tax==='' || tax==='NaN')? 0 : tax)
    const [Q52, setQ52] = useState((typeof opEx==='undefined' || opEx==='' || opEx==='NaN')? 0 :  (parseFloat(opEx)+parseFloat(mainData?.worksheet?.operating_expenses_adjustment)).toFixed())
    const [newBalance, setNewBalance] = useState((parseFloat(Q26)-(parseFloat(Q27)+parseFloat(Q34)+parseFloat(Q48)+parseFloat(Q52))).toFixed())
    const [ButtonColor, setButtonColor] = useState('inactive')
    const [Balance, setBalance] = useState('0')
    const [ShowPopup, setShowPopup] = useState(false)
    const [user, setUser] = useState(mainData.client_information)
    const [logo, setLogo] = useState(null)
    const [annualized, setAnnualized] = useState(false)
    const [annualizedBeforeAdjustment, setAnnualizedBeforeAdjustment] = useState(false)


    
    const [runRateMonth, setRunRateMonth] = useState(1)
    const [rateStartMonth, setRateStartMonth] = useState('')
    const [rateEndMonth, setRateEndMonth] = useState('')

    const [analyze,setAnalyze] = useState(false)

    const [openModel, setOpenModel] =  useState(false)
    const assessType = 'f';  //full assessment
    let token = localStorage.getItem('userAccessToken')

    useEffect(() => {
        
        window.scrollTo(0, 0);

        axios.get(`${BaseURL}/api/v1/user/retrieve-settings/`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
            setLogo(res?.data?.data?.logo)
        })

    },[token])
    useEffect(() => {
        if(!!rateStartMonth && runRateMonth){
            console.log(rateStartMonth);
            const date = new Date(rateStartMonth);
            const end = new Date(date.setMonth(date.getMonth() + parseInt(runRateMonth)));
            setRateEndMonth(formatDate2(end))
        }
    },[runRateMonth,rateStartMonth]);


    const calculater = () => {
        if (((`${(parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52)))}`) === 'NaN') || ((parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52))) === 0)) {
            setButtonColor('green')
            setBalance('zero')
        }
        else if ((parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52))) > 0) {
            setBalance('positive')
            setButtonColor('green')
        }
        else {
            setBalance('negative')
            setButtonColor('red')
        }
        setOpenModel(true)
    }

    useEffect(() => {
        setNewBalance((parseFloat(Q26)-(parseFloat(Q27)+parseFloat(Q34)+parseFloat(Q48)+parseFloat(Q52))).toFixed())
    }, [Q52,Q27])

    const finalSubmit = () => {
        if(analyze){
            setShowPopup(true)
        } else {
            history.push("/");
        }
    }


    const reAnalyze = () => {
        if(!!rateStartMonth){
            const data ={
            
                run_rate:true,
                month:runRateMonth,
                starting_month:formatEndDate(rateStartMonth),
                ending_month:formatEndDate(rateEndMonth)
            }
            analyzeCall(data)
        } else{
            notify('Select Starting Month','error')
        }
        // 
    }

    const handleCurrentStatus = () => {
            const data={
              current_status:2 
            }
            updateCurrentStatus(data)
    }

    const saveExp = (val) => {
        console.log(JSON.stringify(val),'yhi')
        adjustmentCall({now_what:JSON.stringify(val)})
    }

    function AnnualizeButton() {
        if (mainData?.analyzing_report?.run_rate) { 
                  return  <button className={(annualized)?styles.quick_inactive_btn:styles.quick_active_btn} onClick={annualize} >{(annualized)?'Annualized':'Annualize'} Results</button>
        } else {
            return ''
        }                  
    }
    
    const annualize = () => {
        if (!annualized) {
            if  (mainData?.analyzing_report?.run_rate && mainData?.analyzing_report?.month ) {
                var months = mainData?.analyzing_report?.month
                setQ20(((Q20/months)*12).toFixed());
                setQ25(((Q25/months)*12).toFixed());
                setQ26(((Q26/months)*12).toFixed());
                setQ27(((Q27/months)*12).toFixed());
                setQ34(((Q34/months)*12).toFixed());
                setQ48(((Q48/months)*12).toFixed());
                setQ52(((Q52/months)*12).toFixed());
                setAnnualized(true)
            }
        }
    }

    const unannualize = (undoType) => {
        if (annualized) {
            if  (mainData?.analyzing_report?.run_rate && mainData?.analyzing_report?.month ) {
                var months = mainData?.analyzing_report?.month
               
                setQ20(((Q20*months)/12).toFixed());
                setQ25(((Q25*months)/12).toFixed());
                setQ26(((Q26*months)/12).toFixed());
                setQ34(((Q34*months)/12).toFixed());
                setQ48(((Q48*months)/12).toFixed());
                if (undoType=='opex'){
                    setQ27(((Q27*months)/12).toFixed()); // unannualize profit and skip opex
                } else if (undoType=='profit'){
                    setQ52(((Q52*months)/12).toFixed()); // unannualize open and skip profit
                }
                setAnnualized(false)
            }
        }
    }

    return (
        <>
            <div className={styles.quick_assessment_main}>
             <div className={styles.page_content}>
               <div className='worksheet_form_header'>
                <div className={styles.flex_image}>
                    <h1>Final Assessment </h1> 
                    <a href="https://pat-api.profitfirsttools.com/static/video/final-assessments.mp4" target="_blank"><img alt='' className="video-icon" src={video_player} /> <i>Watch Tutorial</i></a>
                 </div>   
                    <h3 className={`worksheet_form_header_h3`} >{mainData?.analyzing_report?.run_rate ? `${momentConvertFormat(mainData?.analyzing_report?.starting_month,'YYYY-MM-DD','MM/DD/YYYY')} - ${momentConvertFormat(mainData?.analyzing_report?.ending_month,'YYYY-MM-DD','MM/DD/YYYY')}` :  mainData?.analyzing_report?.analysing_year}</h3>                  
        
                </div>
                <div className={styles.quick_table_container}>
                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                            <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Real Revenue</span>
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q26 === 'NaN' ? `${currency}0` : Q26} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>

                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                            <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Profit</span>
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q27 === 'NaN' ? `${currency}0` : Q27} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>

                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                             <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Owner's Comp</span>
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q34 === 'NaN' ? `${currency}0` : Q34} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>

                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                          <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Tax</span>
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q48 === 'NaN' ? `${currency}0` : Q48} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>

                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                            <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Operating Expenses</span>
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q52 === 'NaN' ? `${currency}0` : Q52} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>
                    
                    <div className={styles.quick_assessment_row}>
                        <div className={styles.quick_left_side}>
                            {/* <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Operating Expenses</span> */}
                        </div>
                        <div className={styles.quick_right_side}>
                            <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right, styles.color_black,{ [styles.quick_btn_red]: newBalance < 0 }, { [styles.quick_btn_green]: newBalance > 0 })} value={newBalance === 'NaN' ? `${currency}0` : newBalance} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>} />
                        </div>
                    </div>
                </div>
                <div className={styles.quick_footer1}>
                    {/* <button className={styles.quick_active_btn} onClick={calculater}>Calculate</button> */}
                 
                    {/* <button className={(annualized)?styles.quick_inactive_btn:styles.quick_active_btn} onClick={annualize} >{(annualized)?'Annualized':'Annualize'} Results</button> */}
                    <button onClick={()=>{setOpenModel(true);handleCurrentStatus();}} className={cx(styles.quick_inactive_btn, { [styles.quick_btn_red]: newBalance < 0 }, { [styles.quick_btn_green]: newBalance >= 0 })}>Run Report</button>
                    <AnnualizeButton/>
                </div>
                <div className={styles.quick_footer2}>
                    <div>
                        <button onClick={()=>{
                             setQ52((parseFloat(Q52)+parseFloat(newBalance)).toFixed()); 
                                if (annualized) {
                                    setAnnualizedBeforeAdjustment(true);
                                    adjustmentCall({operating_expenses_adjustment:parseFloat(newBalance),operating_expenses:(parseFloat(Q52*mainData?.analyzing_report?.month/12)+parseFloat(newBalance*mainData?.analyzing_report?.month/12)).toFixed()})  
                                } else {
                                    setAnnualizedBeforeAdjustment(false);
                                    adjustmentCall({operating_expenses_adjustment:parseFloat(newBalance),operating_expenses:parseFloat(Q52)+parseFloat(newBalance)})
                                }
                           
                            }} className={cx(styles.quick_inactive_btn, { [styles.activate_quick_btn]: newBalance != 0 })}>Apply difference to operating expenses</button>
                        
                        {mainData?.worksheet?.operating_expenses_adjustment>0 || mainData?.worksheet?.operating_expenses_adjustment<0?
                            <button onClick={()=>{
                                if (annualized) {
                                    if (annualizedBeforeAdjustment){
                                        setQ52((parseFloat(Q52*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment*mainData?.analyzing_report?.month/12)).toFixed()); 
                                        adjustmentCall({operating_expenses_adjustment:0,operating_expenses:(parseFloat(Q52*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment*mainData?.analyzing_report?.month/12)).toFixed()})
                                    } else {
                                        setQ52((parseFloat(Q52*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment)).toFixed()); 
                                        adjustmentCall({operating_expenses_adjustment:0,operating_expenses:(parseFloat(Q52*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment)).toFixed()})
                                    }
                                    unannualize('opex');
                                } else {
                                    setQ52((parseFloat(Q52)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment)).toFixed()); 
                                    adjustmentCall({operating_expenses_adjustment:0,operating_expenses:(parseFloat(Q52)-parseFloat(mainData?.worksheet?.operating_expenses_adjustment)).toFixed()})  
                                }
                            }} className="undo_btn">Undo</button>
                        :null}
                    </div>
                    <div>
                        <button onClick={()=>{
                            setQ27((parseFloat(Q27)+parseFloat(newBalance)).toFixed()); 
                                if (annualized) {
                                    setAnnualizedBeforeAdjustment(true);
                                    adjustmentCall({profit_adjustment:parseFloat(newBalance),profit:(parseFloat(Q27*mainData?.analyzing_report?.month/12)+parseFloat(newBalance*mainData?.analyzing_report?.month/12)).toFixed() })
                                } else {
                                    setAnnualizedBeforeAdjustment(false);  
                                    adjustmentCall({profit_adjustment:parseFloat(newBalance),profit:parseFloat(Q27)+parseFloat(newBalance) })
                                }
                         
                            }} className={cx(styles.quick_inactive_btn, { [styles.activate_quick_btn]: newBalance != 0 })}>Apply difference to profit</button>
                        {mainData?.worksheet?.profit_adjustment>0 || mainData?.worksheet?.profit_adjustment<0?
                            <button onClick={()=>{ 
                                if (annualized){
                                    if (annualizedBeforeAdjustment){
                                        setQ27((parseFloat(Q27*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.profit_adjustment*mainData?.analyzing_report?.month/12)).toFixed()); 
                                        adjustmentCall({profit_adjustment:0,profit:(parseFloat(Q27*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.profit_adjustment*mainData?.analyzing_report?.month/12)).toFixed()})
                                    } else {
                                        setQ27((parseFloat(Q27*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.profit_adjustment)).toFixed());  
                                        adjustmentCall({profit_adjustment:0,profit:(parseFloat(Q27*mainData?.analyzing_report?.month/12)-parseFloat(mainData?.worksheet?.profit_adjustment)).toFixed()})
                                    }
                                    unannualize('profit');
                                } else {
                                    setQ27((parseFloat(Q27)-parseFloat(mainData?.worksheet?.profit_adjustment)).toFixed()); 
                                    adjustmentCall({profit_adjustment:0,profit:(parseFloat(Q27)-parseFloat(mainData?.worksheet?.profit_adjustment)).toFixed()})
                                }
                            }} className="undo_btn">Undo</button>
                           
                        : null}
                    </div>
                </div>
                <hr/>
                <div className="worksheet-exit-button">
                    {/* !!mainData?.worksheet?.linked_id ? null: 
                        <div className='worksheet_radio_container'>
                            <label>Would you like to analyze another year?</label>
                            <div>
                                <div><input onChange={(e) => setAnalyze(true)} className='worksheet_radio' checked={analyze} type="radio" name="another_year" value='yes' required /><span>Yes</span></div>
                                <div><input onChange={(e) => setAnalyze(false)} style={{ marginLeft: "40px" }} checked={!analyze} className='worksheet_radio' type="radio" name="another_year" value='no' required /><span>No</span></div>
                            </div>
                        </div> */ }
                    <div>
                        <button className='worksheet_previous_btn mr-3' onClick={backCall} >Previous</button>
                        <button className='worksheet_submit_btn' onClick={finalSubmit} type='submit'>Proceed</button>
                    </div>
                </div>
                <hr/>
                {/* <div className={styles.quick_footer1} style={{justifyContent:'center'}}>
                    <button type="button" className={styles.quick_active_btn} >SKIP TO WORKSHEETS</button>
                </div> */}
            </div>
            </div>
            {
                openModel?
                <AssessmentModal
                    modalIsOpen={openModel}
                    user={user}
                    logo={logo}
                    BaseURL={BaseURL}
                    saveOtherExp={(val)=>saveExp(val)}
                    saveEditExp={(val)=>adjustmentCall({edited_quater:JSON.stringify(val)})}
                    data={
                        {
                            dCurrency:currency,
                            cCode,
                            topLineRevenue:Q20,
                            materialSubcontractors:Q25,
                            realRevenue:Q26,
                            profit:Q27,
                            ownerComp:Q34,
                            tax:Q48,
                            operatingExpenses: Q52,
                            id:mainData.id,
                            exp:!!mainData?.worksheet?.now_what ? JSON.parse(mainData?.worksheet?.now_what) : [] ,
                            edited_quater:!!mainData?.worksheet?.edited_quater ? JSON.parse(mainData?.worksheet?.edited_quater) : null ,
                        }
                    }
                    closeModal={() => setOpenModel(false)}
                    assessType={assessType}
                />
                : null
            }
                <Modal
                    isOpen={ShowPopup}
                    //   onAfterOpen={afterOpenModal}
                    onRequestClose={()=>setShowPopup(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                <div className='worksheet_popup_data custom_modal_design'>
                    <div className="worksheet_popup_width">
                        <div className={modalStyles.analyzing_report_row}>
                            <div className={modalStyles.analyzing_report_input_container}>
                                <label>How many months are you using for the current year's data?</label>
                                <select value={runRateMonth} onChange={(e)=>setRunRateMonth(e.target.value)} className={modalStyles.analyzing_report_select}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                </select>
                            </div>
                        </div>
                        <div className={modalStyles.analyzing_report_row}>
                            <div className="custom_date_picker">
                                <label>Starting Month</label>
                                <DatePicker    
                                    className="custom-picker"     
                                    onChange={(e)=>setRateStartMonth(e)}
                                    value={rateStartMonth} 
                                />
                            </div>
                        </div>
                        <div className={modalStyles.analyzing_report_row}>
                            <div className="custom_date_picker">
                                <label>Ending Month</label>
                                <DatePicker    
                                    className="custom-picker"
                                    disabled
                                    value={rateEndMonth} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='popup_btn_container custom_modal_cta'>
                    <button className='worksheet_popup_btn popup_btn_left' onClick={() => { setShowPopup(false) }}>Cancel</button>
                    <button className='worksheet_popup_btn popup_btn_right' onClick={reAnalyze}>Continue</button>
                </div>
            </Modal>
        </>
    )
}

export default Worksheet6
