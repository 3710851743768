import {ACTIVE_THEME, UPDATE_COMPANY, UPDATE_LOGO} from '../constant/settings'

const initial = {
    theme:'peach-theme',
    company: '',
    logo:'',
}


export const settingReducers = function (state = initial, action) {
    switch (action.type) {
      case ACTIVE_THEME:
          return {
                ...state,
                theme: action.payload,
          }
      case UPDATE_COMPANY:
        return {
              ...state,
              company: action.payload,
        }
      case UPDATE_LOGO:
        return {
              ...state,
              logo: action.payload,
        }
      default:
        return state;
    }
};