import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import SidePanel from './components/SidePanel'
import Settings from "./components/Settings";
import ForgotPassword from "./components/ForgotPassword";
import QuickAssessment from "./components/QuickAssessment";
import FullAssessment from "./components/FullAssessment";

function App() {
  const [Auth, setAuth] = useState(false)
  const BaseURL = "https://pat-api.profitfirsttools.com"
  // const BaseURL = "http://127.0.0.1:8004"
  // const BaseURL = "http://ec2-3-17-229-30.us-east-2.compute.amazonaws.com"
   

  
  useEffect(() => {
    if (localStorage.getItem('userAuth') === 'true') {
      setAuth(true)
    }
    else {
      setAuth(false)
    }
  }, [])

  const loginDone= () =>{
    setAuth(true)
  }


  // Not authenticated routes
  
  if(!Auth){
    return (  
      <div className="App">
        <Router>
          <Switch>
            <Route path="/register"><Register BaseURL={BaseURL} /></Route>
            <Route path="/forgotpassword"><ForgotPassword BaseURL={BaseURL} /></Route>
            <Route path="/" exact> <Login BaseURL={BaseURL} loginSuccess={loginDone} userLogOut={()=>{setAuth(false);localStorage.clear()}}/></Route>
            <Route path="*"><div className="not-found-page"><h1>404</h1></div></Route>
          </Switch>
          <ToastContainer component="ToastContainer"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}/>
        </Router>
      </div>
      )
  }

  // Authenticated routes
  return (
    <div className="App">
      <Router>
        <div className="d-md-flex">
        <SidePanel BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/>
        <Switch>
          <Route path="/" exact> <Dashboard BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/></Route>
          <Route path="/settings" exact><Settings BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/> </Route>
          <Route path="/quick_assessment" exact><QuickAssessment  BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/></Route>
          <Route path="/quick_assessment/:assessment_id" exact><QuickAssessment  BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/></Route>
          <Route path="/full-assessment" exact><FullAssessment BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/> </Route>
          <Route path="/full-assessment/:assessment_id"><FullAssessment BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/> </Route>
          {/* <Route path="/full-assessment/:assessment_id" render={(props) => ( <FullAssessment BaseURL={BaseURL} userLogOut={()=>{setAuth(false);localStorage.clear()}}/> )} /> */}
          {/* <Route path="/analyzing_report" exact><AnalyzingReport BaseURL={BaseURL} /> </Route> */}
          {/* <Route path="/worksheet1" exact><Worksheet1 Q26={Q26} setQ26={setQ26} WorksheetYear={WorksheetYear} /> </Route>
          <Route path="/worksheet2" exact><Worksheet2 Q27={Q27} setQ27={setQ27} WorksheetYear={WorksheetYear} /> </Route>
          <Route path="/worksheet3" exact><Worksheet3 Q34={Q34} setQ34={setQ34} WorksheetYear={WorksheetYear} /> </Route>
          <Route path="/worksheet4" exact><Worksheet4 Q48={Q48} setQ48={setQ48} WorksheetYear={WorksheetYear} /> </Route>
          <Route path="/worksheet5" exact><Worksheet5 Q26={Q26} Q27={Q27} Q34={Q34} Q48={Q48} setQ52={setQ52} Q52={Q52} WorksheetYear={WorksheetYear} setWorksheetYear={setWorksheetYear} /> </Route> */}
        </Switch>
        </div>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
