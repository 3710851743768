import React, { useState, useEffect } from 'react'
import SidePanel from './SidePanel'
import { useDispatch } from "react-redux";
import info from '../images/info.svg'
import upload from '../images/upload.svg'
import Close from '../images/Close.png'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import styles from '../components_css/Settings.module.css'
import cx from 'classnames'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {notify} from '../utils/globalFunction'
import {updateTheme, updateCompany, updateLogo} from '../redux/actions/settings'
import video_player from '../images/video-player.svg'
// import { useHistory } from 'react-router-dom'

function Settings({ BaseURL,userLogOut }) {
    const [ActiveTab,] = useState('Settings')
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [Name, setName] = useState('')
    const [Company, setCompany] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Email, setEmail] = useState('')
    const [theme, setTheme] = useState('blue-theme')
    const [InvalidName, setInvalidName] = useState(false)
    const [profile, setProfile] = useState({})
    // let history = useHistory()


    // const [ImageUrl, setImageUrl] = useState(null)


    const fileChangeHandler = (files) => {
        setSendingLogo(files[0])
        uploadImage(files)
    }


    const uploadHandler = () => {
        const formData = new FormData()
        if (SendingLogo){
            formData.append(
            'logo',
            SendingLogo,
            SendingLogo.name
            )
        } else {
            formData.append(
            'logo',
            new Blob([]),
            ''
            )
        }
        axios.post(`${BaseURL}/api/v1/user/update-settings/`, formData, { headers: { "Authorization": `JWT ${token}` } })
            .then(response => { 
                console.log(response); 
                dispatch(updateLogo(Logo))
                alert("Logo has been Updated!"); 
                // location.reload(); 
                setShowPopup(false); 
                // history.push("/settings");
            })
            .catch(err => console.log(err))
        
         
    }

    const [InvalidEmail, setInvalidEmail] = useState(false)

    const [Logo, setLogo] = useState(null)
    const [SendingLogo, setSendingLogo] = useState(null)

    const [Allvalid, setAllvalid] = useState(true)

    const [ShowPopup, setShowPopup] = useState(false)

    const [Uploaded, setUploaded] = useState(false)

    let token = localStorage.getItem('userAccessToken')

    const settingsData = new FormData()
    const settingsLogo = new FormData()

    const dispatch = useDispatch();

    settingsData.append("company", `${Company}`)
    settingsData.append("first_name", `${fName}`)
    settingsData.append("phone", `+${Mobile}`)
    settingsData.append("last_name", `${lName}`)
    settingsData.append("template", theme)
    settingsLogo.append("logo", `${Logo}`)
    settingsData.append("logo", `${Logo}`)

    const uploadImage = async (acceptedFiles) => {
        // setLogo(acceptedFiles[0])
        const base64 = await convertBase64(acceptedFiles[0])
        setLogo(base64)
        // settingsData.append('logo', `${Logo}`)
    }

    const convertBase64 = (acceptedFiles) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(acceptedFiles)

            fileReader.onload = () => {
                resolve(fileReader.result)
            }

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }


    useEffect(() => {
        if (!(/^[A-Za-z]+$/.test(Name)) && !(Name === '')) {
            setInvalidName(true)
        }
        else {
            setInvalidName(false)
        }

    }, [Name])


    // useEffect(() => {
    //     if ((!(RegExp(/^\d{10}$/).test(Mobile)) && !(Mobile === ''))) {
    //         setInvalidMobile(true)
    //     }
    //     else {
    //         setInvalidMobile(false)
    //     }

    // }, [Mobile])

    useEffect(() => {
        if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(Email)) && !(Email === '')) {
            setInvalidEmail(true)
        }
        else {
            setInvalidEmail(false)
        }

    }, [Email])

    useEffect(() => {
        if ((InvalidName || InvalidEmail)) {
            setAllvalid(false)
        }
        else {
            setAllvalid(true)
        }

    }, [InvalidEmail, InvalidName])


    useEffect(() => {
        if (Logo === null) {
            setUploaded(false)
        }
        else {
            setUploaded(true)
        }
    }, [Logo])

    useEffect(() => {
        axios.get(`${BaseURL}/api/v1/user/retrieve-settings/`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                // setEmail((res.data.data.email === (undefined || null)) ? '' : res.data.data.email)
                // setCompany(res.data.data.company)
                // setMobile(res.data.data.phone)
                // setName((res.data.data.name === (undefined || null)) ? '' : res.data.data.name)
                // console.log(res.data.data)
                // setImageUrl(res.data.data.logo)
                setLogo(`${BaseURL}${res.data.data.logo}`)
            }).catch(err =>  {
                if(err.response.status===401){
                    userLogOut()
                }
            })
    }, [BaseURL, token])

    useEffect(() => {
        axios.get(`${BaseURL}/api/v1/user/profile/`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
           console.log(res,'profile')
           setProfile(res.data)
           setEmail(res?.data?.data?.email)
           setCompany(res?.data?.data?.company)
           setMobile(res?.data?.data?.phone)
           setFName(res?.data?.data?.first_name)
           setLName(res?.data?.data?.last_name)
           setTheme(res?.data?.data?.template)
        }).catch(err =>  {
            if(err.response.status===401){
                userLogOut()
            }
        })
    },[token]);


    const handleSave = (e) => {
        e.preventDefault()
        if (Allvalid) {
            dispatch(updateTheme(theme))
            //dispatch(updateLogo(Logo))
            axios.put(`${BaseURL}/api/v1/user/profile/update/${profile.data.id}/`, settingsData, { headers: { "Authorization": `JWT ${token}` } })
                .then(response => {
                    dispatch(updateCompany(Company))
                    notify('Updated Successfully','success')
                })
                .catch(err => {
                    if(err.response.status===401){
                        userLogOut()
                    }
                    const error = err.response.data;
                    const firstError= error[Object.keys(error)[0]];
                    notify(firstError[0],'error')
                })
        }
        else {
            console.log('someth wro')
        }
    }

    return (
        <>
            <div className={styles.settings_main}>
            <div className={styles.page_content}>
                <div className={styles.flex_image}>
                    <h1>Your Settings</h1>
                    <a href="http://pat-api.profitfirsttools.com/static/video/setting.mp4" target="_blank"><img className="video-icon" alt='' src={video_player} /> <i>Watch Tutorial</i></a>
                </div>
                <div className={styles.settings_container}>
                    <form className={styles.settings_form} onSubmit={(e) => { e.preventDefault(); if (Allvalid) { handleSave() } }} >
                        <div className={styles.settings_form_div}>
                            <label>First Name</label>
                            <span className={cx(styles.settings_error_msg, { [styles.settings_display_error]: InvalidName })}>Name can be alphabetic only</span>
                            <input className={cx(styles.settings_inputs, { [styles.settings_error]: InvalidName })} type="text" placeholder="Name" name="Name" value={fName} onChange={(e) => setFName(e.target.value)} required />
                            <img alt='' src={info} className={cx(styles.settings_error_icon, { [styles.settings_display_error]: InvalidName })}></img>
                        </div>
                        <div className={styles.settings_form_div}>
                            <label>Last Name</label>
                            <span className={cx(styles.settings_error_msg, { [styles.settings_display_error]: InvalidName })}>Name can be alphabetic only</span>
                            <input className={cx(styles.settings_inputs, { [styles.settings_error]: InvalidName })} type="text" placeholder="Name" name="Name" value={lName} onChange={(e) => setLName(e.target.value)} required />
                            <img alt='' src={info} className={cx(styles.settings_error_icon, { [styles.settings_display_error]: InvalidName })}></img>
                        </div>
                        <div className={styles.settings_form_div}>
                            <label>Company</label>
                            <input className={styles.settings_inputs} type="text" placeholder="Company" name="Company" value={Company} onChange={(e) => setCompany(e.target.value)} required />
                        </div>
                        {/* <div className={styles.settings_form_div}>
                            <label>Mobile</label>
                            <span className={cx(styles.settings_error_msg)}>Enter 10 digit number</span>
                            <PhoneInput disabled={true} containerClass={styles.settings_inputs} containerStyle={{ position: 'relative' }} inputStyle={{ backgroundColor: "inherit", border: 'none', position: 'relative', top: '4px' }} buttonStyle={{ border: 'none', backgroundColor: 'inherit' }} value={Mobile} onChange={setMobile} />
                            <img alt='' src={info} className={cx(styles.settings_error_icon)}></img>
                        </div>
                        <div className={styles.settings_form_div}>
                            <label>Email</label>
                            <input disabled={true} className={cx(styles.settings_inputs)} type="text" placeholder="Email" name="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                        </div> */}
                        <div className={styles.settings_form_div}>
                            <label>Theme Color</label>
                            <div className="themebg">
                                <div className="child_theme">
                                    <label for="bluecolor">
                                        <input type="radio" checked={theme==='blue-theme'} onChange={()=>setTheme('blue-theme')} value="blue-theme" name="tc" id="bluecolor"/>
                                        <div className="grdients-color"></div>
                                    </label>
                                </div>
                                <div className="child_theme">
                                    <label for="peach">
                                        <input type="radio" checked={theme==='peach-theme'}  onChange={()=>setTheme('peach-theme')} value="peach-theme" name="tc" id="peach"/>
                                        <div className="grdients-color c2"></div>
                                    </label>
                                </div>
                                <div className="child_theme">
                                    <label for="greens">
                                        <input type="radio" checked={theme==='green-theme'} onChange={()=>setTheme('green-theme')} value="green-theme"  name="tc" id="greens"/>
                                        <div className="grdients-color c3"></div>
                                    </label>
                                </div>
                                <div className="child_theme">
                                    <label for="grays">
                                        <input type="radio" checked={theme==='gray-theme'} onChange={()=>setTheme('gray-theme')} value="gray-theme" name="tc" id="grays"/>
                                        <div className="grdients-color c4"></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={styles.action_button}>
                            <button type="button" className={styles.settings_upload_btn} onClick={setShowPopup}>Upload Logo</button>
                            <button type="button" className={styles.settings_submit_btn}  onClick={handleSave}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={cx(styles.settings_fade, { [styles.settings_popup_open]: ShowPopup })}></div>
            <div className={cx(styles.settings_popup, { [styles.settings_popup_open]: ShowPopup })}>
                <Dropzone onDrop={fileChangeHandler} accept="image/*" >
                    {({ getRootProps, getInputProps }) => (
                        <section className={styles.drag_and_drop}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img alt='' className={styles.drag_and_drop_img} src={upload}></img>
                                {/* <img src={Logo} alt='' className={cx(styles.uploaded_logo, { [styles.image_uploaded]: Uploaded })}></img> */}
                                <img src={Logo} alt='' className={cx(styles.uploaded_logo, { [styles.image_uploaded_new]: Uploaded })}></img>
                               
                                <h5>JPG or PNG</h5>
                                <p>Drag and drop your image here or</p>
                                <button className={styles.settings_upload_btn}>Upload Image</button>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className={styles.popup_btn_container}>
                    <button className={cx(styles.settings_submit_btn, styles.popup_btn_left)} onClick={() => { setShowPopup(false) }}>Back</button>
                    <button className={cx(styles.settings_submit_btn, styles.popup_btn_right)} onClick={uploadHandler}>Save and continue</button>
                </div>
                <img className={cx(styles.cross_btn, { [styles.image_uploaded]: Uploaded })} src={Close} alt='' onClick={() => { setLogo(null); }}></img>
            </div>
            </div>
        </>
    )
}

export default Settings
