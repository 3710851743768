export const CURRENCY = [
    {
        id:1,
        symbol:'€',
        name:'Euro',
        code:'EUR',
    },
    {
        id:2,
        symbol:'£',
        name:'British Pound',
        code:'GBP'
    },
    {
        id:3,
        symbol:'$',
        name:'American Dollar',
        code:'USD'
    },
    {
        id:4,
        symbol:'M$',
        name:'Mexican Peso',
        code:'MXN'
    },
    {
        id:5,
        symbol:'C$',
        name:'Canadian Dollar',
        code:'CAD'
    },
    {
        id:6,
        symbol:'A$',
        name:'Australian Dollar',
        code:'AUD'
    }

]