import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import Layer_1 from '../images/layer1.svg'
import video_player from '../images/video-player.svg'
import padlock from '../images/padlock.svg'
import user from '../images/user.svg'
import company from '../images/company.svg'
import phone from '../images/phone.svg'
import email from '../images/email.svg'
import info from '../images/info.svg'
import {notify} from '../utils/globalFunction'
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function Register({ BaseURL }) {
    const history = useHistory()

    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [Username, setUsername] = useState('')
    const [Company, setCompany] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [InvalidUsername, setInvalidUsername] = useState(false)
    // const [InvalidMobile, setInvalidMobile] = useState(false)
    const [InvalidEmail, setInvalidEmail] = useState(false)
    const [InvalidPassword, setInvalidPassword] = useState(false)
    const [NotMatched, setNotMatched] = useState(false)
    const [Allvalid, setAllvalid] = useState(true)

    const backHome = () => { history.push('/') }

    const register_data = {
        "first_name": `${fName}`,
        "last_name": `${lName}`,
        "company": `${Company}`,
        "username": `${Username}`,
        "email": `${Email}`,
        "password": `${Password}`,
        "confirm_password": `${ConfirmPassword}`,
        "phone": `+${Mobile}`
    }


    useEffect(() => {
        if (/[^a-z\d]/i.test(Username)) {
            setInvalidUsername(true)
        }
        else {
            setInvalidUsername(false)
        }

    }, [Username])


    // useEffect(() => {
    //     if ((!(RegExp(/^\d{10}$/).test(Mobile)) && !(Mobile === ''))) {
    //         setInvalidMobile(true)
    //     }
    //     else {
    //         setInvalidMobile(false)
    //     }

    // }, [Mobile])

    useEffect(() => {
        if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(Email)) && !(Email === '')) {
            setInvalidEmail(true)
        }
        else {
            setInvalidEmail(false)
        }

    }, [Email])

    useEffect(() => {
        if (Password.length < 8 && !(Password === '')) {
            setInvalidPassword(true)
        }
        else {
            setInvalidPassword(false)
        }

    }, [Password])

    useEffect(() => {
        if (!(Password === ConfirmPassword) && !(ConfirmPassword === '')) {
            setNotMatched(true)
        }
        else {
            setNotMatched(false)
        }

    }, [ConfirmPassword, Password])

    useEffect(() => {
        if ((InvalidUsername || InvalidEmail || NotMatched)) {
            setAllvalid(false)
        }
        else {
            setAllvalid(true)
        }

    }, [InvalidEmail, InvalidUsername, NotMatched])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (Allvalid) {
            axios.post(`${BaseURL}/api/v1/user/register/`, register_data)
                .then(response => { 
                    notify('User Successfully Registered!','info') 
                    history.push('/')
                })
                .catch(err => {
                    const error = err.response.data;
                    const firstError= error[Object.keys(error)[0]];
                    notify(firstError[0],'error')
                })
        }
        else {
            notify('someth wrong','error')
        }
    }



    return (
        <div className='register'>
            <div className='left'>
                <img alt='' onClick={backHome} src={Layer_1} className='login_logo'></img>
                <div className='upper_circle'>
                </div>
                <div className="welcome-text">
                <h2>
                    Welcome to Your
                </h2>
                <h2>
                    Profit Assessment Tool.
                </h2>
                </div>
                <div className='lower_circle'>
                </div>
            </div>
            <div id='register_right'>
            <div className="register-link">
                <p>Already have an account? <Link to='/'>Login</Link></p>
            </div>    
            <div className="register-box">
                <div id='register_heading'>
                    <h4>Register</h4>
                    <img alt='' src={video_player}></img>
                </div>
                <div className='login_container'>
                    <form className='register_form' onSubmit={handleSubmit}>
                                                
                        <label>First Name<sup className="text-danger">*</sup></label>
                        <span className={`error_msg`}>First Name is required</span>
                        <input className={`inputs`} type="text" placeholder="First Name" name="fName" value={fName} onChange={(e) => setFName(e.target.value)} required />
                        <img alt='' src={user} className='input_icons'></img>
                        <label>Last Name<sup className="text-danger">*</sup></label>
                        <span className={`error_msg`}>Last Name is required</span>
                        <input className={`inputs`} type="text" placeholder="Last Name" name="lName" value={lName} onChange={(e) => setLName(e.target.value)} required />
                        <img alt='' src={user} className='input_icons'></img>
                        <label>Username<sup className="text-danger">*</sup></label>
                        <span className={`error_msg ${InvalidUsername && `display_error`}`}>Username can be alphanumeric only</span>
                        <input className={`inputs ${InvalidUsername && `error`}`} type="text" placeholder="Username" name="Username" value={Username} onChange={(e) => setUsername(e.target.value)} required />
                        <img alt='' src={user} className='input_icons'></img>
                        <label>Company<sup className="text-danger">*</sup></label>
                        <input className='inputs' type="text" placeholder="Company" name="Company" value={Company} onChange={(e) => setCompany(e.target.value)} />
                        <img alt='' src={company} className='input_icons'></img>
                        <label>Mobile<sup className="text-danger">*</sup></label>
                        <span className={`error_msg`}>Enter 10 digit number</span>
                        {/* <input className={`inputs ${InvalidMobile && `error`}`} type="tel" placeholder="Mobile" name="Mobile" value={Mobile} onChange={(e) => setMobile(e.target.value)} required /> */}
                        <PhoneInput containerClass='inputs' country={'us'} containerStyle={{ position: 'relative' }} inputStyle={{ backgroundColor: "#fff", border: 'none', position: 'relative', left: '-45px' }} buttonStyle={{ border: 'none', backgroundColor: "#fff", zIndex: '99', left: '20px', width: '50px' }} placeholder='Mobile' value={Mobile} onChange={setMobile} />
                        <img alt='' src={phone} className='input_icons'></img>
                        <label>Email<sup className="text-danger">*</sup></label>
                        <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span>
                        <input className={`inputs ${InvalidEmail && `error`}`} type="text" placeholder="Email" name="Email" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                        <img alt='' src={email} className='input_icons'></img>
                        <label>Password<sup className="text-danger">*</sup></label>
                        <span className={`error_msg ${InvalidPassword && `display_error`}`}>Password must be atleast 8 digits</span>
                        <input className={`inputs ${InvalidPassword && `error`}`} type="password" placeholder="Password" name="password" value={Password} onChange={(e) => setPassword(e.target.value)} required />
                        <img alt='' src={padlock} className='input_icons pass'></img>
                        <label>Confirm Password<sup className="text-danger">*</sup></label>
                        <span className={`error_msg ${NotMatched && `display_error`}`}>Password not matched</span>
                        <input className={`inputs ${NotMatched && `error`}`} type="password" placeholder="Confirm Password" name="ConfirmPassword" value={ConfirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        <img alt='' src={padlock} className='input_icons pass'></img>
                        <button id='register_submit_btn' type="submit">REGISTER</button>
                        
                    </form>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Register
