import {ACTIVE_THEME, UPDATE_COMPANY, UPDATE_LOGO} from '../constant/settings'

export const updateTheme = (userObj) => {
    return {
       type: ACTIVE_THEME,
       payload: userObj
     }
 }
 
export const updateCompany = (userObj) => {
  return {
     type: UPDATE_COMPANY,
     payload: userObj
   }
}


export const updateLogo = (userObj) => {
  return {
     type: UPDATE_LOGO,
     payload: userObj
   }
}