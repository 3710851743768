import React, { useState,useEffect } from 'react'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { AppBar,Typography ,Box ,Tabs,Tab } from '@material-ui/core';
// import SwipeableViews from 'react-swipeable-views'; 
import PropTypes from 'prop-types';
// import SidePanel from './SidePanel'
import add from '../images/add.svg'
import Group from '../images/Group.svg'
import notes from '../images/note.png'
import welcomeimage from '../images/welcome-image.svg'
import quick_ass_icon from '../images/q-ass.svg'
import full_ass_icon from '../images/f-ass.svg'
import video_player from '../images/video-player.svg'
import styles from '../components_css/Dashboard.module.css'
import cx from 'classnames'
import axios from 'axios'
import { Link } from "react-router-dom";
import { useTable, usePagination, useSortBy } from 'react-table'
import {formatDateDisplay} from '../utils/globalFunction'

///////
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
///////





function Dashboard({BaseURL,userLogOut}) {
    const [ActiveTab,] = useState('Dashboard')
    const [Drop, setDrop] = useState(false)
    const [profile,setProfile]= useState({})
    const [tableData,setData]= useState([])
    const [quicktableData,setquickData]= useState([])

    // ///////////////
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
/////////////

    const deleteAssessments = (val) => {

        axios.delete(`${BaseURL}/api/v1/assessments/delete/${val}/`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                const data = tableData
                const newData = data.filter(item=>parseInt(item.id)!==parseInt(val))
                setData(newData)
            })
    } 

    const deleteQuickAssessments = (val) => {

        axios.delete(`${BaseURL}/api/v1/assessments/delete-quick-assessment/${val}/`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                const data = quicktableData
                const newData = data.filter(item=>parseInt(item.id)!==parseInt(val))
                setquickData(newData)
            })
    } 

    ////////////
    const quickcolumns = [
        {
          Header: 'Project Name',
          accessor: 'edit', // accessor is the "key" in the data
          Cell:({cell}) => (
              <span className={styles.table_row_notes}>
                  <Link to={'quick_assessment/'+ cell.value.id}>{cell.value.project_name}</Link>
              </span>
          )
        },
        {
          Header: 'Date',
          accessor: 'date',
        },
        // {
        //   Header: 'Company',
        //   accessor: 'company',
        // },
        // {
        //   Header: 'Status',
        //   accessor: 'status',
        //   Cell:({cell}) => (
        //       <span className={styles.table_row_notes}>
        //           {cell.value===2?'Completed':'In Progress'}
        //       </span>
        //   )
        // },
        // {
        //   Header: 'Notes',
        //   accessor: 'internal_notes',
        //   Cell:({cell}) => (
        //       <span className={styles.table_row_notes}>
        //           <div className={styles.table_row_note}>{cell.value}</div>
        //           <img alt='' src={notes}></img>
        //       </span>
        //   )
        // },
        {
          Header: 'Action',
          accessor: 'delete',            
          Cell:({cell}) => (
              <span className={styles.table_row_notes}>
                  <button onClick={() => {
                const confirmBox = window.confirm(
                    "Do you really want to DELETE "+cell.value.project_name+"?"
                  );
                  if (confirmBox === true) {
                    deleteQuickAssessments(cell.value.id);
                  }
                }} type="button" className="dash-delete">Delete</button>
              </span>
          )
        },
      //   {
      //     Header: 'Status',
      //     accessor: 'status',
      //     Cell: ({ cell }) => (
      //         cell.row.values.status  
      //     )
      //   },
      ]

    ////////////

    const columns = [
          {
            Header: 'Project Name',
            accessor: 'edit', // accessor is the "key" in the data
            Cell:({cell}) => (
                <span className={styles.table_row_notes}>
                    <Link to={'full-assessment/'+ cell.value.id}>{cell.value.project_name}</Link>
                </span>
            )
          },
          {
            Header: 'Date',
            accessor: 'date',
          },
          {
            Header: 'Company',
            accessor: 'company',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell:({cell}) => (
                <span className={styles.table_row_notes}>
                    {cell.value===2?'Completed':'In Progress'}
                </span>
            )
          },
          {
            Header: 'Notes',
            accessor: 'internal_notes',
            Cell:({cell}) => (
                <span className={styles.table_row_notes}>
                    <img className="note_image_icon" alt='' src={notes}></img>
                    <div className={styles.table_row_note}>{cell.value}</div>
                </span>
            )
          },
          {
            Header: 'Action',
            accessor: 'delete',            
            Cell:({cell}) => (
                <span className={styles.table_row_notes}>
                    <button onClick={() => {
                const confirmBox = window.confirm(
                    "Do you really want to DELETE "+cell.value.project_name+"?"
                  );
                  if (confirmBox === true) {
                    deleteAssessments(cell.value.id);
                  }
                }} type="button" className="dash-delete">Delete</button>
                </span>
            )
          },
        //   {
        //     Header: 'Status',
        //     accessor: 'status',
        //     Cell: ({ cell }) => (
        //         cell.row.values.status  
        //     )
        //   },
        ]

    // const data =React.useMemo(() => tableData, [])

    const token = localStorage.getItem('userAccessToken')

    useEffect(() => {
        // console.log('ss');
        axios.get(`${BaseURL}/api/v1/assessments/list/?limit=1000000&offset=0`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                const mainData=res.data.results.map(item=>{
                    // return {status:item.current_status,'id':item.id,'company':item.client_information.company,'project_name':item.client_information.project_name,'date':formatDateDisplay(item.client_information.assessment_date),'internal_notes':item.client_information.internal_notes,'edit':{'id':item.id,'project_name':item.client_information.project_name},}
                    return {status:item.current_status, 'id':item.id,'company':item.client_information.company,'project_name':item.client_information.project_name,'date':formatDateDisplay(item.client_information.assessment_date),'internal_notes':item.client_information.internal_notes,'edit':{'id':item.id,'project_name':item.client_information.project_name},'delete': {'id':item.id,'project_name':item.client_information.project_name},}
                }) 
                setData(mainData)
            }).catch(err =>  {
                if(err?.response?.status===401){
                    userLogOut()
                }
            })

        axios.get(`${BaseURL}/api/v1/assessments/quick-list/?limit=100000&offset=0`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
            // console.log(res,'dddd')
            const mainData=res.data.map(item=>{
                // return {name:item.project_name,'id':item.id,'date':item.assessment_date}
                // return {'id':item.id,'project_name':item.project_name,'date':formatDateDisplay(item.assessment_date),'edit':{'id':item.id,'project_name':item.project_name}}
                return {'id':item.id,'project_name':item.project_name,'date':formatDateDisplay(item.assessment_date),'edit':{'id':item.id,'project_name':item.project_name} , 'delete':{'id':item.id,'project_name':item.project_name }}
                
            }) 
            setquickData(mainData)
        }).catch(err =>  {
            if(err?.response?.status===401){
                userLogOut()
            }
        })

    }, [token])
    // /////////////

    // useEffect(() => {
    //     console.log('quick');
    //    // axios.get(`${BaseURL}/api/v1/assessments/quick-assessment/?limit=1000&offset=0`, { headers: { "Authorization": `JWT ${token}` } })
    //    axios.get(`http://127.0.0.1:8000/api/v1/assessments/list`, { headers: { "Authorization": `JWT ${token}` } })
         
    //    .then(res => {
    //             const mainData=res.data.results.map(item=>{
    //                 return {
    //                     status:item.current_status,
    //                     'id':item.id,
    //                     // 'company':item.client_information.company,
    //                     'project_name':item.client_information.project_name,
    //                     'date':formatDateDisplay(item.client_information.assessment_date),
    //                     // 'internal_notes':item.client_information.internal_notes,
    //                     'edit':{'id':item.id,'project_name':item.client_information.project_name},}
                       
                    
                        
    //             }) 
    //             setquickData(mainData)
    //         })
    // }, [token])

    /////////////////////
      useEffect(() => {
     
        axios.get(`${BaseURL}/api/v1/user/profile/`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
           setProfile(res.data)
        }).catch(err =>  {
            if(err?.response?.status===401){
                userLogOut()
            }
        })

    },[token]);

    return (
        <>
            <div className={styles.dashboard_main}>
                <div className={styles.page_content}>
                <div className={styles.welcome_sec} >
                        <div className={styles.welcome_row}>
                            <div className={styles.welcome_col_left}> 
                               <h1>
                                   Hello <span>{profile?.data?.first_name} {profile?.data?.last_name}!</span>
                               </h1>
                               <p>
                                    Welcome back to <span>Profit Assessment Tool</span>
                               </p>
                               <p>
                                    You have {tableData.length === 0?'no' : tableData.length} Assessment(s) Please Select a Quick or Full Assessment
                               </p>
                            </div> 
                            <div className={styles.welcome_col_right}> 
                                <img className={styles.welcome_image} alt='' src={welcomeimage}></img>
                            </div>            
                        </div>  
                    </div> 
{/* 
                    <div className={styles.statistics_sec}>
                        <div className={styles.statistics_row}>
                        <div className={styles.statistics_card}>
                                <div className={styles.card_row}>
                                    <div className={styles.card_col_left}>
                                    <p>
                                    Quick Assessment 
                                    </p>
                                    <h4>
                                    05
                                    </h4>
                                    </div>
                                    <div className={styles.card_col_right}>
                                    <img className={styles.ass_icon} alt='' src={quick_ass_icon}></img>
                                    </div>
                                </div>
                             </div>
                             <div className={styles.statistics_card}>
                                <div className={styles.card_row}>
                                    <div className={styles.card_col_left}>
                                    <p>
                                    Full Assessment 
                                    </p>
                                    <h4>
                                    10
                                    </h4>
                                    </div>
                                    <div className={styles.card_col_right}>
                                         <img className={styles.ass_icon} alt='' src={full_ass_icon}></img>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div> */}

                    <div className={styles.flex_image}>
                        <h1 className="page--heading">Your Profit Assessments</h1>
                        <a href="https://pat-api.profitfirsttools.com/static/video/dashboard.mp4" target="_blank"><img className="video-icon" alt='' src={video_player} /> <i>Watch Tutorial</i></a>
                    </div>

                    <div id={styles.new_btn_container} className="add-btn-pos">
                        <button id={styles.new_btn} onClick={() => { setDrop(!Drop) }}>
                            <img alt='' src={add}></img>New
                        </button>
                        <div className={cx(styles.drop_down, { [styles.show_drop_down]: Drop })}>
                            <Link className={styles.drop_down_btn} to='/quick_assessment'>Quick Assessment</Link>
                            <Link className={styles.drop_down_btn} to='/full-assessment'>Full Assessment</Link>
                        </div>
                    </div>
                    {/* ////////////// */}
                    <AppBar position="static">
                    <Tabs TabIndicatorProps={{style: {background:'#12467B'}}} value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Full Assessment" {...a11yProps(0)} />
                    <Tab label="Quick Assessment" {...a11yProps(1)} />
                    </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                    <Table 
                        data={tableData}
                        columns={columns}
                    />
                    </TabPanel>
                    <TabPanel value={value} index={1}>

                    <Table 
                        data={quicktableData}
                        columns={quickcolumns}
                    />
                    </TabPanel>
                   
                    {/* ////////////// */}
                     

                    </div>
                </div>
        </>
    )
}


function Table({columns,data}){
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0 },
        },
        useSortBy,
        usePagination,
      )

        return(
            <>
            
            <div className={styles.dashboard_header}>
                <div className={styles.showing_results}>
                    <h5>Showing results:</h5>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
         
            <div className='table-responsive'>
                <table className="table w-100" {...getTableProps()}>
                    <thead className="w-100">
                        {headerGroups.map(headerGroup => (
                            <tr className={styles.table_head} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className={styles.table_head_name} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' ↓'
                                            : ' ↑'
                                        : ' ↓↑'}
                                    </span>
                                </th>
                            ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="w-100 text-center" {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <tr className={styles.table_row} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <td className={styles.table_head_name}  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}

                            </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

                <div className={styles.dashboard_footer}>
                    <div ></div>
                    <div className={styles.page_btn_container}>
                        <button className="page-iniate" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <ArrowLeftIcon fontSize='inherit' />
                        </button>
                        <div className="pagenum">
                            <span>{pageIndex + 1}</span>
                        </div>
                        <button className="page-iniate" onClick={() => nextPage()} disabled={!canNextPage}>
                            <ArrowRightIcon fontSize='inherit' />
                        </button>
                    </div>
                </div>
            </>
        )

}

export default Dashboard
