export const NAIC = [
'1111 - Agriculture - Oilseed and Grain Farming',
'1112 - Agriculture - Vegetable and Melon Farming',
'1113 - Agriculture - Fruit and Tree Nut Farming',
'1114 - Agriculture - Greenhouse, Nursery, and Floriculture Production',
'1119 - Agriculture - Other Crop Farming',
'1121 - Agriculture - Cattle Ranching and Farming',
'1122 - Agriculture - Hog and Pig Farming',
'1123 - Agriculture - Poultry and Egg Production',
'1124 - Agriculture - Sheep and Goat Farming',
'1125 - Agriculture - Aquaculture',
'1129 - Agriculture - Other Animal Production',
'1131 - Agriculture - Timber Tract Operations',
'1132 - Agriculture - Forest Nurseries and Gathering of Forest Products',
'1133 - Agriculture - Logging',
'1141 - Agriculture - Fishing',
'1142 - Agriculture - Hunting and Trapping',
'1151 - Agriculture - Support Activities for Crop Production',
'1152 - Agriculture - Support Activities for Animal Production',
'1153 - Agriculture - Support Activities for Forestry',
'2111 - Mining - Oil and Gas Extraction',
'2121 - Mining - Coal Mining',
'2122 - Mining - Metal Ore Mining',
'2123 - Mining - Nonmetallic Mineral Mining and Quarrying',
'2131 - Mining - Support Activities for Mining',
'2211 - Utilities - Electric Power Generation, Transmission and Distribution',
'2212 - Utilities - Natural Gas Distribution',
'2213 - Utilities - Water, Sewage and Other Systems',
'2361 - Construction - Residential Building Construction',
'2362 - Construction - Nonresidential Building Construction',
'2371 - Construction - Utility System Construction',
'2372 - Construction - Land Subdivision',
'2373 - Construction - Highway, Street, and Bridge Construction',
'2379 - Construction - Other Heavy and Civil Engineering Construction',
'2381 - Construction - Foundation, Structure, and Building Exterior Contractors',
'2382 - Construction - Building Equipment Contractors',
'2383 - Construction - Building Finishing Contractors',
'2389 - Construction - Other Specialty Trade Contractors',
'3133 - Manufacturing - Manufacturing',
'3112 - Manufacturing - Grain and Oilseed Milling',
'3113 - Manufacturing - Sugar and Confectionery Product Manufacturing',
'3114 - Manufacturing - Fruit and Vegetable Preserving and Specialty Food Manufacturing',
'3115 - Manufacturing - Dairy Product Manufacturing',
'3116 - Manufacturing - Animal Slaughtering and Processing',
'3117 - Manufacturing - Seafood Product Preparation and Packaging',
'3118 - Manufacturing - Bakeries and Tortilla Manufacturing',
'3119 - Manufacturing - Other Food Manufacturing',
'3121 - Manufacturing - Beverage Manufacturing',
'3122 - Manufacturing - Tobacco Manufacturing',
'3131 - Manufacturing - Fiber, Yarn, and Thread Mills',
'3132 - Manufacturing - Fabric Mills',
'3133 - Manufacturing - Textile and Fabric Finishing and Fabric Coating Mills',
'3141 - Manufacturing - Textile Furnishings Mills',
'3149 - Manufacturing - Other Textile Product Mills',
'3151 - Manufacturing - Apparel Knitting Mills',
'3152 - Manufacturing - Cut and Sew Apparel Manufacturing',
'3159 - Manufacturing - Apparel Accessories and Other Apparel Manufacturing',
'3161 - Manufacturing - Leather and Hide Tanning and Finishing',
'3162 - Manufacturing - Footwear Manufacturing',
'3169 - Manufacturing - Other Leather and Allied Product Manufacturing',
'3211 - Manufacturing - Sawmills and Wood Preservation',
'3212 - Manufacturing - Veneer, Plywood, and Engineered Wood Product Manufacturing',
'3219 - Manufacturing - Other Wood Product Manufacturing',
'3221 - Manufacturing - Pulp, Paper, and Paperboard Mills',
'3222 - Manufacturing - Converted Paper Product Manufacturing',
'3231 - Manufacturing - Printing and Related Support Activities',
'3241 - Manufacturing - Petroleum and Coal Products Manufacturing',
'3251 - Manufacturing - Basic Chemical Manufacturing',
'3252 - Manufacturing - Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
'3253 - Manufacturing - Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
'3254 - Manufacturing - Pharmaceutical and Medicine Manufacturing',
'3255 - Manufacturing - Paint, Coating, and Adhesive Manufacturing',
'3256 - Manufacturing - Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
'3259 - Manufacturing - Other Chemical Product and Preparation Manufacturing',
'3261 - Manufacturing - Plastics Product Manufacturing',
'3262 - Manufacturing - Rubber Product Manufacturing',
'3271 - Manufacturing - Clay Product and Refractory Manufacturing',
'3272 - Manufacturing - Glass and Glass Product Manufacturing',
'3273 - Manufacturing - Cement and Concrete Product Manufacturing',
'3274 - Manufacturing - Lime and Gypsum Product Manufacturing',
'3279 - Manufacturing - Other Nonmetallic Mineral Product Manufacturing',
'3311 - Manufacturing - Iron and Steel Mills and Ferroalloy Manufacturing',
'3312 - Manufacturing - Steel Product Manufacturing from Purchased Steel',
'3313 - Manufacturing - Alumina and Aluminum Production and Processing',
'3314 - Manufacturing - Nonferrous Metal (except Aluminum) Production and Processing',
'3315 - Manufacturing - Foundries',
'3321 - Manufacturing - Forging and Stamping',
'3322 - Manufacturing - Cutlery and Handtool Manufacturing',
'3323 - Manufacturing - Architectural and Structural Metals Manufacturing',
'3324 - Manufacturing - Boiler, Tank, and Shipping Container Manufacturing',
'3325 - Manufacturing - Hardware Manufacturing',
'3326 - Manufacturing - Spring and Wire Product Manufacturing',
'3327 - Manufacturing - Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
'3328 - Manufacturing - Coating, Engraving, Heat Treating, and Allied Activities',
'3329 - Manufacturing - Other Fabricated Metal Product Manufacturing',
'3331 - Manufacturing - Agriculture, Construction, and Mining Machinery Manufacturing',
'3332 - Manufacturing - Industrial Machinery Manufacturing',
'3333 - Manufacturing - Commercial and Service Industry Machinery Manufacturing',
'3334 - Manufacturing - Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
'3335 - Manufacturing - Metalworking Machinery Manufacturing',
'3336 - Manufacturing - Engine, Turbine, and Power Transmission Equipment Manufacturing',
'3339 - Manufacturing - Other General Purpose Machinery Manufacturing',
'3341 - Manufacturing - Computer and Peripheral Equipment Manufacturing',
'3342 - Manufacturing - Communications Equipment Manufacturing',
'3343 - Manufacturing - Audio and Video Equipment Manufacturing',
'3344 - Manufacturing - Semiconductor and Other Electronic Component Manufacturing',
'3345 - Manufacturing - Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
'3346 - Manufacturing - Manufacturing and Reproducing Magnetic and Optical Media',
'3351 - Manufacturing - Electric Lighting Equipment Manufacturing',
'3352 - Manufacturing - Household Appliance Manufacturing',
'3353 - Manufacturing - Electrical Equipment Manufacturing',
'3359 - Manufacturing - Other Electrical Equipment and Component Manufacturing',
'3361 - Manufacturing - Motor Vehicle Manufacturing',
'3362 - Manufacturing - Motor Vehicle Body and Trailer Manufacturing',
'3363 - Manufacturing - Motor Vehicle Parts Manufacturing',
'3364 - Manufacturing - Aerospace Product and Parts Manufacturing',
'3365 - Manufacturing - Railroad Rolling Stock Manufacturing',
'3366 - Manufacturing - Ship and Boat Building',
'3369 - Manufacturing - Other Transportation Equipment Manufacturing',
'3371 - Manufacturing - Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
'3372 - Manufacturing - Office Furniture (including Fixtures) Manufacturing',
'3379 - Manufacturing - Other Furniture Related Product Manufacturing',
'3391 - Manufacturing - Medical Equipment and Supplies Manufacturing',
'3399 - Manufacturing - Other Miscellaneous Manufacturing',
'3111 - Manufacturing - Animal Food Manufacturing',
'4231 - Wholesale Trade - Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
'4232 - Wholesale Trade - Furniture and Home Furnishing Merchant Wholesalers',
'4233 - Wholesale Trade - Lumber and Other Construction Materials Merchant Wholesalers',
'4234 - Wholesale Trade - Professional and Commercial Equipment and Supplies Merchant Wholesalers',
'4235 - Wholesale Trade - Metal and Mineral (except Petroleum) Merchant Wholesalers',
'4236 - Wholesale Trade - Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
'4237 - Wholesale Trade - Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
'4238 - Wholesale Trade - Machinery, Equipment, and Supplies Merchant Wholesalers',
'4239 - Wholesale Trade - Miscellaneous Durable Goods Merchant Wholesalers',
'4241 - Wholesale Trade - Paper and Paper Product Merchant Wholesalers',
'4242 - Wholesale Trade - Drugs and Druggists\' Sundries Merchant Wholesalers',
'4243 - Wholesale Trade - Apparel, Piece Goods, and Notions Merchant Wholesalers',
'4244 - Wholesale Trade - Grocery and Related Product Merchant Wholesalers',
'4245 - Wholesale Trade - Farm Product Raw Material Merchant Wholesalers',
'4246 - Wholesale Trade - Chemical and Allied Products Merchant Wholesalers',
'4247 - Wholesale Trade - Petroleum and Petroleum Products Merchant Wholesalers',
'4248 - Wholesale Trade - Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
'4249 - Wholesale Trade - Miscellaneous Nondurable Goods Merchant Wholesalers',
'4251 - Wholesale Trade - Wholesale Electronic Markets and Agents and Brokers',
'4411 - Retail Trade - Automobile Dealers',
'4412 - Retail Trade - Other Motor Vehicle Dealers',
'4413 - Retail Trade - Automotive Parts, Accessories, and Tire Stores',
'4421 - Retail Trade - Furniture Stores',
'4422 - Retail Trade - Home Furnishings Stores',
'4431 - Retail Trade - Electronics and Appliance Stores',
'4441 - Retail Trade - Building Material and Supplies Dealers',
'4442 - Retail Trade - Lawn and Garden Equipment and Supplies Stores',
'4451 - Retail Trade - Grocery Stores',
'4452 - Retail Trade - Specialty Food Stores',
'4453 - Retail Trade - Beer, Wine, and Liquor Stores',
'4461 - Retail Trade - Health and Personal Care Stores',
'4471 - Retail Trade - Gasoline Stations',
'4481 - Retail Trade - Clothing Stores',
'4482 - Retail Trade - Shoe Stores',
'4483 - Retail Trade - Jewelry, Luggage, and Leather Goods Stores',
'4511 - Retail Trade - Sporting Goods, Hobby, and Musical Instrument Stores',
'4512 - Retail Trade - Book Stores and News Dealers',
'4522 - Retail Trade - Department Stores',
'4523 - Retail Trade - General Merchandise Stores, including Warehouse Clubs and Supercenters',
'4531 - Retail Trade - Florists',
'4532 - Retail Trade - Office Supplies, Stationery, and Gift Stores',
'4533 - Retail Trade - Used Merchandise Stores',
'4539 - Retail Trade - Other Miscellaneous Store Retailers',
'4541 - Retail Trade - Electronic Shopping and Mail-Order Houses',
'4542 - Retail Trade - Vending Machine Operators',
'4543 - Retail Trade - Direct Selling Establishments',
'4811 - Transportation and Warehousing - Scheduled Air Transportation',
'4812 - Transportation and Warehousing - Nonscheduled Air Transportation',
'4821 - Transportation and Warehousing - Rail Transportation',
'4831 - Transportation and Warehousing - Deep Sea, Coastal, and Great Lakes Water Transportation',
'4832 - Transportation and Warehousing - Inland Water Transportation',
'4841 - Transportation and Warehousing - General Freight Trucking',
'4842 - Transportation and Warehousing - Specialized Freight Trucking',
'4851 - Transportation and Warehousing - Urban Transit Systems',
'4852 - Transportation and Warehousing - Interurban and Rural Bus Transportation',
'4853 - Transportation and Warehousing - Taxi and Limousine Service',
'4854 - Transportation and Warehousing - School and Employee Bus Transportation',
'4855 - Transportation and Warehousing - Charter Bus Industry',
'4859 - Transportation and Warehousing - Other Transit and Ground Passenger Transportation',
'4861 - Transportation and Warehousing - Pipeline Transportation of Crude Oil',
'4862 - Transportation and Warehousing - Pipeline Transportation of Natural Gas',
'4869 - Transportation and Warehousing - Other Pipeline Transportation',
'4871 - Transportation and Warehousing - Scenic and Sightseeing Transportation, Land',
'4872 - Transportation and Warehousing - Scenic and Sightseeing Transportation, Water',
'4879 - Transportation and Warehousing - Scenic and Sightseeing Transportation, Other',
'4881 - Transportation and Warehousing - Support Activities for Air Transportation',
'4882 - Transportation and Warehousing - Support Activities for Rail Transportation',
'4883 - Transportation and Warehousing - Support Activities for Water Transportation',
'4884 - Transportation and Warehousing - Support Activities for Road Transportation',
'4885 - Transportation and Warehousing - Freight Transportation Arrangement',
'4889 - Transportation and Warehousing - Other Support Activities for Transportation',
'4911 - Transportation and Warehousing - Postal Service',
'4921 - Transportation and Warehousing - Couriers and Express Delivery Services',
'4922 - Transportation and Warehousing - Local Messengers and Local Delivery',
'4931 - Transportation and Warehousing - Warehousing and Storage',,
'5111 - Information - Newspaper, Periodical, Book, and Directory Publishers',
'5112 - Information - Software Publishers',
'5121 - Information - Motion Picture and Video Industries',
'5122 - Information - Sound Recording Industries',
'5151 - Information - Radio and Television Broadcasting',
'5152 - Information - Cable and Other Subscription Programming',
'5173 - Information - Wired and Wireless Telecommunications Carriers',
'5174 - Information - Satellite Telecommunications',
'5179 - Information - Other Telecommunications',
'5182 - Information - Data Processing, Hosting, and Related Services',
'5191 - Information - Other Information Services',
'5211 - Finance and Insurance - Monetary Authorities-Central Bank',
'5221 - Finance and Insurance - Depository Credit Intermediation',
'5222 - Finance and Insurance - Nondepository Credit Intermediation',
'5223 - Finance and Insurance - Activities Related to Credit Intermediation',
'5231 - Finance and Insurance - Securities and Commodity Contracts Intermediation and Brokerage',
'5232 - Finance and Insurance - Securities and Commodity Exchanges',
'5239 - Finance and Insurance - Other Financial Investment Activities',
'5241 - Finance and Insurance - Insurance Carriers',
'5242 - Finance and Insurance - Agencies, Brokerages, and Other Insurance Related Activities',
'5251 - Finance and Insurance - Insurance and Employee Benefit Funds',
'5259 - Finance and Insurance - Other Investment Pools and Funds',
'5311 - Real Estate - Lessors of Real Estate',
'5312 - Real Estate - Offices of Real Estate Agents and Brokers',
'5313 - Real Estate - Activities Related to Real Estate',
'5321 - Real Estate - Automotive Equipment Rental and Leasing',
'5322 - Real Estate - Consumer Goods Rental',
'5323 - Real Estate - General Rental Centers',
'5324 - Real Estate - Commercial and Industrial Machinery and Equipment Rental and Leasing',
'5331 - Real Estate - Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
'5411 - Professional, Sci, Tech Services - Legal Services',
'5412 - Professional, Sci, Tech Services - Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
'5413 - Professional, Sci, Tech Services - Architectural, Engineering, and Related Services',
'5414 - Professional, Sci, Tech Services - Specialized Design Services',
'5415 - Professional, Sci, Tech Services - Computer Systems Design and Related Services',
'5416 - Professional, Sci, Tech Services - Management, Scientific, and Technical Consulting Services',
'5417 - Professional, Sci, Tech Services - Scientific Research and Development Services',
'5418 - Professional, Sci, Tech Services - Advertising, Public Relations, and Related Services',
'5419 - Professional, Sci, Tech Services - Other Professional, Scientific, and Technical Services',
'5511 - Management of Companies - Management of Companies and Enterprises',
'5611 - Admin, Support, and Waste manage. - Office Administrative Services',
'5612 - Admin, Support, and Waste manage. - Facilities Support Services',
'5613 - Admin, Support, and Waste manage. - Employment Services',
'5614 - Admin, Support, and Waste manage. - Business Support Services',
'5615 - Admin, Support, and Waste manage. - Travel Arrangement and Reservation Services',
'5616 - Admin, Support, and Waste manage. - Investigation and Security Services',
'5617 - Admin, Support, and Waste manage. - Services to Buildings and Dwellings',
'5619 - Admin, Support, and Waste manage. - Other Support Services',
'5621 - Admin, Support, and Waste manage. - Waste Collection',
'5622 - Admin, Support, and Waste manage. - Waste Treatment and Disposal',
'5629 - Admin, Support, and Waste manage. - Remediation and Other Waste Management Services',
'6111 - Educational Services - Elementary and Secondary Schools',
'6112 - Educational Services - Junior Colleges',
'6113 - Educational Services - Colleges, Universities, and Professional Schools',
'6114 - Educational Services - Business Schools and Computer and Management Training',
'6115 - Educational Services - Technical and Trade Schools',
'6116 - Educational Services - Other Schools and Instruction',
'6117 - Educational Services - Educational Support Services',
'6211 - Health Care and Social Assistance - Offices of Physicians',
'6212 - Health Care and Social Assistance - Offices of Dentists',
'6213 - Health Care and Social Assistance - Offices of Other Health Practitioners',
'6214 - Health Care and Social Assistance - Outpatient Care Centers',
'6215 - Health Care and Social Assistance - Medical and Diagnostic Laboratories',
'6216 - Health Care and Social Assistance - Home Health Care Services',
'6219 - Health Care and Social Assistance - Other Ambulatory Health Care Services',
'6221 - Health Care and Social Assistance - General Medical and Surgical Hospitals',
'6222 - Health Care and Social Assistance - Psychiatric and Substance Abuse Hospitals',
'6223 - Health Care and Social Assistance - Specialty (except Psychiatric and Substance Abuse) Hospitals',
'6231 - Health Care and Social Assistance - Nursing Care Facilities (Skilled Nursing Facilities)',
'6232 - Health Care and Social Assistance - Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
'6233 - Health Care and Social Assistance - Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
'6239 - Health Care and Social Assistance - Other Residential Care Facilities',
'6241 - Health Care and Social Assistance - Individual and Family Services',
'6242 - Health Care and Social Assistance - Community Food and Housing, and Emergency and Other Relief Services',
'6243 - Health Care and Social Assistance - Vocational Rehabilitation Services',
'6244 - Health Care and Social Assistance - Child Day Care Services',
'7111 - Arts, Entertainment, and Recreation - Performing Arts Companies',
'7112 - Arts, Entertainment, and Recreation - Spectator Sports',
'7113 - Arts, Entertainment, and Recreation - Promoters of Performing Arts, Sports, and Similar Events',
'7114 - Arts, Entertainment, and Recreation - Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
'7115 - Arts, Entertainment, and Recreation - Independent Artists, Writers, and Performers',
'7121 - Arts, Entertainment, and Recreation - Museums, Historical Sites, and Similar Institutions',
'7131 - Arts, Entertainment, and Recreation - Amusement Parks and Arcades',
'7132 - Arts, Entertainment, and Recreation - Gambling Industries',
'7139 - Arts, Entertainment, and Recreation - Other Amusement and Recreation Industries',
'7211 - Accommodation and Food Services - Traveler Accommodation',
'7212 - Accommodation and Food Services - RV (Recreational Vehicle) Parks and Recreational Camps',
'7213 - Accommodation and Food Services - Rooming and Boarding Houses, Dormitories, and Workers\' Camps',
'7223 - Accommodation and Food Services - Special Food Services',
'7224 - Accommodation and Food Services - Drinking Places (Alcoholic Beverages)',
'7225 - Accommodation and Food Services - Restaurants and Other Eating Places',
'8111 - Other Services (except Public Administration) - Automotive Repair and Maintenance',
'8112 - Other Services (except Public Administration) - Electronic and Precision Equipment Repair and Maintenance',
'8113 - Other Services (except Public Administration) - Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
'8114 - Other Services (except Public Administration) - Personal and Household Goods Repair and Maintenance',
'8121 - Other Services (except Public Administration) - Personal Care Services',
'8122 - Other Services (except Public Administration) - Death Care Services',
'8123 - Other Services (except Public Administration) - Drycleaning and Laundry Services',
'8129 - Other Services (except Public Administration) - Other Personal Services',
'8131 - Other Services (except Public Administration) - Religious Organizations',
'8132 - Other Services (except Public Administration) - Grantmaking and Giving Services',
'8133 - Other Services (except Public Administration) - Social Advocacy Organizations',
'8134 - Other Services (except Public Administration) - Civic and Social Organizations',
'8139 - Other Services (except Public Administration) - Business, Professional, Labor, Political, and Similar Organizations',
'8141 - Other Services (except Public Administration) - Private Households',
'9211 - Public Administration - Executive, Legislative, and Other General Government Support',
'9221 - Public Administration - Justice, Public Order, and Safety Activities',
'9231 - Public Administration - Administration of Human Resource Programs',
'9241 - Public Administration - Administration of Environmental Quality Programs',
'9251 - Public Administration - Administration of Housing Programs, Urban Planning, and Community Development',
'9261 - Public Administration - Administration of Economic Programs',
'9271 - Public Administration - Space Research and Technology',
'9281 - Public Administration - National Security and International Affairs',
]