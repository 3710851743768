import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import video_player from '../images/video-player.svg'
import NumberFormat from 'react-number-format';
import '../components_css/Worksheet.css'
import { useHistory } from 'react-router'
import * as allCurrency from '../constant/CURRENCY';
import {floatCheck, momentConvertFormat, CustomInput} from '../utils/globalFunction'
import { Autosave } from 'react-autosave'; 

function Worksheet3({ Q34, setQ34, WorksheetYear,backCall,submitCall,saveCall,mainData }) {

    const currency = allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.symbol || '$'
    const [Q35, setQ35] = useState(floatCheck(mainData?.worksheet?.cell_phone_owner))
    const [Q36, setQ36] = useState(floatCheck(mainData?.worksheet?.company_contribution_to_retirement_owner))
    const [Q37, setQ37] = useState(floatCheck(mainData?.worksheet?.health_insurance_owner))
    const [Q39, setQ39] = useState(floatCheck(mainData?.worksheet?.other_personal_expenses))
    const [Q40, setQ40] = useState(floatCheck(mainData?.worksheet?.owners_draw_distribution))
    const [Q41, setQ41] = useState(floatCheck(mainData?.worksheet?.owner_vehicle_expenses_fuel))
    const [Q42, setQ42] = useState(floatCheck(mainData?.worksheet?.owner_vehicle_expenses_insurance))
    const [Q43, setQ43] = useState(floatCheck(mainData?.worksheet?.owner_vehicle_expenses_loan_payment))
    const [Q44, setQ44] = useState(floatCheck(mainData?.worksheet?.owner_vehicle_expenses_repair_maintenance))
    const [Q45, setQ45] = useState(floatCheck(mainData?.worksheet?.wages_from_payroll))
    const [Q46, setQ46] = useState(floatCheck(mainData?.worksheet?.other_owner_compensation))
    const [compensationWriteHere, setCompensationWriteHere] = useState(mainData?.worksheet?.compensation_write_here || '')
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])


    useEffect(() => {
        setQ34((parseFloat((typeof Q35==='undefined' || Q35===''  || Number.isNaN(Q35))? 0 : Q35) + parseFloat((typeof Q36==='undefined' || Q36===''  || Number.isNaN(Q36))? 0 : Q36) + parseFloat((typeof Q37==='undefined' || Q37===''  || Number.isNaN(Q37))? 0 : Q37) + parseFloat((typeof Q39==='undefined' || Q39===''  || Number.isNaN(Q39))? 0 : Q39) + parseFloat((typeof Q40==='undefined' || Q40===''  || Number.isNaN(Q40))? 0 : Q40) + parseFloat((typeof Q41==='undefined' || Q41==='' || Number.isNaN(Q41))? 0 : Q41) + parseFloat((typeof Q42==='undefined' || Q42===''  || Number.isNaN(Q42))? 0 : Q42) + parseFloat((typeof Q43==='undefined' || Q43===''  || Number.isNaN(Q43))? 0 : Q43) + parseFloat((typeof Q44==='undefined' || Q44===''  || Number.isNaN(Q44))? 0 : Q44) + parseFloat((typeof Q45==='undefined' || Q45===''  || Number.isNaN(Q45))? 0 : Q45) + parseFloat((typeof Q46==='undefined' || Q46===''  || Number.isNaN(Q46))? 0 : Q46)).toFixed())
    }, [Q35, Q36, Q37, Q39, Q40, Q41, Q42, Q43, Q44, Q45, Q46, setQ34])

    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (true) {
            const data = {
                cell_phone_owner:Q35,
                company_contribution_to_retirement_owner:Q36,
                health_insurance_owner:Q37,
                other_personal_expenses:Q39,
                owners_draw_distribution:Q40,
                owner_vehicle_expenses_fuel:Q41,
                owner_vehicle_expenses_insurance:Q42,
                owner_vehicle_expenses_loan_payment:Q43,
                owner_vehicle_expenses_repair_maintenance:Q44,
                other_owner_compensation:Q46,
                compensation_write_here:compensationWriteHere,
                wages_from_payroll:Q45, 
                edited_quater:null,
                owner_compensation:Q34
            }
            submitCall(Q34,data)
            // history.push("/worksheet4")
        }
        else {

        }
    }

   const data = {
                cell_phone_owner:Q35,
                company_contribution_to_retirement_owner:Q36,
                health_insurance_owner:Q37,
                other_personal_expenses:Q39,
                owners_draw_distribution:Q40,
                owner_vehicle_expenses_fuel:Q41,
                owner_vehicle_expenses_insurance:Q42,
                owner_vehicle_expenses_loan_payment:Q43,
                owner_vehicle_expenses_repair_maintenance:Q44,
                other_owner_compensation:Q46,
                compensation_write_here:compensationWriteHere,
                wages_from_payroll:Q45, 
                edited_quater:null,
                owner_compensation:Q34
    }

  const handleSave = (e) => {
        if (true) {
            saveCall(Q34,data)
        }
        else {
            console.log('Something wrong')
        }
    }


    return (
            <div className='worksheet_main'>
                <div className={'page_content'}>
                <div className='worksheet_form_container'>
                    <div className='worksheet_form_header'>
                        <div className='flex_image' >
                            <h4>Owner's Compensation Worksheet</h4>
                            <a href="https://pat-api.profitfirsttools.com/static/video/owner-compesation.mp4" target="_blank"><img alt='' className="video-icon" src={video_player} /> <i>Watch Tutorial</i></a>
                        </div>
                        <h3 className={`worksheet_form_header_h3`} >{mainData?.analyzing_report?.run_rate ? `${momentConvertFormat(mainData?.analyzing_report?.starting_month,'YYYY-MM-DD','MM/DD/YYYY')} - ${momentConvertFormat(mainData?.analyzing_report?.ending_month,'YYYY-MM-DD','MM/DD/YYYY')}` :  mainData?.analyzing_report?.analysing_year}</h3>
                       
                    </div>
                    <div className='worksheet_form'>
                        <div className='worksheet_table_container'>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left worksheet_dark_left'>Owner's Compensation</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    {/* <span className='worksheet_right worksheet_dark_right'>{Q34 === 'NaN' ? '0.00' : Q34}</span> */}
                                    <NumberFormat className='worksheet_right worksheet_dark_right' value={Q34 === 'NaN' ? `${currency}0` : Q34} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Total Wages from Payroll (Gross, Including Taxes) - Owner</span>
                                </div>
                                <div className='worksheet_right_side'>
                                <CustomInput value={Q45} formatedValue={Q45} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ45(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q45} placeholder="$0.00" onValueChange={(value) => setQ45(value.floatValue)}/> */}
                                 {/* <input className='worksheet_right' placeholder='0.00' value={Q45} onChange={(e) => setQ45(e.target.value)} required /> */}
                                </div>
                            </div>
                            
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Owner's Draws / Distributions, Minus any Draws/Distributions used to Pay Taxes (from Balance Sheet)</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q40} formatedValue={Q40} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ40(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q40} placeholder="$0.00" onValueChange={(value) => setQ40(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q40} onChange={(e) => setQ40(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Cell Phone Owner</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q35} formatedValue={Q35} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ35(val)}/>
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q35} onChange={(e) => setQ35(e.target.value)} required /> */}
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q35} placeholder="$0.00" onValueChange={(value) => setQ35(value.floatValue)}/> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                     <span className='worksheet_left'>Company Contributions to Retirement – Owner</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q36} formatedValue={Q36} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ36(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q36} placeholder="$0.00" onValueChange={(value) => setQ36(value.floatValue)}/> */}
                                     {/* <input className='worksheet_right' placeholder='0.00' value={Q36} onChange={(e) => setQ36(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Health Insurance - Owner</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q37} formatedValue={Q37} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ37(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q37} placeholder="$0.00" onValueChange={(value) => setQ37(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q37} onChange={(e) => setQ37(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Other Personal expenses paid by The Business - Owner</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q39} formatedValue={Q39} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ39(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q39} placeholder="$0.00" onValueChange={(value) => setQ39(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q39} onChange={(e) => setQ39(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Owner's Vehicle Expenses - Fuel</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q41} formatedValue={Q41} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ41(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q41} placeholder="$0.00" onValueChange={(value) => setQ41(value.floatValue)}/> */}
                                     {/* <input className='worksheet_right' placeholder='0.00' value={Q41} onChange={(e) => setQ41(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Owner's Vehicle Expenses - Insurance</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q42} formatedValue={Q42} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ42(val)}/>
                                {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q42} placeholder="$0.00" onValueChange={(value) => setQ42(value.floatValue)}/> */}
                                {/* <input className='worksheet_right' placeholder='0.00' value={Q42} onChange={(e) => setQ42(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                 <span className='worksheet_left'>Owner's Vehicle Expenses - Loan Payment</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q43} formatedValue={Q43} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ43(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q43} placeholder="$0.00" onValueChange={(value) => setQ43(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q43} onChange={(e) => setQ43(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Owner's Vehicle Expenses - Repairs and Maintenance</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q44} formatedValue={Q44} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ44(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q44} placeholder="$0.00" onValueChange={(value) => setQ44(value.floatValue)}/> */}
                                 {/* <input className='worksheet_right' placeholder='0.00' value={Q44} onChange={(e) => setQ44(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'><span>Other Owner's Compensation</span>
                                        <span className="spancol spancol-note" style={{ paddingRight: '20px' }}>Note: Please list items included in this amount</span>
                                    </span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q46} formatedValue={Q46} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ46(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q46} placeholder="$0.00" onValueChange={(value) => setQ46(value.floatValue)}/> */}
                                 {/* <input className='worksheet_right' placeholder='0.00' value={Q46} onChange={(e) => setQ46(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                 <input value={compensationWriteHere} onChange={(e)=>setCompensationWriteHere(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                </div>
                                <div className='worksheet_right_side'>
                                  <span className='worksheet_right worksheet_last'>&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div className='worksheet_footer'>
                            <button className='worksheet_previous_btn' onClick={backCall} >Previous</button>
                            <div className='worksheet_progress_bar' style={{ left: '0' }}><div className='worksheet_progress' style={{ width: "62.5%" }}></div></div>
                            <button className='worksheet_submit_btn' onClick={handleSubmit} >Save and continue</button>
                        </div>
                    </div>
                    <Autosave data={data} onSave={handleSave} />
                </div>
                <Footer />
            </div>
            </div>
    )
}

export default Worksheet3
