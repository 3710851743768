import React, { useState,useEffect } from 'react'
import Footer from './Footer'
import moment from 'moment';

import DatePicker from 'react-date-picker';
import video_player from '../images/video-player.svg'
import download_icon from '../images/download.svg'
import styles from '../components_css/Analyzing_report.module.css'
import { useHistory } from 'react-router'
import axios from 'axios'
import cx from 'classnames'
import {formatDate, formatDate2,newDateFormat} from '../utils/globalFunction'
import {formatEndDate} from '../utils/globalFunction'
import { State } from 'country-state-city';
import { Autosave } from 'react-autosave';



function AnalyzingReport({ BaseURL,backCall,submitCall,saveCall, mainData }) {
    const [Textarea, setTextarea] = useState(mainData?.analyzing_report?.notes || '')
    const [runRate, setRunRate] = useState(mainData?.analyzing_report?.run_rate===true?1:2)
    const [runRateMonth, setRunRateMonth] = useState(mainData?.analyzing_report?.month||'')
    const [rateStartMonth, setRateStartMonth] = useState(newDateFormat(mainData?.analyzing_report?.starting_month))
    const [rateEndMonth, setRateEndMonth] = useState(newDateFormat(mainData?.analyzing_report?.ending_month))

    // tax 
    const [taxLoading,setTaxLoading] = useState(false)
    const [taxFile,setTaxFile] = useState(mainData?.analyzing_report?.taxFile)
  
    // income statement 
    const [incomeLoading,setIncomeLoading] = useState(false)  
    const [incomeStatement,setIncomeStatement] = useState(mainData?.analyzing_report?.incomeStatement) 
    const [assessmentYear,setAssessmentYear] = useState(mainData?.analyzing_report?.analysing_year) 

    // balancesheet
    const [balanceLoading,setBalanceLoading] = useState(false)  
    const [balanceSheet,setBalanceSheet] = useState(mainData?.analyzing_report?.balanceSheet)  

    // cash flow
    const [cashLoading,setCashLoading] = useState(false)  
    const [cashFlow,setCashFlow] = useState(mainData?.analyzing_report?.cashFlow)  

    // other document
    const [otherLoading,setOtherLoading] = useState(false)  
    const [otherDocs,setOtherDocs] = useState(mainData?.analyzing_report?.documents)  
    const [otherDocsText,setOtherDocsText] = useState(mainData?.analyzing_report?.other_docs_text || '')  


    const [value, onChange] = useState(new Date("YYYY-MM-DD"));

    let token = localStorage.getItem('userAccessToken')
    
    useEffect(() => {
        setTextarea(mainData?.analyzing_report?.notes || '')
        setRunRate(mainData?.analyzing_report?.run_rate===true?1:2)
        setRunRateMonth(mainData?.analyzing_report?.month || '')
        if(mainData?.analyzing_report?.startingMonth){
            setRateStartMonth(newDateFormat(mainData?.analyzing_report?.startingMonth))
        }
        if(mainData?.analyzing_report?.analysing_year){
            setAssessmentYear(mainData?.analyzing_report?.analysing_year)
        }
        if(mainData?.analyzing_report?.startingMonth){
            setRateEndMonth(newDateFormat(mainData?.analyzing_report?.ending_month))
        }
    }, [mainData?.analyzing_report]);

    const analyzing_report_user_data = {
        "analyzing_report": {
            "reports": null,
            "other_docs_text": null,
            "documents": null,
            "run_rate": false
        },
        "progress_bar": 25,
        "starting_month":'02/2021',
        "ending_month":'03/2021',
        "month_using_in_current_year": 1
    }

    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
    
        if(!!rateStartMonth && runRateMonth){
            console.log(rateStartMonth)
            const date = new Date(rateStartMonth);
            const end = new Date(date.setMonth(date.getMonth() + parseInt(runRateMonth)));
            setRateEndMonth(formatDate2(end))
        }
    },[runRateMonth,rateStartMonth]);

    const changeStart = (e) => {
        setRateStartMonth(e)
    }

    const setFileRes = (type,value) =>{
        switch (type) {
            case 'taxLoading':
                setTaxFile(value)
                break;
            case 'incomeLoading':
                setIncomeStatement(value)
                break;
            case 'balanceLoading':
                setBalanceSheet(value)
                break;
            case 'cashLoading':
                setCashFlow(value)
                break;
            case 'otherLoading':
                setOtherDocs(value)
                break;
            default:
                break;
        }
    }

    const getFileRes = (type) =>{
        switch (type) {
            case 'taxLoading':
                return taxFile;
                break;
            case 'incomeLoading':
                return incomeStatement;
                break;
            case 'balanceLoading':
                return balanceSheet;
                break;
            case 'cashLoading':
                return cashFlow;
                break;
            case 'otherLoading':
                return otherDocs;
                break;
            default:
                return '';
                break;
        }
    }


    const actionLoading = (type,status) => {
        switch (type) {
            case 'taxLoading':
                setTaxLoading(status)
                break;
            case 'incomeLoading':
                setIncomeLoading(status)
                break;
            case 'balanceLoading':
                setBalanceLoading(status)
                break;
            case 'cashLoading':
                setCashLoading(status)
                break;
            case 'otherLoading':
                setOtherLoading(status)
                break;
            default:
                break;
        } 
    }
    

    const uploadFile = (e,type) =>{
        let data = e.target.files
        const formData = new FormData();
        formData.append(
            'avatar',
            data[0],
            data[0].name
        )
        formData.append('avatar', data);
        if(e.target.files[0]!=null){

        actionLoading(type,true)   
        axios.post(`${BaseURL}/api/v1/user/avatar-upload/`, formData,{ headers: { "Authorization": `JWT ${token}` }})
                .then(response => {
                    setFileRes(type,response.data.avatar)
                    actionLoading(type,false)
                }).catch(error => {
                    
                    actionLoading(type,false)
                console.log("*****  "+error)
            });
        }
    }

    const deleteFile = (type, name) =>{
        if(window.confirm('Are sure want to delete ' + name +'?')) {
            let filepath = getFileRes(type);
            let data = {"file":filepath} 
                      
            axios.put(`${BaseURL}/api/v1/user/delete-upload/`, data,{ headers: { "Authorization": `JWT ${token}` }})
                    .then(response => {
                        setFileRes(type,null)
                        actionLoading(type,false)
                    }).catch(error => {
                        
                        actionLoading(type,false)
                    console.log("*****  "+error)
            });
        }
        
    }  
    const openFile = (filelink) =>{
        axios({url: `${BaseURL}${filelink}`, 
            method: 'GET',
            headers: { "Authorization": `JWT ${token}` }, 
            responseType: 'blob',})
                .then((response) => {
                // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;

                    // 1) Get the value of content-disposition header
                    const contentDisposition = response.headers['content-disposition'];
                    // console.log('response headers: ', response.headers);

                    // console.log('contentDisposition: ', contentDisposition);

                    // 2) set the fileName variable to the default value
                    let fileName = 'ProfitFirstFile';

                    // 3) if the header is set, extract the filename
                    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) { 
                          fileName = matches[1].replace(/['"]/g, '');
                        }
                    }
                  
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    console.log("file downloaded");
                }).catch(error => {
                console.log("*****  "+error)
            });
    }

    const data ={
            notes:Textarea,
            run_rate:runRate===1?true:false,
            month:runRateMonth,
            starting_month:formatEndDate(rateStartMonth),
            ending_month:formatEndDate(rateEndMonth),
            analysing_year:assessmentYear,
            taxFile:taxFile,
            incomeStatement:incomeStatement,
            balanceSheet:balanceSheet,
            cashFlow:cashFlow,
            other_docs_text:otherDocsText,
            documents:otherDocs,
     }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("date "+formatEndDate(rateStartMonth));
        // if (true) {
        //     axios.post(`${BaseURL}/api/v1/assessments/create/`, analyzing_report_user_data, { headers: { "Authorization": `JWT ${token}` } })
        //         .then(response => {
                    // const data ={
                    //     notes:Textarea,
                    //     run_rate:runRate===1?true:false,
                    //     month:runRateMonth,
                    //     starting_month:formatEndDate(rateStartMonth),
                    //     ending_month:formatEndDate(rateEndMonth),
                    //     analysing_year:assessmentYear,
                    //     taxFile,
                    //     incomeStatement,
                    //     balanceSheet,
                    //     cashFlow,
                    //     other_docs_text:null,
                    //     documents:otherDocs,
                    // }
                   
                    submitCall(data)
                    // history.push("/worksheet1")
        //         })
        //         .catch(err => alert(err))
        // }
        // else {
        //     console.log('someth wro')
        // }
    }
    

    const handleSave = (e) => {
        console.log("date "+formatEndDate(rateStartMonth));
        saveCall(data)
    }

    return (
            <div className={styles.analyzing_report_main}>
                <div className={styles.page_content}>
                <div className={styles.analyzing_report_form_container}>
                    <div className={styles.analyzing_report_form_header}>
                        <h4>Analyzing Reports</h4>
                        <a href="https://pat-api.profitfirsttools.com/static/video/analyze-report.mp4" target="_blank"><img alt='' className="video-icon" src={video_player} /> <i>Watch Tutorial</i></a>
                    </div>
                    <div className={styles.analyzing_report_form}>
                        <div className={styles.analyzing_report_row}>
                            <div className={styles.analyzing_report_input_container}>
                                <label>What reports are you using to complete this assessment?</label>
                                <div className="analyzing_report-from analyeze_form_part">
                                    <div className="analyzing_report-from-group">
                                        <div>
                                        <input className={styles.analyzing_report_checkbox} type="checkbox" disabled checked={!!taxFile} name="check" />
                                        <span>Tax Returns</span> {!!taxFile ? <div>&nbsp;&nbsp;&nbsp; <a href="#" onClick={()=>openFile(taxFile)}><img src={download_icon} width="20"/></a>&nbsp;&nbsp;&nbsp; <button onClick={()=>deleteFile('taxLoading', 'Tax Returns')} className="remove-file">X</button>&nbsp;&nbsp; </div> : null}
                                        </div>
                                        {!taxLoading?
                                        <label className={styles.custom_file_upload}>
                                            <input type="file"  onChange={(e)=>uploadFile(e,'taxLoading')} />
                                            <em>Upload</em>
                                        </label>
                                        :'Uploading...'}
                                    </div>
                                    <div className="analyzing_report-from-group">
                                        <div>
                                        <input className={styles.analyzing_report_checkbox} type="checkbox" disabled checked={!!incomeStatement} name="check" />
                                        <span>Income Statement</span>  {!!incomeStatement ? <div>&nbsp;&nbsp;&nbsp; <a href="#" onClick={()=>openFile(incomeStatement)}><img src={download_icon} width="20"/></a>&nbsp;&nbsp;&nbsp; <button onClick={()=>deleteFile('incomeLoading','Income Statement')} className="remove-file">X</button>&nbsp;&nbsp; </div> : null}
                                        </div>
                                        {!incomeLoading?
                                        <label className={styles.custom_file_upload}>
                                            <input type="file"  onChange={(e)=>uploadFile(e,'incomeLoading')}/>
                                            <em>Upload</em>
                                        </label>:'Uploading...'}
                                    </div>
                                    <div className="analyzing_report-from-group">
                                        <div>
                                        <input className={styles.analyzing_report_checkbox} type="checkbox" disabled checked={!!balanceSheet}  name="check" />
                                        <span>Balance Sheet</span> {!!balanceSheet ? <div>&nbsp;&nbsp;&nbsp; <a href="#" onClick={()=>openFile(balanceSheet)}><img src={download_icon} width="20"/></a>&nbsp;&nbsp;&nbsp; <button onClick={()=>deleteFile('balanceLoading','Balance Sheet')} className="remove-file">X</button>&nbsp;&nbsp; </div> : null}
                                        </div>
                                        {!balanceLoading?
                                        <label className={styles.custom_file_upload}>
                                            <input type="file" onChange={(e)=>uploadFile(e,'balanceLoading')}/>
                                            <em>Upload</em>
                                        </label>:'Uploading...'}
                                    </div>
                                    <div className="analyzing_report-from-group">
                                        <div>
                                        <input className={styles.analyzing_report_checkbox} type="checkbox" disabled checked={!!cashFlow} name="check" />
                                        <span>Cash Flow Statement</span> {!!cashFlow ? <div>&nbsp;&nbsp;&nbsp; <a href="#" onClick={()=>openFile(cashFlow)}><img src={download_icon} width="20"/></a>&nbsp;&nbsp;&nbsp; <button onClick={()=>deleteFile('cashLoading','Cash Flow Statement')} className="remove-file">X</button>&nbsp;&nbsp; </div> : null}
                                        </div>
                                        {!cashLoading?
                                        <label className={styles.custom_file_upload}>
                                            <input type="file" onChange={(e)=>uploadFile(e,'cashLoading')}/>
                                            <em>Upload</em>
                                        </label>:'Uploading...'}
                                    </div>
                                    <div className="analyzing_report-from-group anlyze_last_report">
                                        <div>
                                        <input className={styles.analyzing_report_checkbox} type="checkbox" disabled checked={!!otherDocs} name="check" />
                                        <span>Other Documents</span>  {!!otherDocs ? <div>&nbsp;&nbsp;&nbsp; <a href="#" onClick={()=>openFile(otherDocs)}><img src={download_icon} width="20"/></a>&nbsp;&nbsp;&nbsp; <button onClick={()=>deleteFile('otherLoading','Other Documents')} className="remove-file">X</button>&nbsp;&nbsp; </div> : null}
                                        <input value={otherDocsText}  onChange={(e)=>setOtherDocsText(e.target.value)} className={styles.analyzing_report_input} id="otherDocument"></input>
                                        </div>
                                        {!otherLoading?
                                        <label className={styles.custom_file_upload}>
                                            <input type="file" onChange={(e)=>uploadFile(e,'otherLoading')}/>
                                            <em>Upload</em>
                                        </label>:'Uploading...'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.analyzing_report_row} id="analyzeTextarea">
                            <div className={styles.analyzing_report_input_container}>
                                <label>Analyzing Reports Notes</label>
                                <textarea className={styles.analyzing_report_textarea} rows="4" placeholder='Enter here' value={Textarea} onChange={(e) => setTextarea(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className={cx(styles.analyzing_report_form_header, styles.analyzing_report_runrate)}>
                        <h4>Run Rate</h4>
                    </div>
                    
                    <div className={styles.analyzing_report_form}>
                        <div className={styles.analyzing_report_row}>
                            <div className={styles.analyzing_report_input_container}>
                                <label>Are you using a Run Rate?</label>
                                <div className={styles.analyzing_report_input_holder}>
                                    <div><input className={styles.analyzing_report_radio} value="1" onChange={(e)=>setRunRate(parseInt(e.target.value))} checked={runRate===1} type="radio" name="company_name" /><span>Yes</span></div>
                                    <div><input style={{ marginLeft: "40px" }} value="2" checked={runRate===2} onChange={(e)=>setRunRate(parseInt(e.target.value))} className={styles.analyzing_report_radio} type="radio" name="company_name" /><span>No</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {runRate===1?
                    <>
                        <div className="analyze_run_rate">
                        <div className={styles.analyzing_report_row}>
                            <div className={styles.analyzing_report_input_container}>
                                <label>How many months are you using for the current year's data?</label>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                <select value={runRateMonth} onChange={(e)=>setRunRateMonth(parseInt(e.target.value))} className={styles.analyzing_report_select}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                </select>
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                        </div>
                        <div className={styles.analyzing_report_row}>
                            <div className="custom_date_picker">
                                <label>Starting Month</label>
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                <DatePicker    
                                    className="custom-picker"     
                                    onChange={(e)=>changeStart(e)}
                                    value={rateStartMonth} 
                                    selected={rateStartMonth}
                                    dateFormat="YYYY-MM-DD"
                                />
                                {/* <input type="date" value={rateStartMonth} onChange={(e)=>setRateStartMonth(e.target.value)} className={styles.analyzing_report_select}/> */}
                                {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                            </div>
                        </div>
                        <div className={styles.analyzing_report_row}>
                            <div className="custom_date_picker">
                                <label>Ending Month</label>
                                <DatePicker    
                                    className="custom-picker"
                                    disabled
                                    value={rateEndMonth} 
                                    selected={rateEndMonth}
                                    dateFormat="YYYY-MM-DD"
                                />
                                {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                {/* <input type="date" value={rateEndMonth}  disabled className={styles.analyzing_report_select}/> */}
                            </div>
                        </div>
                        </div>
                        </>
                    :  <div className="analyze_run_rate">
                            <div className={styles.analyzing_report_row}>
                                <div className="custom_date_picker">
                                    <label>First select year of Assessments</label>
                                    {/* <span className={`error_msg ${InvalidEmail && `display_error`}`}>Enter a valid Email</span> */}
                                    <select defaultValue={assessmentYear} onChange={(e)=>setAssessmentYear(parseInt(e.target.value))} className={styles.analyzing_report_select}>
                                        {Array.from( new Array(100), (v,i) =><option key={i}  value={1970+i}>{1970+i}</option>)}
                                    </select>
                                    {/* <input type="date" value={rateStartMonth} onChange={(e)=>setRateStartMonth(e.target.value)} className={styles.analyzing_report_select}/> */}
                                    {/* <img alt='' src={info} className={`error_icon ${InvalidEmail && `display_error`}`}></img> */}
                                </div>
                            </div>
                        </div>
                 }
                    <div className={styles.analyzing_report_footer} id="analyzeFooter">
                        <button className={styles.analyzing_report_previous_btn}  onClick={backCall}>Previous</button>
                        <div className={styles.analyzing_report_progress_bar} style={{ left: '0' }}><div className={styles.analyzing_report_progress} style={{ width: "25%" }}></div></div>
                        <button className={styles.analyzing_report_submit_btn} onClick={handleSubmit} >Save and continue</button>
                    </div>
                </div>
                 <Autosave data={data} onSave={handleSave} />
                </div>
                <Footer />
            </div>
    )
}

export default AnalyzingReport
