import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import video_player from '../images/video-player.svg'
import NumberFormat from 'react-number-format';
import '../components_css/Worksheet.css'
import * as allCurrency from '../constant/CURRENCY';
import { useHistory } from 'react-router'
import {floatCheck, momentConvertFormat, CustomInput} from '../utils/globalFunction'
import { Autosave } from 'react-autosave'; 

function Worksheet4({ Q48, setQ48, WorksheetYear,backCall, submitCall, saveCall, mainData }) {

    const currency = allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.symbol || '$'
    // const [Q48, setQ48] = useState('')
    const [Q49, setQ49] = useState(floatCheck(mainData?.worksheet?.owners_draw_for_taxes))
    const [Q50, setQ50] = useState(floatCheck(mainData?.worksheet?.other_taxes))
    const [taxWriteHere, setTaxWriteHere] = useState(mainData?.worksheet?.tax_write_here || '')

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])


    useEffect(() => {
        setQ48((parseFloat((typeof Q49==='undefined' || Q49==='' || Number.isNaN(Q49))? 0 : Q49) + parseFloat((typeof Q50==='undefined' || Q50==='' || Number.isNaN(Q50))? 0 : Q50)).toFixed())
    }, [Q49, Q50, setQ48])

    const history = useHistory()
    const data={
                owners_draw_for_taxes:Q49,
                other_taxes:Q50,
                tax_write_here:taxWriteHere, 
                edited_quater:null,
                tax:Q48
            }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        if (true) {
            // const data={
            //     owners_draw_for_taxes:Q49,
            //     other_taxes:Q50,
            //     tax_write_here:taxWriteHere, 
            //     edited_quater:null,
            //     tax:Q48
            // }
            submitCall(Q48,data)
            // history.push("/worksheet5")
        }
        else {
        }
    }
    
    const handleSave = (e) => {
        if (true) {
            saveCall(Q48,data)
        }
        else {
            console.log('Something wrong')
        }
    }

    return (
            <div className='worksheet_main'>
                 <div className={'page_content'}>
                <div className='worksheet_form_container'>
                    <div className='worksheet_form_header'>
                     <div className='flex_image' >
                        <h4>Tax Worksheet</h4>
                        <a href="https://pat-api.profitfirsttools.com/static/video/tax-worksheet.mp4" target="_blank"><img alt='' className="video-icon" src={video_player} /> <i>Watch Tutorial</i></a>
                    </div>
                    <h3 className={`worksheet_form_header_h3`} >{mainData?.analyzing_report?.run_rate ? `${momentConvertFormat(mainData?.analyzing_report?.starting_month,'YYYY-MM-DD','MM/DD/YYYY')} - ${momentConvertFormat(mainData?.analyzing_report?.ending_month,'YYYY-MM-DD','MM/DD/YYYY')}` :  mainData?.analyzing_report?.analysing_year}</h3>
                      
                    </div>
                    <div className='worksheet_form'>
                        <div className='worksheet_table_container'>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left worksheet_dark_left'>Tax</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                     <NumberFormat className='worksheet_right worksheet_dark_right' value={Q48 === 'NaN' ? `${currency}0` : Q48} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Owner's Draws / Distributions for Taxes</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <CustomInput value={Q49} formatedValue={Q49} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ49(val)}/>
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q49} onChange={(e) => setQ49(e.target.value)} required /> */}
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q49} placeholder="$0.00" onValueChange={(value) => setQ49(value.floatValue)}/> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                 <span className='worksheet_left'>
                                     <span className="spancol">Other Taxes</span>
                                     <span className="spancol spancol-note" style={{ paddingRight: '20px' }}>Note: Please list items included in this amount</span></span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <CustomInput value={Q50} formatedValue={Q49} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ50(val)}/>
                                 {/* <input className='worksheet_right' placeholder='0.00' value={Q50} onChange={(e) => setQ50(e.target.value)} required /> */}
                                 {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q50} placeholder="$0.00" onValueChange={(value) => setQ50(value.floatValue)}/> */}
                                </div>
                            </div>

                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <input value={taxWriteHere} onChange={(e)=>setTaxWriteHere(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                 </div>
                                 <div className='worksheet_right_side'>
                                     <span className='worksheet_right worksheet_last'>&nbsp;</span>
                                </div>
                            </div>

                         
                        </div>
                        <div className='worksheet_footer'>
                            <button className='worksheet_previous_btn' onClick={backCall} >Previous</button>
                            <div className='worksheet_progress_bar' style={{ left: '0' }}><div className='worksheet_progress' style={{ width: "75%" }}></div></div>
                            <button className='worksheet_submit_btn' onClick={handleSubmit} >Save and continue</button>
                        </div>
                    </div>
                    <Autosave data={data} onSave={handleSave} />
                </div>
                <Footer />
            </div>
            </div>
    )
}

export default Worksheet4
