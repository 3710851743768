import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import video_player from '../images/video-player.svg'
import NumberFormat from 'react-number-format';
import '../components_css/Worksheet.css'
import { useHistory } from 'react-router'
import * as allCurrency from '../constant/CURRENCY';
import {floatCheck, momentConvertFormat, CustomInput} from '../utils/globalFunction';
import { Autosave } from 'react-autosave'; 

function Worksheet2({ Q27, setQ27, WorksheetYear, backCall, submitCall, saveCall, mainData }) {
    const currency = allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.symbol || '$'
    const [Q28, setQ28] = useState(floatCheck(mainData?.worksheet?.money_allocated_to_profit_account))
    const [Q30, setQ30] = useState(floatCheck(mainData?.worksheet?.other_profit))
    const [Q31, setQ31] = useState(floatCheck(mainData?.worksheet?.money_owner_contributed_to_business))
    const [Q33, setQ33] = useState(floatCheck(mainData?.worksheet?.proceeds_from_loans))
    const [profitWriteHere,setProfitWriteHere] = useState(mainData?.worksheet?.profit_write_here || '')
    const [otherProfitWriteHere,setOtherProfitWriteHere] = useState(mainData?.worksheet?.other_profit_write_here || '')
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    useEffect(() => {
        setQ27((parseFloat((typeof Q28==='undefined' || Q28==='' || Number.isNaN(Q28))? 0 : Q28) - parseFloat((typeof Q30==='undefined' || Q30===''  || Number.isNaN(Q30))? 0 : Q30) + parseFloat((typeof Q31==='undefined' || Q31===''  || Number.isNaN(Q31))? 0 : Q31) - parseFloat((typeof Q33==='undefined' || Q33===''  || Number.isNaN(Q33))? 0 : Q33)).toFixed())
    }, [Q28, Q30, Q31, Q33, setQ27])

    const history = useHistory()

    const data={
                money_allocated_to_profit_account:Q28,
                profit_write_here:profitWriteHere,
                money_owner_contributed_to_business:Q31,
                other_profit:Q30,
                other_profit_write_here:otherProfitWriteHere,
                proceeds_from_loans:Q33,
                edited_quater:null,
                profit:Q27
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (true) {
            console.log('form can be submitted')
            // const data={
            //     money_allocated_to_profit_account:Q28,
            //     profit_write_here:profitWriteHere,
            //     money_owner_contributed_to_business:Q31,
            //     other_profit:Q30,
            //     other_profit_write_here:otherProfitWriteHere,
            //     proceeds_from_loans:Q33,
            //     edited_quater:null,
            //     profit:Q27
            // }
            submitCall(Q27,data)
            // history.push("/worksheet3")
        }
        else {
            console.log('someth wro')
        }
    }

    
    const handleSave = (e) => {
         if (true) {   
            saveCall(Q27,data)
        }
        else {
            console.log('something wrong')
        }
    }


    return (
            <div className='worksheet_main'>
                <div className={'page_content'}>
                <div className='worksheet_form_container'>
                    <div className='worksheet_form_header'>
                        <div className='flex_image' >
                            <h4>Profit Worksheet</h4>
                            <a href="https://pat-api.profitfirsttools.com/static/video/profit-worksheets.mp4" target="_blank"><img alt='' className="video-icon" src={video_player} /> <i>Watch Tutorial</i></a>
                        </div>
                        <h3 className={`worksheet_form_header_h3`} >{mainData?.analyzing_report?.run_rate ? `${momentConvertFormat(mainData?.analyzing_report?.starting_month,'YYYY-MM-DD','MM/DD/YYYY')} - ${momentConvertFormat(mainData?.analyzing_report?.ending_month,'YYYY-MM-DD','MM/DD/YYYY')}` :  mainData?.analyzing_report?.analysing_year}</h3>
                       
                    </div>
                    <div className='worksheet_form'>
                        <div className='worksheet_table_container'>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left worksheet_dark_left'>Profit</span>
                                </div>
                                <div className='worksheet_right_side'>
                                <NumberFormat className='worksheet_right worksheet_dark_right' value={Q27 === 'NaN' ? `${currency}0` : Q27} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                    {/* <span className='worksheet_right worksheet_dark_right'>{Q27 === 'NaN' ? '0.00' : Q27}</span> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                     <span className='worksheet_left'>
                                         <span className="spancol">Money Allocated to Profit Account</span>
                                         <span className="spancol-note" style={{ paddingRight: '20px' }}>Note: Please list items included in this amount</span></span>
                                </div>
                                <div className='worksheet_right_side'>
                                <CustomInput value={Q28} formatedValue={Q28}  unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ28(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q28} placeholder="$0.00" onValueChange={(value) => setQ28(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q28} onChange={(e) => setQ28(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <input value={profitWriteHere} onChange={(e)=>setProfitWriteHere(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                </div>
                                <div className='worksheet_right_side'>
                                    <span className='worksheet_right'>&nbsp;</span>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>
                                        <span  className="spancol">Other Profit</span>
                                        <span  className="spancol-note"  style={{ paddingRight: '20px' }}>Note: Please list items included in this amount</span>
                                    </span>
                                </div>
                                <div className='worksheet_right_side'>
                                <CustomInput value={Q31} formatedValue={Q31}  unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ31(val)}/>
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q31} onChange={(e) => setQ31(e.target.value)} required /> */}
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q31} placeholder="$0.00" onValueChange={(value) => setQ31(value.floatValue)}/> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <input value={otherProfitWriteHere} onChange={(e)=>setOtherProfitWriteHere(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                </div>
                                <div className='worksheet_right_side'>
                                 <span className='worksheet_right'>&nbsp;</span>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Money Owner Contributed to Business (Balance Sheet)</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <CustomInput value={Q30} formatedValue={Q30}  unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ30(val)}/>
                                {/* <input className='worksheet_right' placeholder='0.00' value={Q30} onChange={(e) => setQ30(e.target.value)} required /> */}
                                {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q30} placeholder="$0.00" onValueChange={(value) => setQ30(value.floatValue)}/> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Proceeds from Loans (from Balance Sheet)</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    
                                    <CustomInput value={Q33} formatedValue={Q33} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ33(val)}/>
                                    {/* <input className='worksheet_right worksheet_last' placeholder='0.00' value={Q33} onChange={(e) => Q33(e.target.value)} required /> */}
                                    {/* <NumberFormat className='worksheet_right worksheet_last' thousandSeparator={true} prefix={'$'} value={Q33} placeholder="$0.00" onValueChange={(value) => setQ33(value.floatValue)}/> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left worksheet_dark_left'>Profit Adjustment</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right worksheet_dark_right' value={mainData?.worksheet?.profit_adjustment === 'NaN' ? `${currency}0` : mainData?.worksheet?.profit_adjustment} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                    {/* <span className='worksheet_right worksheet_dark_right'>{Q27 === 'NaN' ? '0.00' : Q27}</span> */}
                                </div>
                            </div>
                        </div>
                        <div className='worksheet_footer'>
                            <button className='worksheet_previous_btn' onClick={backCall} >Previous</button>
                            <div className='worksheet_progress_bar' style={{ left: '0' }}><div className='worksheet_progress' style={{ width: "50%" }}></div></div>
                            <button className='worksheet_submit_btn' onClick={handleSubmit} >Save and continue</button>
                        </div>
                    </div>
                    <Autosave data={data} onSave={handleSave} />
                </div>
                <Footer />
                </div>
            </div>
    )
}

export default Worksheet2
