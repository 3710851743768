import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import SidePanel from './SidePanel'
import AssessmentModal from './AssessmentModal'
import styles from '../components_css/Quick_assessment.module.css'
import cx from 'classnames'
import video_player from '../images/video-player.svg'
import video_player_icon from '../images/circle-play-regular.svg'
import axios from 'axios'
import { verifyValue } from '../utils/globalFunction'
import DatePicker from 'react-date-picker';
import { Link, useParams } from "react-router-dom"
import { notify, CustomInput } from '../utils/globalFunction'
import { useHistory } from 'react-router-dom';
import { Autosave } from 'react-autosave';



function QuickAssessment({ BaseURL, userLogOut }) {

    const history = useHistory();
 
    const assessId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    console.log('My assessment ID: ' + assessId);
    const currentDate = new Date();
    let token = localStorage.getItem('userAccessToken');
    //get param
    const uuseParams = useParams();
   
    // const assessmentId = parseInt(assId) > 0 ? assId : ""; 
    const assessmentId = uuseParams.assessment_id ? uuseParams.assessment_id : "";
    // const [assessmentId, setAssessmentId] = useState(uuseParams.assessment_id ? uuseParams.assessment_id : "");
   // const [assessmentId, setAssessmentId] = useState(parseInt(assessId) > 0 ? assessId : "");
    
    console.log("assessmentId " + assessmentId);

    const [clientData, setclientData] = useState([])
    const [Editmode, setEditmode] = useState(false);
    const [operatingAdjustment, setOperatingAdjustment] = useState(false);
    const [profitAdjustment, setProfitAdjustment] = useState(false);

    const [operatingAdjustmentValue, setOperatingAdjustmentValue] = useState(0);
    const [profitAdjustmentValue, setProfitAdjustmentValue] = useState(0);

    const [AssessmentDate, setAssessmentDate] = useState(currentDate)
    const [ProjectName, setProjectName] = useState(clientData ? clientData.project_name : '')

    const [ActiveTab,] = useState('Dashboard')
    const [Q20, setQ20] = useState()
    const [Q24, setQ24] = useState()
    const [Q26, setQ26] = useState()
    const [Q27, setQ27] = useState()
    const [Q34, setQ34] = useState()
    const [Q48, setQ48] = useState()
    const [Q52, setQ52] = useState()
    const [ButtonColor, setButtonColor] = useState('inactive')
    const [Balance, setBalance] = useState(0)
    const [user, setUser] = useState({}) 
    const [logo, setLogo] = useState(null) 
    // const [value, onChange] = useState(new Date());
    const assessType = 'q';  //quick assessment

    const [openModel, setOpenModel] = useState(false)


    useEffect(() => {

        axios.get(`${BaseURL}/api/v1/assessments/quick-assessment-list/${assessmentId}`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                // console.log(res.data);
                setclientData(res.data);
                // setMainForm(res.data.data);
            }).catch(err =>  {
                if(err.response.status===401){
                    userLogOut()
                }
            })


        axios.get(`${BaseURL}/api/v1/user/retrieve-settings/`, { headers: { "Authorization": `JWT ${token}` } })
        .then(res => {
            setLogo(res?.data?.data?.logo)
        }).catch(err =>  {
            if(err.response.status===401){
                userLogOut()
            }
        })

    }, [assessmentId]);

    useEffect(() => {
        setEditmode(clientData.id ? true : false)
        setAssessmentDate(clientData && clientData.assessment_date != 'undefined' ? clientData.assessment_date : currentDate)
        setProjectName(clientData ? clientData.project_name : '')
        setQ20(clientData ? clientData.top_line_revenue : '')
        setQ24(clientData ? clientData.material_subcontractor : '')
        setQ26(clientData ? clientData.real_revenue : '')
        setQ27(clientData ? clientData.profit : '')
        setQ34(clientData ? clientData.owner_compensation : '')
        setQ48(clientData ? clientData.tax : '')
        setQ52(clientData ? clientData.operating_expenses : '')


    }, [clientData]);

    const calculater = () => {
        // if (((`${(parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52)))}`) === 'NaN') || ((parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52))) === 0)) {
        //     setButtonColor('green')
        //     setBalance('zero')
        // }
        // else if ((parseFloat(Q26) - (parseFloat(Q27) + parseFloat(Q34) + parseFloat(Q48) + parseFloat(Q52))) > 0) {
        //     setBalance('positive')
        //     setButtonColor('red')
        // }
        // else {
        //     setBalance('negative')
        //     setButtonColor('red')
        // }
    }

    useEffect(() => {
        setQ26((verifyValue(Q20) - verifyValue(Q24)).toFixed())
        setBalance((verifyValue(Q20) - verifyValue(Q24)) - (verifyValue(Q27) + verifyValue(Q34) + verifyValue(Q48) + verifyValue(Q52)))

    }, [Q20, Q24, Q26, Q27, Q34, Q48, Q52]);




    useEffect(() => {
        axios.get(`${BaseURL}/api/v1/user/profile/`, { headers: { "Authorization": `JWT ${token}` } })
            .then(res => {
                console.log(res, 'profile')
                setUser(res.data.data)
            })
            .catch(err => console.log(err))
    }, []);

    function refreshPage() {
        window.location.reload(false);
      }

    const form_data = {
        "assessment_date": `${AssessmentDate}`,
        "project_name": `${ProjectName}`,
        "topLineRevenue": verifyValue(Q20),
        "materialSubcontractors": verifyValue(Q24),
        "realRevenue": verifyValue(Q26),
        "profit": verifyValue(Q27),
        "ownerComp": verifyValue(Q34),
        "tax": verifyValue(Q48),
        "operatingExpenses": verifyValue(Q52),

    };


    const handleSave = (e) => {
        // e.preventDefault();

        console.log('Form assessment date: ' + form_data.assessment_date)
        const payloadform = {
            "project_name": form_data.project_name,
            "top_line_revenue": form_data.topLineRevenue,
            "material_subcontractor": form_data.materialSubcontractors,
            "real_revenue": form_data.realRevenue,
            "profit": form_data.profit,
            "owner_compensation": form_data.ownerComp,
            "tax": form_data.tax,
            "operating_expenses": form_data.operatingExpenses,
            "assessment_date": form_data.assessment_date
        }
  
        if (Editmode) {

            axios.put(`${BaseURL}/api/v1/assessments/update-quick-assessment/${assessmentId}/`, payloadform, { headers: { "Authorization": `JWT ${token}` } })
                .then(response => {
                    // console.log(response.data)
                    const res = response.data

                   
                    const data = {
                        client_information: res.data.client_information,
                        id: res.data.id
                    }
                  //  alert("saved");
                 //   history.push("/");
                    

                })
                .catch(err => {
                    console.log(err)


                })

        }
    }
        
    
    const handleSubmit = (e) =>{
        e.preventDefault();
         
           
        console.log(form_data.assessment_date,"date")
            const payloadform = {
                "project_name": form_data.project_name,
                "top_line_revenue": form_data.topLineRevenue,
                "material_subcontractor": form_data.materialSubcontractors,
                "real_revenue": form_data.realRevenue,
                "profit": form_data.profit,
                "owner_compensation": form_data.ownerComp,
                "tax": form_data.tax,
                "operating_expenses": form_data.operatingExpenses,
                "assessment_date": form_data.assessment_date
            }
    
            if (!Editmode) {   
    
    
                axios.post(`${BaseURL}/api/v1/assessments/quick-assessment/`, payloadform, { headers: { "Authorization": `JWT ${token}` } })
                    .then(response => {
                        console.log('New record: '+response.data)
                        const res = response.data
    
                        // const data = {
                        //     // quick_assessment: res.quick_assessment,
                        //     id: res.id
                        // }
                        
                        // alert("Data Saved !");
                          history.push("/");
                          console.log('New assessment id: '+res.id)
                           history.replace("/quick_assessment/"+res.id);                     
                                             
                        })
                    .catch(err => {
                        notify(err, 'error')
                    })
             }
    }



return (
    <>
        <div className={styles.quick_assessment_main}>
            <div className={styles.page_content}>
                <div className={styles.flex_image}>
                    <h1>Quick Assessment </h1>
                    <a href="https://pat-api.profitfirsttools.com/static/video/quick-assessment.mp4" target="_blank" className="text"><img className="video-icon" title="Watch Tutorial" alt='' src={video_player} /> <i>Watch Tutorial</i></a>

                </div>
                <form onSubmit={handleSubmit}>

                    <div className={styles.quick_table_container}>
                        {/*  */}
                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Assessment Date *</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <DatePicker
                                    className="custom-picker"
                                    // onChange={onChange}
                                     value={AssessmentDate}
                                    selected={AssessmentDate}
                                    onChange={(date) => setAssessmentDate(date)}
                                />
                            </div>
                        </div>
                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_right}>Project Name *</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <input className={styles.quick_assessment_right} type="text" placeholder="Enter here" name="project-name" value={ProjectName} onChange={(e) => setProjectName(e.target.value) } required />
                            </div>
                        </div>



                        {/*  */}
                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Top Line Revenue</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <CustomInput value={Q20} formatedValue={Q20} unit={3} changeValue={(val,val2)=>setQ20(val)}/>
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Materials & Subcontractors</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <CustomInput value={Q24} formatedValue={Q24} unit={3} changeValue={(val,val2)=>setQ24(val)}/>
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Real Revenue</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right)} value={Q26 === 'NaN' ? '$0.00' : Q26} displayType={'text'} thousandSeparator={true} decimalScale={0} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Profit</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                
                                <CustomInput value={Q27} formatedValue={Q27} unit={3} adjustment={profitAdjustment} changeValue={(val,val2)=>setQ27(val)}/>
                                {/* <NumberFormat className={styles.quick_assessment_right} thousandSeparator={true} prefix={'$'} value={Q27} placeholder="$0.00" onValueChange={(value) => setQ27(value.floatValue)} /> */}
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Owner's Comp</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                
                                <CustomInput value={Q34} formatedValue={Q34} unit={3} changeValue={(val,val2)=>setQ34(val)}/>
                                {/* <NumberFormat className={styles.quick_assessment_right} value={Q34} thousandSeparator={true} prefix={'$'} placeholder="$0.00" onValueChange={(value) => setQ34(value.floatValue)} /> */}
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Tax</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                <CustomInput value={Q48} formatedValue={Q48} unit={3} changeValue={(val,val2)=>setQ48(val)}/>
                                {/* <NumberFormat className={styles.quick_assessment_right} value={Q48} thousandSeparator={true} prefix={'$'} placeholder="$0.00" onValueChange={(value) => setQ48(value.floatValue)} /> */}
                            </div>
                        </div>

                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                <span className={styles.quick_assessment_left}>Operating Expenses</span>
                            </div>
                            <div className={styles.quick_right_side}>
                                
                                <CustomInput value={Q52} formatedValue={Q52} unit={3} adjustment={operatingAdjustment} changeValue={(val,val2)=>setQ52(val)}/>
                                {/* <NumberFormat className={cx(styles.quick_assessment_right, styles.quick_last)} value={Q52} thousandSeparator={true} prefix={'$'} placeholder="$0.00" onValueChange={(value) => setQ52(value.floatValue)} /> */}
                            </div>
                        </div>
                        <div className={styles.quick_assessment_row}>
                            <div className={styles.quick_left_side}>
                                {/* <span className={cx(styles.quick_assessment_left, styles.real_revenue)}>Operating Expenses</span> */}
                            </div>
                            <div className={styles.quick_right_side}>
                                <NumberFormat className={cx(styles.quick_assessment_right, styles.real_revenue_right, styles.color_black, { [styles.quick_btn_red]: Balance < 0 }, { [styles.quick_btn_green]: Balance > 0 })} value={Balance === 'NaN' ? '$0.00' : Balance} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.quick_footer1}>
                        {/* <button className={styles.quick_active_btn} onClick={calculater}>Calculate</button> */}
                        <button type="button"  onClick={(e) => setOpenModel(true)} className={cx(styles.quick_inactive_btn, { [styles.quick_btn_red]: Balance < 0 }, { [styles.quick_btn_green]: Balance >= 0 })}>Run Report</button>
                        
                        <button className={cx(styles.quick_inactive_btn, styles.quick_btn_green)}  type='submit'>{Editmode?"Update Report":"Save New Report"}</button>

                    </div>
                    <div className={styles.quick_footer2}>
                        <div>
                            <button type="button" disabled={Balance === 0} onClick={(e) => {setOperatingAdjustment(!operatingAdjustment); setOperatingAdjustmentValue(Balance); setQ52(parseInt(typeof Q52 == 'undefined'? 0 : Q52 )+Balance)}} className={cx(styles.quick_inactive_btn, { [styles.activate_quick_btn]: Balance !== 0 })}>Apply difference to operating expenses</button>
                            {operatingAdjustmentValue!=0?
                                <button className='undo_btn' onClick={(e) => {setOperatingAdjustment(!operatingAdjustment); setOperatingAdjustmentValue(0); setQ52(parseInt(typeof Q52 == 'undefined'? 0 : Q52 )-operatingAdjustmentValue)}} type='button'>Undo</button>
                            : null}
                        </div>
                        <div>
                            <button type="button" disabled={Balance == 0} onClick={(e) => {setProfitAdjustment(!profitAdjustment);setProfitAdjustmentValue(Balance); setQ27(parseInt(typeof Q27 == 'undefined'? 0 : Q27 ) + Balance)}} className={cx(styles.quick_inactive_btn, { [styles.activate_quick_btn]: Balance !== 0 })}>Apply difference to profit</button>
                            {profitAdjustmentValue!=0?
                                <button type='button' onClick={(e) => {setProfitAdjustment(!operatingAdjustment); setProfitAdjustmentValue(0); setQ27(parseInt(typeof Q27 == 'undefined'? 0 : Q27 )-profitAdjustmentValue)}} className='undo_btn'>Undo</button>
                            :null}
                        </div>
                    </div>
                    <Autosave data={form_data} onSave={handleSave} />
                </form>
            </div>
        </div>
        {
            openModel ?
                <AssessmentModal
                    BaseURL={BaseURL}
                    modalIsOpen={openModel}
                    user={user}
                    logo={logo}
                    data={
                        {   dCurrency:'$',
                            cCode:'USD',
                            topLineRevenue: verifyValue(Q20),
                            materialSubcontractors: verifyValue(Q24),
                            realRevenue: verifyValue(Q26),
                            profit: verifyValue(Q27),
                            ownerComp: verifyValue(Q34),
                            tax: verifyValue(Q48),
                            operatingExpenses: verifyValue(Q52),
                            id:assessmentId,
                            exp:[],
                            edited_quater:null,
                            project_name:ProjectName
                        }
                    }
                    closeModal={() => setOpenModel(false)
                    }
                    assessType={assessType}
                />
                : null
        }
    </>
)
}

export default QuickAssessment
