import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import NumberFormat from 'react-number-format';
import Footer from './Footer'
import video_player from '../images/video-player.svg'
import '../components_css/Worksheet.css'
import * as allCurrency from '../constant/CURRENCY';
import {floatCheck, momentConvertFormat, CustomInput} from '../utils/globalFunction'
import { Autosave } from 'react-autosave'; 

function Worksheet1({ Q26, setQ26, WorksheetYear,backCall,submitCall, saveCall, mainData }) {
    const currency = allCurrency.CURRENCY.find(item=>item.id===parseInt(mainData?.client_information?.currency))?.symbol || '$'
    const [Q20, setQ20] = useState(floatCheck(mainData?.worksheet?.top_line_revenue))
    const [Q21, setQ21] = useState(floatCheck(mainData?.worksheet?.income_from_sale))
    const [Q22, setQ22] = useState(floatCheck(mainData?.worksheet?.interest_income))
    const [Q23, setQ23] = useState(floatCheck(mainData?.worksheet?.other_income))
    const [Q25, setQ25] = useState(floatCheck(mainData?.worksheet?.material_subcontractor))
    const [topLineNotes, setTopLineNotes] = useState(mainData?.worksheet?.top_line_notes || '')
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    useEffect(() => {
        setQ20((parseFloat((typeof Q21==='undefined' || Q21==='' || Number.isNaN(Q21))? 0 : Q21) + parseFloat((typeof Q22==='undefined' || Q22==='' || Number.isNaN(Q22))? 0 : Q22) + parseFloat((typeof Q23==='undefined' || Q23==='' || Number.isNaN(Q23))? 0 : Q23)).toFixed(0))
    }, [Q21, Q22, Q23])

    useEffect(() => {
        setQ26((parseFloat((typeof Q20==='undefined' || Q20==='' || Number.isNaN(Q20))? 0 : Q20) - parseFloat((typeof Q25==='undefined' || Q25==='' || Number.isNaN(Q25))? 0 : Q25)).toFixed(0))

    }, [Q20, Q25, setQ26])


    

    const history = useHistory()
    const data ={
                top_line_revenue:Q26,
                income_from_sale:Q21,
                interest_income:Q22,
                other_income:Q23,
                top_line_notes:topLineNotes,
                material_subcontractor:Q25, 
                edited_quater:null 
            }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (true) {
            console.log('form can be submitted')
            // const data ={
            //     top_line_revenue:Q26,
            //     income_from_sale:Q21,
            //     interest_income:Q22,
            //     other_income:Q23,
            //     top_line_notes:topLineNotes,
            //     material_subcontractor:Q25, 
            //     edited_quater:null 
            // }
            submitCall(Q26,Q25,Q20,data);
            // history.push("/worksheet2")
        }
        else {
            console.log('someth wro')
        }
    }
    
    const handleSave = (e) => {
        if (true) {
            saveCall(Q26,Q25,Q20,data);
        }
        else {
            console.log('something wrong')
        }
    }

    return (
            <div className='worksheet_main'>
                <div className={'page_content'}>
                <div className='worksheet_form_container'>
                    <div className='worksheet_form_header'>
                        <div className='flex_image' >
                            <h4>Top Line Revenue Worksheet</h4>
                            <a href="https://pat-api.profitfirsttools.com/static/video/top-line-revenue-worksheet.mp4" target="_blank"><img className="video-icon" alt='' src={video_player} /> <i>Watch Tutorial</i></a>
                        </div>
                        <h3 className={`worksheet_form_header_h3`} >{mainData?.analyzing_report?.run_rate ? `${momentConvertFormat(mainData?.analyzing_report?.starting_month,'YYYY-MM-DD','MM/DD/YYYY')} - ${momentConvertFormat(mainData?.analyzing_report?.ending_month,'YYYY-MM-DD','MM/DD/YYYY')}` : mainData?.analyzing_report?.analysing_year}</h3>
                        
                   </div>
                    <div className='worksheet_table-row'>
                                
                                <div className='worksheet_left_side '>
                                     <span className='worksheet_left worksheet_dark_left'>Top Line Revenue</span>
                                </div>
                                <div className='worksheet_right_side'>
                                
                                    <NumberFormat className='worksheet_right worksheet_dark_right' value={Q20 === 'NaN' ? `${currency}0` : Q20} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                    {/* <span className='worksheet_right worksheet_dark_right'>{Q20 === 'NaN' ? '0.00' : Q20}</span> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                     <span className='worksheet_left'>Income from Sales</span>
                                </div>
                                <div className='worksheet_right_side'>
                                <CustomInput value={Q21} formatedValue={Q21} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ21(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q21} placeholder="$0.00" onValueChange={(value) => setQ21(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q21} onChange={(e) => setQ21(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                     <span className='worksheet_left'>Interest Income</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    
                                <CustomInput value={Q22} formatedValue={Q22} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ22(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'}  value={Q22} placeholder="$0.00" onValueChange={(value) => setQ22(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q22} onChange={(e) => setQ22(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>
                                        <span className="spancol">Other Income</span>
                                        <span className="spancol spancol-note" style={{ paddingRight: '20px' }}>Note: Please list items included in this amount</span>
                                    </span>
                                </div>
                                <div className='worksheet_right_side'>
                                    
                                    <CustomInput value={Q23} formatedValue={Q23} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ23(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'}  value={Q23} placeholder="$0.00" onValueChange={(value) => setQ23(value.floatValue)}/> */}
                                    {/* <input className='worksheet_right' placeholder='0.00' value={Q23} onChange={(e) => setQ23(e.target.value)} required /> */}
                                </div>
                            </div> 
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                    <input value={topLineNotes} onChange={(e)=>setTopLineNotes(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                </div>
                                <div className='worksheet_right_side'>
                                    <span className='worksheet_right'>&nbsp;</span>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                 <span className='worksheet_left worksheet_dark_left'>Materials & Subcontractors</span>
                                </div>
                                <div className='worksheet_right_side'>
                                     <NumberFormat className='worksheet_right worksheet_dark_right' value={(Q25 === '' || typeof Q25==='undefined') ? `${currency}0` : Q25} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                {/* <span className='worksheet_right worksheet_dark_right'>{Q25 === '' ? '0.00' : Q25}</span> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                <span className='worksheet_left'>Cost of Goods Sold / Cost of Sales (Include Materials and Subcontractors Costs to Produce Product/Service)</span>
                                </div>
                                <div className='worksheet_right_side'>
                                {/* <span className='worksheet_right worksheet_dark_right'>{Q25 === '' ? '0.00' : Q25}</span> */}
                                    <CustomInput value={Q25} formatedValue={Q25} unit={mainData?.client_information?.currency} changeValue={(val,val2)=>setQ25(val)}/>
                                    {/* <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'}  value={Q25} placeholder="$0.00" onValueChange={(value) => setQ25(value.floatValue)}/>  */}
                                {/* <input className='worksheet_right' placeholder='0.00' value={Q25} onChange={(e) => setQ25(e.target.value)} required /> */}
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                  <span className='worksheet_left worksheet_dark_left'>Materials & Subcontractors Percentage</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <span className='worksheet_right worksheet_dark_right worksheet_last'>{Number.isFinite((Q25/Q21)*100)? ((Q25/Q21)*100).toFixed(0) : 0} %</span>
                                </div>
                            </div>
                            
                            <div className='worksheet_table-row'>
                                <div className='worksheet_left_side'>
                                  <span className='worksheet_left worksheet_dark_left'>Real Revenue</span>
                                </div>
                                <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right worksheet_dark_right worksheet_last' value={Q26 === 'NaN' ? `${currency}0` : Q26} displayType={'text'}  thousandSeparator={true} prefix={currency} renderText={(value, props) => <div {...props}>{value}</div>}/>
                                {/* <span className='worksheet_right worksheet_dark_right worksheet_last'>{Q26 === 'NaN' ? '0.00' : Q26}</span> */}
                                </div>
                            </div>
                            <div className='worksheet_footer'>
                                <button className='worksheet_previous_btn' onClick={backCall} >Previous</button>
                                <div className='worksheet_progress_bar' style={{ left: '0' }}><div className='worksheet_progress' style={{ width: "37.5%" }}></div></div>
                                <button className='worksheet_submit_btn' onClick={handleSubmit} >Save and continue</button>
                            </div>
                            <Autosave data={data} onSave={handleSave} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Worksheet1
