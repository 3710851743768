import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import video_player from '../images/video-player.svg'
import NumberFormat from 'react-number-format';
import '../components_css/Worksheet.css'
import { useHistory } from 'react-router'
import {floatCheck} from '../utils/globalFunction'

function Worksheet7({ WorksheetYear,backCall, submitCall, mainData }) {


    const [Q80, setQ80] = useState(floatCheck(mainData?.worksheet?.charity))
    const [Q81, setQ81] = useState(floatCheck(mainData?.worksheet?.professional_dev))
    const [Q82, setQ82] = useState(floatCheck(mainData?.worksheet?.equipment))
    const [Q83, setQ83] = useState(floatCheck(mainData?.worksheet?.software_subscription))
    const [Q84, setQ84] = useState(floatCheck(mainData?.worksheet?.staffing))
    const [Q85, setQ85] = useState(floatCheck(mainData?.worksheet?.general_expenses))
    const [Q86, setQ86] = useState(floatCheck(mainData?.worksheet?.investment_fund))
    const [nowWhat, setNowWhat] = useState(mainData?.worksheet?.now_what || '')

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])


    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (true) {
            const data={
                charity:Q80,
                professional_dev:Q81,
                equipment:Q82,  
                software_subscription:Q83,  
                staffing:Q84,  
                general_expenses:Q85,  
                investment_fund:Q86,  
                now_what:nowWhat,  
            }
            submitCall(data)
            // history.push("/worksheet5")
        }
        else {
        }
    }

    return (
            <div className='worksheet_main'>
                 <div className={'page_content'}>
                <div className='worksheet_form_container'>
                    <div className='worksheet_form_header'>
                        <h4>Other Parameters</h4>
                        <h3 className={`worksheet_form_header_h3 ${(WorksheetYear === '') ? `` : `show_header_h3`}`} >{WorksheetYear}</h3>
                        <img alt='' className="video-icon" src={video_player}></img>
                    </div>
                    <div className='worksheet_form'>
                        <div className='worksheet_table_container'>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Charity</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q80} placeholder="$0.00" onValueChange={(value) => setQ80(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Professional Dev</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q81} placeholder="$0.00" onValueChange={(value) => setQ81(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Equipment</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q82} placeholder="$0.00" onValueChange={(value) => setQ82(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Software Subscription</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q83} placeholder="$0.00" onValueChange={(value) => setQ83(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Staffing</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q84} placeholder="$0.00" onValueChange={(value) => setQ84(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>General Expenses</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q85} placeholder="$0.00" onValueChange={(value) => setQ85(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>Investment Fund</span>
                                 </div>
                                 <div className='worksheet_right_side'>
                                    <NumberFormat className='worksheet_right' thousandSeparator={true} prefix={'$'} value={Q86} placeholder="$0.00" onValueChange={(value) => setQ86(value.floatValue)}/>
                                </div>
                            </div>
                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <span className='worksheet_left'>
                                        <span className="spancol">Now What</span>
                                    </span>
                                 </div>
                            </div>

                            <div className='worksheet_table-row'>
                                 <div className='worksheet_left_side'>
                                    <input value={nowWhat} onChange={(e)=>setNowWhat(e.target.value)} className='worksheet_left' placeholder='Write here'></input>
                                 </div>
                            </div>

                         
                        </div>
                        <div className='worksheet_footer'>
                            <button className='worksheet_previous_btn' onClick={backCall} >Previous</button>
                            <div className='worksheet_progress_bar' style={{ left: '0' }}><div className='worksheet_progress' style={{ width: "75%" }}></div></div>
                            <button className='worksheet_submit_btn' onClick={handleSubmit} >Save and continue</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            </div>
    )
}

export default Worksheet7
