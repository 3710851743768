import React,{useEffect, useState} from 'react'
import {  toast } from 'react-toastify';
import moment from 'moment';
import * as math from 'mathjs'
import 'react-toastify/dist/ReactToastify.css';
import * as allCurrency from '../constant/CURRENCY';

const formatMoney = (number,code='USD') => {
    return number.toLocaleString('en-US', { style: 'currency', currency: code, minimumFractionDigits: 0,  maximumFractionDigits: 2, currencyDisplay: 'narrowSymbol' });
}

export const currency = (number,code='USD') => {
    return formatMoney(parseFloat(number),code)
}

export const getFix = (value,realRevenue,percentage,code='USD') =>{
    return (value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed() < 0 ? `(${currency((value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed()*-1,code)})` :  currency((value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed(),code)
}

export const getFixU = (value,realRevenue,percentage) =>{
    return (value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed() < 0 ? `(${(value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed()*-1})` :  (value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed()
}
export const getDelta = (value,realRevenue,percentage) =>{
    return (value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed() < 0 ? 'Redistribute' :  ((value-((parseFloat(realRevenue)*percentage)/100).toFixed()).toFixed() < 2 ? 'MAINTAIN' : 'Reallocate')
}

export const getPF = (realRevenue, percentage,code='USD') =>{
    return currency(((realRevenue*percentage)/100).toFixed(),code)
}

export const getPFU = (realRevenue, percentage) =>{
    return ((realRevenue*percentage)/100).toFixed()
}


export const getQuater = (val, ca, ta) =>{
    return (parseFloat(val) - (parseFloat(ca)-parseFloat(ta))/6).toFixed()
}

export const notify = (message,type) => {
    if(type==='success'){
        toast.success(message);
    } else if(type === 'error'){
        toast.error(message);       
    } else {
        toast(message);       
    }
}

export const formatDate2 = (date) => {
    if(date===''){
        return ''
    }
    var d = new Date(date),
        month = '' + d.getMonth(),
        day = '' + (d.getDate()-1),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return new Date(year, month, day);
}


export const newDateFormat = (date) =>{
    if(!!date){
      const da=  moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD') 
      return new Date(da)
    }
    return ''
} 


export const momentFormat = (date,format) => {
    return moment(date, format)
}

export const momentConvertFormat = (date, to, from) => {
    return moment(date, to).format(from)
}

export const formatDate = (date) => {
    if(date===''){
        return ''
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return new Date(year, month, day);
}

export const formatDateDisplay = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('-');
}

export const formatEndDate = (date) => {
    if(date===''){
        return ''
    }
    // var d = new Date(date),
    //     month = d.getMonth(),
    //     day = d.getDate(),
    //     year = d.getFullYear();
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

if (month.length < 2) 
    month = '0' + month;
if (day.length < 2) 
    day = '0' + day;
  

    return [year ,month, day].join('-');
}
export const floatCheck = (value) => {
    return parseFloat(value)===0? '' : parseFloat(value)
}

export const getPercent1 = (value) => {
    if(value>=0 && value<250000){
        return 5
    } else if (value >= 250000 && value < 500000){
        return 10
    } else if (value >= 500000 && value < 1000000){
        return 15
    } else if(value >= 1000000 && value < 5000000){
        return 10
    } else if(value>= 5000000 && value < 10000000){
        return 15
    } else {
        return 17
    }
}

export const verifyValue = (value) =>{
    if(value==='' || isNaN(value) || !value){
        return 0
    } else{
        return parseFloat(value)
    }

}

export const getPercent2 = (value) => {
    if(value>=0 && value<250000){
        return 50
    } else if (value >= 250000 && value < 500000){
        return 35
    } else if (value >= 500000 && value < 1000000){
        return 20
    } else if(value >= 1000000 && value < 5000000){
        return 10
    } else if(value>= 5000000 && value < 10000000){
        return 5
    } else {
        return 3
    }
}



export const getPercent3 = (value) => {
    if(value>=0 && value<250000){
        return 30
    } else if (value >= 250000 && value < 500000){
        return 40
    } else if (value >= 500000 && value < 1000000){
        return 50
    } else if(value >= 1000000 && value < 5000000){
        return 65
    } else if(value>= 5000000 && value < 10000000){
        return 65
    } else {
        return 65
    }
}


export const currencyFormat = (val) =>{
    if (!val|| (val=='' && isNaN(val) && typeof val == 'undefined')) {
        return '0';
    }

    return val.toFixed().replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const CustomInput = ({value,formatedValue,unit,changeValue, adjustment=false}) => {
    const [currency,setCurrency] = useState('$')
    const [calculated,setCalculated]=useState('0.0') 
    const [history,setHistory]=useState(!isNaN(formatedValue)?formatedValue:'') 
    const [activeState,setActiveState]=useState(1) 

    useEffect(()=>{

        console.log(value,'s');
        setCurrency(allCurrency.CURRENCY.find(item=>item.id===parseInt(unit))?.symbol || '$')
        // setCalculated(`${allCurrency.CURRENCY.find(item=>item.id===parseInt(unit))?.symbol || '$'}${(!value || (value=='' && isNaN(value) && typeof value == 'undefined'))?`0.0`:parseInt(value).toFixed(1)}`)
        // setCalculated(`${allCurrency.CURRENCY.find(item=>item.id===parseInt(unit))?.symbol || '$'}${(!value || (value=='' && isNaN(value) && typeof value == 'undefined'))?`0.0`:currencyFormat(parseInt(value).toFixed(1))}`)
        setCalculated(`${allCurrency.CURRENCY.find(item=>item.id===parseInt(unit))?.symbol || '$'}${(!value || (value=='' && isNaN(value) && typeof value == 'undefined'))?`0`:currencyFormat(parseFloat(value))}`)
    },[unit, value])
    
    
    useEffect(()=>{
        setHistory(!isNaN(formatedValue)?formatedValue:'') 
    },[adjustment])


    const handleBlur = () =>{
        setActiveState(1)
        let result
        try{ 
            if(!!history){
                const total = math.compile(history)
                result = `${currency}${currencyFormat(total.evaluate())}`
            } else {
                if (!formatedValue) {
                    formatedValue=0;
                }
                const fValue  = math.compile(formatedValue)
                result = `${currency}${currencyFormat(fValue.evaluate())}`
            }
        } catch(e) {
            result = 'Syntax error'
        }
        setCalculated(result)
                                              
    }
    const handleFocus = (e) =>{
        setActiveState(0)
    }
    const handleChange = (e) =>{
        setHistory(e.target.value)
        let result
        try{ 
            if(!!e.target.value){
                const total = math.compile(e.target.value)
                result = total.evaluate()
            } else {
                result = 0
            }
            changeValue(result,e.target.value)
        } catch(e) {
            
        }
    }
    return (
        <>
        <input onBlur={handleBlur} value={activeState==1?calculated:history} className={`worksheet_right ${unit}`} onChange={handleChange} onFocus={handleFocus} type="text" />
        </>
    )
}
